import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as image from "../../resources/images";
import Vicky from "../controls/Vicky";
import { ViewVideo } from "../dimensions/activity";
const UserActivityProgress = ({ activityData, setShowVideoPopUp, handleVideoData }) => {
  const [videoPopup, setVideoPopup] = useState();

  const openVideo = (data) => {
    setShowVideoPopUp(true);
    handleVideoData({
      siteUrl: data.link,
      title: data.title,
    });
    /*
    setVideoPopup({
      siteUrl: data.link,
      title: data.title,
    });
    */
  };

  /*
  const close = () => {
    setVideoPopup();
  };
  */

  return (
    <div className="CourseCardWrapper fullHeight100">
      <div class="LessionDtlOverview m-0">
        <div class="d-flex align-items-start flex-wrap p-0">
          <div class="flex w-100 mb-3 align-items-start flex-wrap text-justify textcontentSidebar p-0">
            <p>
              <div className="module_challenge_">
                <h4 className="mx-2 mb-3 mt-2">{activityData?.name}</h4>
                {activityData && activityData?.videoUrl ? (
                  <div className="helpvideosection pointer">
                    <div className="challenge_story_vid mt-3 mb-3 position-relative" onClick={() => openVideo({
                      title: activityData?.name,
                      link: activityData?.videoUrl,
                    })}>
                      <img src="https://bloomster.s3.us-east-1.amazonaws.com/growth-plan/Resilient+Decision-Maker.jpg" alt="" />
                      <img src={image.View_Video} className="view_video_plan border-0" alt="" />
                      {/* {videoPopup && <ViewVideo close={close} data={videoPopup} />} */}
                    </div>
                  </div>
                  // <div className="helpvideos">
                  // <div className="helpvideosection">

                  //   <span
                  //     className="text-center"
                  //       onClick={() => openVideo({
                  //         title: activityData?.name,
                  //         link: activityData?.videoUrl,
                  //     })}
                  //   >
                  //    <img src="https://bloomster.s3.us-east-1.amazonaws.com/growth-plan/Resilient+Decision-Maker.jpg" alt="" />
                  //     {/* <h4 className="text-center">{videoObj.title}</h4 > */}
                  //     <img src={image.View_Video} className="view_video_plan" />
                  //   </span>
                  //   {videoPopup && <ViewVideo close={close} data={videoPopup} />}
                  // </div>
                  // </div>
                ) : (
                  <div class="intructionsBox ScenecerelateddQuiz Analysistxt  seneriotext">
                    {/* <p><strong>Scenario Name</strong></p> */}
                    <p class="d-inline">
                      <span>
                        {" "}
                        {/* <i class="fa-solid fa-quote-left"></i> */}
                      </span>
                      <em>
                        <span class="m-0"
                          dangerouslySetInnerHTML={{
                            __html: activityData?.story
                          }}
                        >
                        </span>
                      </em>
                      <span key={activityData?.story}>
                        {/* <i class="fa-solid fa-quote-right"></i> */}
                      </span>
                    </p>
                  </div>
                )}

                <div className="how_challenges_work mt-3 p-2">
                  <h4>How the Challenge Works:</h4>
                  <ul>
                    {activityData?.instructions?.map((data, key) => (
                      <li key={key}>
                        <img src={image.Capturevdfhfveh} />
                        {data}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </p>
            {!activityData?.videoUrl && (
              <Vicky text={activityData?.story} />
            )}

          </div>
        </div>
      </div>
    </div>
  )
}
export default UserActivityProgress;