import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as image from "../../resources/images";
import {
	dateSortingByStartDate,
	getCapitalized,
	getRankSort,
	getSequnceSort,
	showRoundValue
} from "../../utils/helper";
import PieGraph from "./PieGraph";
import Sidebar from "./Sidebar";

import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
	ShimmerSimpleGallery
} from "react-shimmer-effects";
import { activePlanIndexNo, selectTab, showModal } from "../../redux/actions";
import {
	getDimensionPlanData,
	getJourny,
	getJournyCourses,
	getLeaderBoard,
	getLearnerPlanTags,
	getMyPlan,
	getSocialActivityDetail,
	getUpdateLearnerPlan,
} from "../../redux/actions/APIs";
import { showOverallModal } from "../../redux/actions/Home";
import { PATHS } from "../../utils";
import { holisticSkillData } from "../../utils/DataObjects";
import { useForm } from "../../utils/Packages";
import LessonCongratulationsPopup from "../dimensions/course/vickycourses/LessonCongratulationsPopup";
import LearnerPlanRightPanel from "../learnerplan/LearnerPlanRightPanel";
import CongratulationCard from "../widget/CongratulationCard";
import ShowOverallProgressPopup from "./ShowOverallProgressPopup";
import StudentDashBord from "./StudentDashBord";
import TeacherDashboard from "./TeacherDashboard";
import DashBoardUserProress from "./DashBoardUserProress";
import Vicky from "../controls/Vicky";
import UserProgressRightPanel from "./UserProgressRightPanel";
import DeletePlanActivities from "../learnerplan/DeletePlanActivities";
import { ViewVideo } from "../dimensions/activity";
import LeaderBoard from "./LeaderBoard";
import LeaderBoardRightPanel from "./LeaderBoardRightPanel";

const HolisticView = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const { defaultChildData, loggedInUser, getMyPlanData, showPanelObj, getLeaderBoardResp } = useSelector((state) => state.collections);
	const { dashboardData, showoverModal } = useSelector((state) => state.home);
	const [getPlanIndex, setPlanIndex] = useState(0);

	const {
		getValues,
		setValue,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm({ mode: "onTouched" });


	const [message, setMessage] = useState();
	const [showOverallProgressPopup, setShowOverallProgressPopup] =
		useState(false);

	const handleShowOverallProgressPopup = (result) => {
		setShowOverallProgressPopup(result);
	};

	const [toggleState, setToggleState] = useState(true);
	const [weeks, setWeeks] = useState(0);
	const [selectedTab, setSelectedTab] = useState("");

	useEffect(() => {
		if (dashboardData?.id !== defaultChildData?.id) {
			setSelectePlanObj();
			setShowPlanCourseData([]);
			setModuleObj();
		}
		/*
		if (defaultChildData?.id) {
			dispatch(getLeaderBoard(defaultChildData?.id));
		}
		*/
	}, [defaultChildData])

	useEffect(() => {
		dispatch(selectTab());
		if (dashboardData?.userPlans?.length > 0) {
			setToggleState(true);
			setWeeks(parseInt(dashboardData?.week));
		} else {
			setToggleState(false);
		}
	}, [dashboardData]);

	let socialObj = [];
	let emotionalObj = [];
	let spiritualObj = [];
	let physicalObj = [];
	let intellectualObj = [];

	dashboardData &&
		dashboardData?.dimensions.map((obj) => {
			if (obj.name === "Physical") {
				physicalObj = obj;
			} else if (obj.name === "Mindfulness") {
				spiritualObj = obj;
			} else if (obj.name === "Social") {
				socialObj = obj;
			} else if (obj.name === "Emotional") {
				emotionalObj = obj;
			} else if (obj.name === "Intellectual") {
				intellectualObj = obj;
			}
		});

	const handleClickHere = () => {
		dispatch(getJourny(false));

		history.push({
			pathname: PATHS.COURSEPAGE_STR + defaultChildData?.id,
			state: { isFilter: true },
		});
	};

	const showOverall = () => {
		dispatch(showOverallModal(true));
	};

	let progressCount = 0;

	dashboardData &&
		dashboardData?.dimensions.map((vl) => {
			progressCount += vl.dimPro;
		});

	const handleToggleState = (state) => {
		setToggleState(state);
	};

	const _redirectLearnerPlan = () => {
		history.push(PATHS.STR_LEARNER_PLAN + defaultChildData?.id);
	};

	const handleMoreOptions = (type) => {
		if (type === "changePace") {
			history.push({
				pathname: PATHS.STR_LEARNER_PLAN + defaultChildData?.id,
				state: {
					show: "changePace",
					screen: 1,
				},
			});
		} else if (type === "createNewPlan") {
			dispatch(getUpdateLearnerPlan(defaultChildData?.id));
			history.push({
				pathname: PATHS.STR_LEARNER_PLAN + defaultChildData?.id,
				state: {
					show: "recommended",
					screen: 1,
				},
			});
			dispatch(getLearnerPlanTags());
			dispatch(getJourny());
			dispatch(getDimensionPlanData());
			dispatch(getJournyCourses());
		} else if (type === "courseCatlog") {
			history.push(PATHS.COURSEPAGE_STR + defaultChildData?.id);
		}
	};
	//Why A Plan add message 16 may 2024
	const handlePopUpOpen = (type) => {
		if (type === "whyplan") {
			dispatch(
				showModal({
					type: "learnerPlanInfo",
					title: "Why A Plan?",
					icon: image.Calendericon,
					message: `Creating a Plan helps parents and students work Bloomster into a regular habit and maintain consistency for maximum effectiveness.
`,
				})
			);
		}
	};

	const handleReports = (value, dimObj) => {
		history.push({
			pathname: PATHS.STUDENT_REPORTS,
			state: {
				type: value, dimObj: dimObj, usertype: "teacher"
			},
		});
	}

	const [showPlanCourseData, setShowPlanCourseData] = useState([]);
	const [getSelectePlanObj, setSelectePlanObj] = useState();
	const [chooseOption, setChooseOption] = useState("Plan_DashBoard");

	const gotoSelectedPlan = (planIndex) => {
		let getAllSortedPlan = dateSortingByStartDate(getMyPlanData?.records)?.reverse();
		setPlanIndex(planIndex);
		setSelectePlanObj(getAllSortedPlan[planIndex]);
		let courses = [];
		getAllSortedPlan[planIndex]?.dimensions?.map((dim, index) => {
			dim?.skills?.map((skill, key) => {
				skill?.courses?.map((course, cKey) => {
					course.skill = skill;
					course.dimension = { key: dim?.id, value: dim?.name }
					courses.push(course);
				})
			})
		})
		setShowPlanCourseData(courses);
	}

	useEffect(() => {
		if (getMyPlanData?.records?.length > 0) {
			if (!getSelectePlanObj) {
				gotoSelectedPlan(0);
				setChooseOption("Plan_DashBoard");
			}
		} else {
			let emptyArray = [];
			if (getMyPlanData?.records?.length === 0) {
				setShowPlanCourseData([{ activities: Array(4).fill({ isLocked: true }) }]);
				handleOption("");
			}
		}
	}, [getMyPlanData])

	const [getTeaccherProf, setTeacherProf] = useState(false);

	useEffect(() => {
		if (loggedInUser?.role?.name === "TEACHER") {
			setTeacherProf(true);
		} else if (loggedInUser?.role?.name === "PROVIDER") {
			history.push(PATHS.COURSE_STR + loggedInUser?.id);
		}
	}, [loggedInUser])

	const [showCongratulationModel, setShowCongratulationModel] = useState(false);
	const [congratulationPopup, setCongratulationPopup] = useState(false);
	const [courseDetail, setCourseDetail] = useState([]);
	const [courseActivityId, setCourseActivityId] = useState([]);

	const handleCongratulationPopup = (vl) => {
		setCongratulationPopup(vl);
	};

	const getDataFromCourseProgress = (result, data, course) => {
		setCourseDetail(course);
		setCourseActivityId(data);
		dispatch(getSocialActivityDetail());
		if (course?.isAcademic) {
			setCongratulationPopup(result);
		} else {
			setShowCongratulationModel(result);
		}
	};

	const handleMyPlan = (courseId) => {
		dispatch(activePlanIndexNo({ index: getPlanIndex }));
		dispatch(getMyPlan());
		history.push({
			pathname: PATHS.STR_MYPLAN + defaultChildData?.id,
			state: { showPlanPopup: true, courseId: courseId },
			index: getPlanIndex,
			selectedFrom: "home",
		});
	};

	const handleOption = (type) => {
		setChooseOption(type);
	}

	const handleWhyAPlanPopup = () => {
		dispatch(showModal({ type: "WhyAPlanPopup" }));
	};

	const handlePolyText = (type) => {
		let polyText = `Right now, your child’s progress map is waiting to be filled with achievements! Enroll in a Growth Plan today and take the first step toward building essential life skills that will help them thrive.`
		return polyText;
	}

	const handleGotoCourse = () => {
		history.push({
			pathname: PATHS.COURSEPAGE_STR + defaultChildData?.id,
			state: {
				data: { filter: [] },
			},
		});
	}

	const [moduleObj, setModuleObj] = useState();
	const [courseIndex, setSelectedCourseIndex] = useState(0);
	const [showAlertPopUp, setShowAlertPopUp] = useState();
	const [showVideoPopUp, setShowVideoPopUp] = useState(false);
	const [videoData, setVideoData] = useState();

	const handleClose = () => {
		setShowAlertPopUp();
		setShowVideoPopUp(false);
	}

	const handleVideoData = (data) => {
		setVideoData(data);
	}

	useEffect(() => {
		dispatch(activePlanIndexNo({ index: getPlanIndex }));
	}, [courseIndex]);


	return (
		<>
			{loggedInUser?.role?.name === "TEACHER" && getTeaccherProf ? (
				<TeacherDashboard handleReports={handleReports} />
			) : loggedInUser?.role?.name === "STUDENT" ? (
				<StudentDashBord />
			) : (
				<div className='LeftbarPannel p-0' id=''>
					<div className='form-title mt-0 mb-0 Enrollcoursetitle heading p-0'>
						<h2
							data-toggle='collapse'
							className='m-0 pt-0 pb-1 w-100 flex justify-content-between position-relative'>
							{loggedInUser?.role?.name !== "TEACHER" ? (
								<div class="tabgrid w-100 m-0  ">
									{/* <ul>
										<li class={`tabs w-50${chooseOption == "Plan_DashBoard" ? " active-tabs" : ""}`} onClick={() => handleOption("Plan_DashBoard")}><img src={image.reports_docs}></img>Plan Dashboard</li>
										<li class={`tabs w-50${chooseOption == "" ? " active-tabs" : ""}`} onClick={() => handleOption("")}><img src={image.Chartimgicon} alt="" />Holistic View</li>
									</ul> */}
									<ul>
										<li class={`tabs ${chooseOption == "Plan_DashBoard" ? " active-tabs" : ""}`} onClick={() => handleOption("Plan_DashBoard")}><img src={image.your_progress_} alt="" />Your Progress</li>
										{/* <li class={`tabs ${chooseOption == "Leader_Board" ? " active-tabs" : ""}`} onClick={() => handleOption("Leader_Board")}><img src={image.leaderboard_icon} alt="" />Leaderboard</li> */}
										<li class={`tabs ${chooseOption == "" ? " active-tabs" : ""}`} onClick={() => handleOption("")}><img src={image.Chartimgicon} alt="" />Holistic View</li>
									</ul>
								</div>
							) : (
								<span className="flexone">
									{" "}
									<img src={image.reports_docs} className='mr-2 report_Iocns' alt='' />
									Reports{" "}
								</span>
							)}
							{/* {
								loggedInUser?.role?.name !== "TEACHER" && (
									<span className="Dashboard_i_icon_">
										<img
											src={image.chat_icon}
											className='chat_icon pointer'
											alt=''
											onClick={() => showOverall()}
										/>
									</span>
								)
							} */}
						</h2>
					</div>
					{chooseOption == "Plan_DashBoard" ? (
						<DashBoardUserProress showPlanCourseData={getSequnceSort(showPlanCourseData)}
							handleMyPlan={handleMyPlan}
							gotoSelectedPlan={gotoSelectedPlan}
							getSelectePlanObj={getSelectePlanObj}
							handleOption={handleOption}
							chooseOption={chooseOption}
							handleGotoCourse={handleGotoCourse}
							moduleObj={moduleObj}
							setModuleObj={setModuleObj}
							courseIndex={courseIndex}
							setSelectedCourseIndex={setSelectedCourseIndex}
						/>
					) : chooseOption == "Leader_Board" ? (
						<LeaderBoard myLeaderBoardData={getRankSort(getLeaderBoardResp?.records)} />
					) : (
						<div className='CourseCardWrapper fullHeight100'>

							{dashboardData ? (
								<div className='NhPageWrap'>
									{/* {
								dashboardData?.userPlans?.length === 0 && (
									<h4 className="stripLeaner_plan">
										<a href="javascript:void(0)" onClick={() => _redirectLearnerPlan()}>Create learner plan for {getCapitalized(defaultChildData.firstName)}</a>
									</h4>
								)
							} */}

									{
										["TEACHER", "STUDENT"]?.includes(loggedInUser?.role?.name) ? (
											<h3 className='text-left mt-4 statementabthome'>
												Here is {dashboardData?.sessionName}'s overall dimensional progress. Click on the dimension bubbles to filter the progress by skill and course.
											</h3>
										) : (
											<h3 className='text-left mt-4 statementabthome'>
												{dashboardData?.progress <= 0 && (
													<>
														{/* Explore our most popular courses on your right or{" "}
										<a href="#" onClick={() => handleClickHere()}>
											click here
										</a>{" "}
										to explore other courses to begin{" "}
										{defaultChildData &&
											getCapitalized(defaultChildData.firstName)}
										's holistic growth journey. */}
														Here is{" "}
														{defaultChildData &&
															getCapitalized(defaultChildData.firstName)}
														's overall and dimensional progress. Click on the dimension
														bubbles to explore a dimension.
													</>
												)}
												{dashboardData?.progress > 0 &&
													dashboardData?.progress <= 15 && (
														<>
															{defaultChildData &&
																getCapitalized(defaultChildData.firstName)}{" "}
															has taken their first steps towards their holistic growth.
															They can accelerate their progress by countinuing their
															current course or enrolling in other courses by{" "}
															<a href='#' onClick={() => handleClickHere()}>
																clicking here
															</a>
															.
														</>
													)}
												{dashboardData?.progress > 15 &&
													dashboardData?.progress <= 50 && (
														<>
															{defaultChildData &&
																getCapitalized(defaultChildData.firstName)}{" "}
															is making good progress in their holistic growth journey.
															Click on each dimension circle to learn more about their
															progress or{" "}
															<a href='#' onClick={() => handleClickHere()}>
																click here
															</a>{" "}
															to explore more courses.
														</>
													)}
												{dashboardData?.progress > 50 && (
													<>
														{defaultChildData &&
															getCapitalized(defaultChildData.firstName)}{" "}
														is well on their way to becoming a well-rounded individual.
														Click on each dimension circle to learn more about their
														progress and decide what to work on next.{" "}
													</>
												)}
											</h3>
										)
									}
									{/* <h3 className="text-center mt-4 statementabthome" id="statementabthome" dangerouslySetInnerHTML={{ __html: message }}></h3> */}
									<div className={`NHProgreschartList flex flex-wrap ${loggedInUser?.role?.name === "TEACHER" || loggedInUser?.role?.name === "STUDENT" ? "schoolbubble_progress" : ""}`}>
										{socialObj?.length !== 0 && (
											<PieGraph
												name={"Social"}
												DimData={socialObj}
												skillData={holisticSkillData[0]}
											/>
										)}
										<div className='NHProgresslistitem NhHolisticProgress allitemsNH'>
											<div className={`NHPreogressouter`}>
												<div
													className={`NHProgressinner ${showRoundValue(dashboardData?.progress) > 80
														? "flextwo"
														: ""
														}`}
													style={{
														width: `calc(${showRoundValue(
															dashboardData?.progress
														)}% + 12.5px)`,
														height: `calc(${showRoundValue(
															dashboardData?.progress
														)}% + 12.5px)`,
													}}>
													<p
														style={{
															bottom:
																showRoundValue(dashboardData?.progress) / 2 +
																50 +
																"%",
														}}>
														{" "}
														{/* need to do some change in css */}
														{showRoundValue(dashboardData?.progress)}
														<span className='percentage_sign'>%</span>
													</p>
													{/* {progressCount >= 30 && (
						<h3>{getProfileName(defaultChildData)}</h3>
						)} */}
												</div>
											</div>
											<div className='NHDimeProgName flextwo mt-2'>
												<h4>
													<img src='' alt='' />
													{getCapitalized(defaultChildData.firstName)}'s Overall
													Progress
												</h4>{" "}
												<span
													className='pointer'
													onClick={() => handleShowOverallProgressPopup(true)}>
													<img src={image.chat_icon} alt='' className='chat_icon' />
												</span>
											</div>
										</div>
										{emotionalObj?.length !== 0 && (
											<PieGraph
												name={"Emotional"}
												DimData={emotionalObj}
												skillData={holisticSkillData[3]}
											/>
										)}
										{intellectualObj?.length !== 0 && (
											<PieGraph
												name={"Intellectual"}
												DimData={intellectualObj}
												skillData={holisticSkillData[2]}
											/>
										)}
										{spiritualObj?.length !== 0 && (
											<PieGraph
												name={"Mindfulness"}
												DimData={spiritualObj}
												skillData={holisticSkillData[4]}
											/>
										)}

										{physicalObj?.length !== 0 && (
											<PieGraph
												name={"Physical"}
												DimData={physicalObj}
												skillData={holisticSkillData[1]}
											/>

										)}
									</div>
								</div>
							) : (
								<div className='NhPageWrap'>
									<div className='NHProgreschartList'>
										<ShimmerSimpleGallery
											imageType='circular'
											imageHeight={200}
											caption
										/>
									</div>
								</div>
							)}
						</div>
					)}

					<div class="LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip ">
						<div class="form-group BDsubmitbutton d-flex justify-content-right align-items-center m-0 ">
							<div className="w-100">
								{/* <button class="btn-blue btn-login d-block float-right w-auto"
														>Next
														<span>
															<i className="fa-solid fa-arrow-right m-0 ml-2"></i>
														</span>
													</button> */}
							</div>

						</div>
					</div>
				</div>
			)
			}
			{location?.showRightPanel === "Popular Courses" ? (
				<>
					<Sidebar
						data={dashboardData}
						handleToggleState={handleToggleState}
						toggleState={toggleState}
					/>

				</>
			) : (
				<>
					{((loggedInUser?.role?.name !== "TEACHER" && chooseOption == "Plan_DashBoard" && (showPlanCourseData?.length > 0 && !showPlanCourseData[courseIndex]?.isComic)) || (!getMyPlanData || showPlanCourseData?.length === 0)) ? (
						<div className='RightbarPannel p-0 rightpannelSticky'>
							<UserProgressRightPanel
								handleWhyAPlanPopup={handleWhyAPlanPopup}
								handleMoreOptions={handleMoreOptions}
								handleGotoCourse={handleGotoCourse}
								userPlanData={getMyPlanData?.records}
								moduleObj={moduleObj}
								courseIndex={courseIndex}
								showPlanCourseData={showPlanCourseData}
								setShowAlertPopUp={setShowAlertPopUp}
								setShowVideoPopUp={setShowVideoPopUp}
								handleVideoData={handleVideoData}
							/>
						</div>
					) : chooseOption == "Leader_Board" ? (
						<LeaderBoardRightPanel myLeaderBoardData={getRankSort(getLeaderBoardResp?.records)} />
					) : ((loggedInUser?.role?.name === "TEACHER" || (getMyPlanData?.records?.length > 0 && chooseOption !== "Plan_DashBoard")) ||
						chooseOption === "" || (showPlanCourseData?.length > 0 && showPlanCourseData[courseIndex]?.isComic)) &&
					(loggedInUser?.role?.name !== "STUDENT" || showPanelObj?.type !== "showGrowthSurveyPanel") && (
						<div className='RightbarPannel p-0 rightpannelSticky newcoursecardpanel home_page_rgt_pnl'>
							<LearnerPlanRightPanel
								screen={1}
								selectedSitting={2}
								selectedMinutes={30}
								handleMoreOptions={handleMoreOptions}
								// selectedOption={location?.showRightPanel === "Rcommnded Plan" ? "recommended" : "home"}
								// 	getMyEditPlan={location?.showRightPanel === "Rcommnded Plan" ? "New Plan" : ""}
								selectedOption="home"
								getMyEditPlan=""
								handlePopUpOpen={handlePopUpOpen}
								coursePlanTree={[]}
								timezoneValue={"US/Eastern"}
								gotoSelectedPlan={gotoSelectedPlan}
								showPlanCourseData={showPlanCourseData}
								getDataFromCourseProgress={getDataFromCourseProgress}
								selctedDays={[]}
								getFlexibLeDays={[]}
								getValues={getValues}
								setValue={setValue}
								setSelectedTab={setSelectedTab}
							/>
						</div>
					)
					}
				</>
			)}

			{/* {dashboardData ? (
				<Sidebar data={dashboardData} />
			) : (
				<div className="RightbarPannel p-0 rightpannelSticky">
					<ShimmerCategoryItem items={4} categoryStyle="STYLE_SIX" />
				</div>
			)} */}

			{showOverallProgressPopup && (
				<ShowOverallProgressPopup
					handleShowOverallProgressPopup={handleShowOverallProgressPopup}
				/>
			)}

			{showCongratulationModel && !courseDetail?.isAcademic && (
				<CongratulationCard
					courseActivityId={courseActivityId}
					handleOpenpopup={getDataFromCourseProgress}
					isContinueButtonClick={false}
				/>
			)}

			{congratulationPopup && courseDetail?.isAcademic && (
				<LessonCongratulationsPopup
					handleCongratulationPopup={handleCongratulationPopup}
					courseActivityId={courseActivityId}
				/>
			)}

			{showAlertPopUp && (
				<DeletePlanActivities
					closePlan={handleClose}
					deletePlan={showAlertPopUp}
				/>
			)}

			{showVideoPopUp && <ViewVideo close={handleClose} data={videoData} />}
		</>
	);
};

export default HolisticView;
