import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as image from "../../resources/images";
import { ShimmerCategoryList } from "react-shimmer-effects";
const LeaderBoardRightPanel = ({ myLeaderBoardData }) => {
    const myDataObj = myLeaderBoardData[0];
    return (
        <div className="RightbarPannel p-0 rightpannelSticky newcoursecardpanel setuptwo_rPnl">
            <div className="heading p-0 border-0">
                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading">
                    <h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
                        <span> <img src={image.myAchivement} className='mr-2' alt="" />
                            My Achievements
                        </span>
                    </h2>
                </div>

            </div>
            {myDataObj ? (<div className="CourseCardWrapper fullHeight100">
                <div className="userprofiledtl">
                    <div className="clbcarddtl">
                        <div className="lbuserdtl flexone">
                            <div className="lbuserimage">
                                <img src={myDataObj.avatarRightImageUrl} />
                            </div>
                            <div className="lbuserName">
                                <h4>{myDataObj?.learnerName}</h4>
                                <p><i>{myDataObj?.title}</i></p>
                            </div>
                        </div>
                        <ul>
                            <li><span>Rank</span>{myDataObj?.rank}</li>
                            <li><span>Total</span>{myDataObj?.totalPoints}</li>
                            <li> <span>Lessons</span>{myDataObj?.lessons}</li>
                            <li> <span>MCs</span>{myDataObj?.mc}</li>
                            <li> <span>KCs</span>{myDataObj?.kc}</li>
                            <li> <span>MEs</span>{myDataObj?.me}</li>
                            <li><span>Streak</span>{myDataObj?.streak}</li>
                            <li> <span><img src={image.sceneArrow} /> Next Goal</span>{myDataObj?.nextGoal}</li>
                            <li> <span><img src={image.rewardswonimg} />Achievements</span> {myDataObj?.achievements}</li>
                        </ul>
                    </div>
                </div>

            </div>
            ) : (
                <ShimmerCategoryList items={3} categoryStyle="STYLE_SIX" />
            )}


            <div class="LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip ">
                {/* <div class="form-group BDsubmitbutton d-flex justify-content-right align-items-center m-0 ">
                    <div class="">
                        <button
                            class="btn-blue btn-login d-block float-right w-auto"
                            disabled={true}
                        // onClick={() =>}
                        >
                            <span>
                                {" "}
                                <i class="fa-solid fa-arrow-left m-0 mr-2"></i>
                            </span>
                            Back
                        </button>
                    </div>
                    <div class="w-100">
                        <button class="btn-blue btn-login d-block float-right w-auto"
                            disabled={true}
                        // onClick={() =>}
                        >Next
                            <span>
                                {" "}
                                <i className="fa-solid fa-arrow-right m-0 ml-2"></i>
                            </span>
                        </button>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
export default LeaderBoardRightPanel;