import * as image from "../../resources/images";
import React, { useEffect, useMemo, useState } from "react";
import { convertedNumber, getCapitalized, getSequnceSort, getUsStatnderdTime, textTrim } from "../../utils/helper";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { ShimmerCategoryList} from "react-shimmer-effects";
const ChallengePlanModuleRightPanel = ({getPlanCoursesRes, defaultChildData})=>{

    const getLearnerName = () => {
        return getCapitalized(defaultChildData?.firstName);
    };
    return useMemo(
        ()=>(
            <>
            {getPlanCoursesRes?.getAllCourses?.length > 0 ? (
                    <div class="learning_plan_summry  ScenecerelateddQuiz p-0  setuptwo_rPnl recummenddedpln CourseCardWrapper fullHeight100">
                  <div class="learner_Planrighpnl pb-3">
                      <div class="lerner_jounry_plan m-0">
                          <div
                              class="Multi_learner_plan"
                          // data-toggle="collapse"
                          // href="#learner_planone"
                          // aria-expanded="true"
                          >
                              <h5 class="flex">
                                  <i class="fa-light fa-rocket-launch mr-2"></i>
                                  <p class="timint_date mr-auto ml-2 mt-1">
                                      {getPlanCoursesRes && (
                                          <span className="user_planname">
                                              {getPlanCoursesRes?.hcfPlanData?.planName}
                                              {/* {getUsStatnderdTime(learnerPlanData[0]?.startDate, getUserTimeZone)} - {getUsStatnderdTime(learnerPlanData[learnerPlanData?.length - 1]?.endDate, getUserTimeZone)} */}
                                          </span>
                                      )}
                                  </p>
                              </h5>
                              <div className="flexone MultiplanPacedesc">
                                  <div className="flexone">
                                      <p className="mr-3">
                                          Sessions:
                                          <strong>
                                              {" "}
                                              {convertedNumber(getPlanCoursesRes?.sitting)} (
                                              {getPlanCoursesRes?.sitting})
                                          </strong>
                                      </p>
                                      <p>
                                          Duration:<strong> {getPlanCoursesRes?.duration} minutes</strong>
                                      </p>
                                  </div>
                                  <div className="ml-3"></div>
                              </div>
                          </div>
                          <div
                              class="panel-collapse collapse show planheighCollaspe "
                              id="learner_planone"
                          >
                              {getPlanCoursesRes?.getAllCourses?.map((course, index) => (
                                  <div class="LPrpcomponent ">
                                      <div class="d-flex align-items-center plan_course_name">
                                          {/* <div class="User_inisal mr-2">
                                                      <i class="fa-solid fa-graduation-cap"></i>
                                                  </div> */}
                                          <div className="User_inisal courseCircle mr-3">
                                              <span>C{index + 1}</span>
                                          </div>
                                          <div class="userPLan_start">
                                              {/* <span className="inprogress_Course complete_course">Complete</span> */}
                                              {/* <span className="inprogress_Course">In Progress</span> */}
                                              {/* <p class="outCometxt">Course: {course?.isStarted && (<span className="inprogress_Course">In Progress</span>)}</p> */}
                                              <p class="outCometxt">Course {index + 1}:
                                                  {course?.isCompleted && (
                                                      <span>
                                                          <img
                                                              src={image.completedIcon}
                                                              className="statusImg ml-2"
                                                          />
                                                      </span>
                                                  )}
                                                  {course?.isStarted && (
                                                      <span>
                                                          <img
                                                              src={image.inprogresscheck}
                                                              className="statusImg ml-2"
                                                          />
                                                      </span>
                                                  )}
                                              </p>
                                              <p class="flexone">
                                                  <div
                                                      data-for="Making Responsible Choices"
                                                      data-event-off=""
                                                      data-tip="true"
                                                  >
                                                      <strong class="pointer Course_NamePland">
                                                          <React.Fragment>
                                                              {course?.name?.length > 35 ? (
                                                                  <ReactTooltip id={course?.name}>
                                                                      <p>{course?.name}</p>
                                                                  </ReactTooltip>
                                                              ) : (
                                                                  ""
                                                              )}
                                                              <div
                                                                  data-for={course.name}
                                                                  data-event-off=""
                                                                  data-tip
                                                              >
                                                                  <strong
                                                                      className="pointer Course_NamePland"
                                                                      onClick={() =>
                                                                          courseDetailPage(course)
                                                                      }
                                                                  >
                                                                      {textTrim(course.name, 35)}
                                                                  </strong>
                                                              </div>
                                                          </React.Fragment>
                                                      </strong>
                                                  </div>
                                                  {/* <span class="pointer" onClick={() => courseDetailPage(course)}>
                                                              <i class="fa-solid fa-up-right-from-square"></i>
                                                          </span> */}
                                              </p>
                                              <p className="timint_date">
                                                  {getUsStatnderdTime(
                                                      course?.startDate,
                                                      getPlanCoursesRes?.getUsTimeZone
                                                  )}{" "}
                                                  -{" "}
                                                  {getUsStatnderdTime(
                                                      course?.endDate,
                                                      getPlanCoursesRes?.getUsTimeZone
                                                  )}
                                                  {/* <span><i class="fa-light fa-calendar ml-3 p-0"></i></span> */}
                                                  {/* <a href="javascript:void(0)" onClick={() => handleShowPlanModule(index, course, learnerPlanData, "newPlan")} className="ml-2">Show details</a> */}
                                              </p>
                                          </div>
                                      </div>
                                      <div class="flexone LPdestination_mark">
                                          <div class="User_inisal mr-2">
                                              {" "}
                                              <i class="fa-sharp fa-solid fa-location-dot"></i>
                                          </div>
                                          <div class="userPLan_start course_destination">
                                              <p class="outCometxt">Outcome:</p>
                                              <p>
                                                  <div
                                                      data-for="is starting to make more responsible choices!"
                                                      data-event-off=""
                                                      data-tip="true"
                                                  >
                                                      <span>
                                                          <React.Fragment>
                                                              {course?.outcome?.length > 78 ? (
                                                                  <ReactTooltip id={course?.outcome}>
                                                                      <p>
                                                                          {getLearnerName()} {course?.outcome}
                                                                      </p>
                                                                  </ReactTooltip>
                                                              ) : (
                                                                  ""
                                                              )}
                                                              <div
                                                                  data-for={course.outcome}
                                                                  data-event-off=""
                                                                  data-tip
                                                              >
                                                                  <span>
                                                                      {getLearnerName()}{" "}
                                                                      {textTrim(course.outcome, 78)}
                                                                  </span>
                                                              </div>
                                                          </React.Fragment>
                                                      </span>
                                                  </div>
                                              </p>
                                          </div>
                                      </div>
                                  </div>
                              ))}
                              <div class="Contionur_Arrow padding65">
                                  <h4 class="flexone align-items-start">
                                      <span class="p-0 mr-2 flagchecked">
                                          <i class="fa-solid fa-flag-checkered"></i>
                                      </span>
                                      {getLearnerName()} has grown through this
                                      plan and is ready for a new set of courses!
                                  </h4>
                              </div>
                          </div>
                      </div>
                  </div>

                    </div>
            ) : (
                <ShimmerCategoryList items={3} categoryStyle="STYLE_SIX" />
        )}
              
            </>
            
           
        ),[getPlanCoursesRes]
    )

}
export default ChallengePlanModuleRightPanel;