import * as image from "../../resources/images";
import { ShimmerCategoryList, useForm } from "../../utils/Packages";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getHcfChallenges } from "../../redux/actions/APIs";
import { ViewVideo } from "../dimensions/activity";
import { getSequnceSort, getUsStatnderdTimeWithMonthName } from "../../utils/helper";
import CourseCard from "../dimensions/course/intellectual/CourseCard";
import ReadMore from "../controls/ReadMore";
import { showModal } from "../../redux/actions";
const ChallengePlanModule = ({ hcfPlanData, setShowChallenge, getAllHcfCourses, providerData, getUsTimeZone, viewReward, viewCertificate, isMyChoice }) => {
	const dispatch = useDispatch();
	const [videoPopup, setVideoPopup] = useState();
	const [videoObj, setVideoObj] = useState({});

	useEffect(() => {
		setVideoObj({});
	}, [])

	useEffect(() => {
		if (hcfPlanData?.planName) {
			// let myPlanName = hcfPlanData?.planName?.replaceAll(" ", "+");
			let urlObj = {};
			// urlObj = {
			// 	image: `https://bloomster.s3.us-east-1.amazonaws.com/${isMyChoice?.length > 0 ? "growth-plan" : "life_event"}/${myPlanName}.${isMyChoice?.length > 0 ? "jpg" : "webp"}`,
			// 	link: `https://bloomster.s3.us-east-1.amazonaws.com/${isMyChoice?.length > 0 ? "growth-plan" : "life_event"}/${myPlanName}.mp4`,
			// 	title: hcfPlanData?.planName,
			// }
			if (hcfPlanData?.imageUrl) {
				urlObj = {
					image: `${hcfPlanData?.imageUrl}.webp`,
					link: `${hcfPlanData?.imageUrl}.mp4`,
					title: hcfPlanData?.planName,
				}
			}
			setVideoObj(urlObj);
			//	console.log("hcfPlanData?.planName MyObjjjjj: ", myPlanName, urlObj);
		}

	}, [hcfPlanData])

	const openVideo = (data) => {
		setVideoPopup({
			siteUrl: data.link,
			title: data.title,
		});
	};

	const close = () => {
		setVideoPopup();
	};

	const showCalenderScreen = () => {
		let dayOfWeek = []
		let currentDate = new Date();
		for (let i = 0; i < 2; i++) {
			let weekDay = new Date(currentDate).toLocaleString("en-us", { weekday: 'short' });
			dayOfWeek?.push(weekDay);
			currentDate.setDate(currentDate.getDate() + 1);
		}
		let holidaysArr = [];
		// myPlan?.holidays?.map((myDate, key) => {
		// 	let obj = { date: addDayNameInDate(myDate?.date), name: myDate?.name }
		// 	holidaysArr?.push(obj);
		// })
		const modalObj = { type: "CalenderVaiew", data: getAllHcfCourses, getUserTimeZone: getUsTimeZone, dayOfWeek: dayOfWeek, holidays: holidaysArr }
		dispatch(showModal(modalObj));
	}

	return (
		<div className="Challenges_list px-2 pt-2">
			{hcfPlanData && getAllHcfCourses?.length > 0 ?
				(<div className="challenges_itesms">
					<div class="gridSection">
						<div className="Plan_card_">
							<h4 className="mb-2"><img src={image.myplan_icon_regular} className="mr-2 plan_image_" />{hcfPlanData?.planName}</h4>

							<div className="flex align-items-start">
								<div className="w-50">
									{hcfPlanData?.imageUrl && isMyChoice?.length === 0 ? (
										<div class="position-relative">
											<img alt="" src={videoObj.image} />
										</div>
									) : (
										<div className="helpvideos">
											<div className="helpvideosection">

												<span
													className="text-center"
													onClick={() => openVideo(videoObj)}
												>
													<img src={videoObj.image} />
													{/* <h4 className="text-center">{videoObj.title}</h4 > */}
													<img src={image.View_Video} className="view_video_plan" />
												</span>
												{videoPopup && <ViewVideo close={close} data={videoPopup} />}
											</div>
										</div>
									)}

								</div>
								<div className="w-50 plan_content_cover">

									<div className="plans_contents_ pl-3 mt-0">
										{/* <h4 className="mb-2">Plan Objectives:</h4> */}
										<h5><img src={image.greentick} className="green_tickk mr-2" />Plan Objectives</h5>
										<ul>
											{hcfPlanData?.planObjectives?.map((obj, index) => (
												<li key={index} className="d-flex">
													<i class="fa fa-hand-o-right me-1 pt-2 mr-2" aria-hidden="true"></i>
													<span className="">
														<b>
															{/* <img src={image.greentick} className="green_tickk mr-2" /> */}

															{obj?.split(":")[0]}:</b>
														{obj?.split(":")[1]}
													</span>
												</li>
											))}
										</ul>
									</div>

								</div>

							</div>
							<div className="plan_content_cover w-100 flex pl-0">
								<h5 className="mt-3"><img src={image.mortarboard} />Courses {hcfPlanData?.courseCount}</h5>
								<h5 className="mt-3"><img src={image.sessiontimer} />Plan Duration
									{/* December 10th, 2024 - May 25th, 2025 */}
									{" "}
									{getUsStatnderdTimeWithMonthName(
										getAllHcfCourses[0]?.startDate,
										getUsTimeZone
									)}{" "}
									-{" "}
									{getUsStatnderdTimeWithMonthName(
										getAllHcfCourses[getAllHcfCourses?.length - 1]?.endDate,
										getUsTimeZone
									)}
								</h5>
								<h5 className="mt-3"
									onClick={() => showCalenderScreen()}
								><img src={image.calenderIcon1} /><a href="javascript:0">Plan Schedule</a> </h5>


							</div>
						</div>
						<div className="">
							<h4 className="mb-2">Why This Plan?</h4>
							<h5 className="mb-2">This plan is designed to address the following challenges by developing targeted skills:
							</h5>
							<div class="challenge_table">
								<div className="challenge_table_list">
									<ul>
										{hcfPlanData?.alignment &&
											JSON.parse(hcfPlanData?.alignment)?.challenges?.map((challengeObj, key) => (
												<li key={key} onClick={() => setShowChallenge({ ...challengeObj, key: key })} className="pointer">
													{challengeObj?.challenge}
													<span>
														<i class="fa-regular fa-angle-right"></i>
													</span>
												</li>
											))
										}
									</ul>
								</div>
							</div>
							<div className="mt-3">
								<h4 className="mb-2">Real-Life Applications</h4>
								<h5>This section demonstrates how the skills developed in this plan translate into practical, real-world scenarios for everyday success</h5>
							</div>
							<div class="challenge_table real_time_app">
								<div className="realtime_Applist">
									{hcfPlanData?.applications && JSON.parse(hcfPlanData?.applications)?.applications?.map((data, key) => (
										<div className="realtymcard" key={key}>
											<h4>{data?.scenario}</h4>
											<p>{data?.howthishelps}</p>
										</div>
									))}
								</div>
							</div>
						</div>

						<h4 className="mb-2 mt-3">Courses Included in this Plan:</h4>
						{
							getAllHcfCourses?.map((course, cKey) => (
								<React.Fragment key={cKey}>
									<CourseCard
										data={course}
										skills={course?.skill}
										viewReward={viewReward}
										viewCertificate={viewCertificate}
										selectedOption={"Challenges_Plan"}
									/>
								</React.Fragment>
							))}
						<h4 className="mb-2 mt-2">Meet the Course Creators:</h4>

						{providerData?.map((provider, index) => (
							<div className="Course_creatercardlist" key={index}>
								<div className="">
									<div class="JourneyCoursecard newCourseCard mt-0">
										<div class="GridCardTitle">
											<h3>
												<div class="pointer flexone ">
													{provider?.name}
												</div>
											</h3>
										</div>
										<div class="Gridcard SocialGridcard border-0 mt-0">
											<div class="Gridsocialsec">
												<div class="GridiamgeCard">
													<div class="Gridimage position-relative courseCardImg">
														<img alt="" src={provider?.imageUrl} />
													</div>
												</div>
											</div>
											<div class="GridDetails ml-3 gid_plan_objective">
												<h4>Course Creator Bio:</h4>
												{provider?.aboutMe && (
													<p>
														<ReadMore limit={500} height={50}>
															{provider?.aboutMe}
														</ReadMore>
													</p>


												)}
												<div className="plans_contents_ mt-2">
													<div className="craetecourse_list">
														<div className="craetecourse_listtitle">
															<h4>{provider?.name} created her/his own or was part of the team that created the course.</h4>
														</div>
														<div className="createdcourse_list">

															<ul>
																{provider?.courseName?.map((corName, key) => (
																	<li key={key}><img src={image.mortarboard} />{corName}</li>
																))}
															</ul>
														</div>
													</div>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				) : (
					<ShimmerCategoryList items={4} categoryStyle="STYLE_SEVEN" />
				)}

		</div>
	);
}
export default ChallengePlanModule;