import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as image from "../../resources/images";
import { resetEmailResponse, resetLoginResponse, showModal } from "../../redux/actions";
import { getCapitalized } from "../../utils/helper";
import { varificationEmail } from "../../redux/actions/APIs";
import Success from "../notifications/Success";
import { toast } from "react-toastify";
const EmailVerifyPopup = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { defaultChildData, loggedInUser, emailResponse } = useSelector((state) => state.collections);
  const [loader, setLoader] = useState(false);

  const hideShowBox = () => {
    dispatch(showModal());
    // dispatch(resetLoginResponse());
    // dispatch(resetEmailResponse());
  };

  const varifyEmail = (userName) => {
    setLoader(true);
    dispatch(varificationEmail(userName));
  };

  useEffect(() => {
    if (emailResponse && emailResponse?.success) {
      setLoader(false);
      toast.info(<Success response={emailResponse} />, {});
      dispatch(varificationEmail());
    }
  }, [emailResponse]);

  return (
    <div
      class="modal d-flex downgradepopup sectionscorecard"
      id="schoolactivity188"
      role="dialog"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <div class="heading p-0 border-0 w-100">
              <h2 class="flex">
                <span class="flex">
                  <img
                    class="mr-2"
                    src={image.security_icon}
                    alt="Musical"
                  />{" "}
                  Verify Your Account
                </span>
                <button class="btn btn-primary" data-dismiss="modal" onClick={() => hideShowBox()}>
                  <i className="fa-regular fa-xmark m-0"></i>
                </button>
              </h2>
            </div>
          </div>
          <div class="modal-body">
            <div class="intelligenceTyoedetail flexone p-5">
              <div class="intelligenceimg security_account text-center">
                <img src={image.shield_icon} alt="Musical" />
              </div>
              <div class=" pl-3">
                
                <p>
                  Please verify your email to ensure continued access to your account.
                  {/* Is <strong>your-{loggedInUser?.username}</strong> ! Please verify
                                           your email to ensure continued access to your account */}
                </p>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="form-group BDsubmitbutton d-flex m-0">
              <div className="buttonDistribotion">
                <div className="form-group BDsubmitbutton d-flex m-0 ">
                  <button
                    type="button"
                    className="btn-blue btn-login d-block mb-5 cancelbutton"
                    onClick={() => hideShowBox()}
                  >
                    <i className="fa-solid fa-xmark"></i> Cancel
                  </button>
                  {loader ? (
                    <button
                      className="btn-blue btn-login d-block ml-auto mr-2  w-auto"
                      disabled
                    >
                      <span className="RounAnimation mr-1"></span>{" "}
                      Please Wait...
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn-blue btn-login d-block mb-5"
                      onClick={() =>
                        varifyEmail(loggedInUser?.username)
                      }
                    >
                      Resend Verification Email
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EmailVerifyPopup;