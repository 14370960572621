import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as image from "../../resources/images";
const LeaderBoard = ({ myLeaderBoardData }) => {
    // console.log("myLeaderBoardData : ", myLeaderBoardData);

    const [showTable, setShowTable] = useState(false);

    const handleMouseMove = (e) => {
        setShowTable(false);
        // console.log(`Cursor Position: X: Event: ${e}, ${e.clientX}, Y: ${e.clientY}`);
        setTimeout(() => {
            setShowTable(true);
        }, 3000)
    };
    return (
        <>
            {myLeaderBoardData?.length > 0 ? (
                <div className="CourseCardWrapper fullHeight100">
                    <div className='NhPageWrap'>
                        {/* <h3 className="mb-3  pl-3">LeaderBoard</h3> */}
                        <div className="student_leader_board flex align-items-start">
                            <table className="studentleaderboardtbl">
                                <tr className="">
                                    <th>
                                        <span className="student_count"><strong>Rank</strong></span>
                                    </th>
                                    <th>
                                        <span className="stud_name">
                                            <strong className="Avtar_user_name"> <img src={image.myAvtar_icon} className="mr-2" alt="" /></strong>
                                            Name
                                        </span>
                                    </th>
                                    <th><span>Total</span></th>
                                    <th> <span>Lessons</span></th>
                                    <th> <span>MCs</span></th>
                                    <th> <span>KCs</span></th>
                                    <th> <span>MEs</span></th>
                                    <th><span>Streak</span></th>
                                    <th> <span>Next Goal</span></th>
                                    <th> <span>Achievements</span></th>
                                    <th> <span>Message</span></th>
                                    <th></th>
                                </tr>
                                {myLeaderBoardData?.length > 0 &&
                                    <>
                                        {
                                            myLeaderBoardData?.map((myData, index) => (
                                                <tr className="my_position_list" onMouseMove={handleMouseMove}>
                                                    <td className="student_count"><strong>{myData?.rank}</strong></td>
                                                    <td className="stud_name">
                                                        <strong className="Avtar_user_name">  <img src={myData?.avatarLeftImageUrl} className="mr-2" alt="" /></strong>
                                                        {myData?.learnerName}
                                                    </td>
                                                    <td>{myData?.totalPoints}</td>
                                                    <td>{myData?.lessons}</td>
                                                    <td>{myData?.mc}</td>
                                                    <td>{myData?.kc}</td>
                                                    <td>{myData?.me}</td>
                                                    <td>{myData?.streak}</td>
                                                    <td>{myData?.nextGoal}</td>
                                                    <td>({myData?.achievements})</td>
                                                    <td><div className="messgae_avtar"> "So close! Just 75 pts to Rank 1!"</div></td>
                                                    <td>
                                                        {showTable && (
                                                            <table className="leade_bord_detailcard">
                                                                <tr>
                                                                    <td>
                                                                        <div className="clbcarddtl">
                                                                            <div className="lbuserdtl flexone">
                                                                                <div className="lbuserimage">
                                                                                    <img src={myData?.avatarLeftImageUrl} />
                                                                                </div>
                                                                                <div className="lbuserName">
                                                                                    <h4>{myData?.learnerName}</h4>
                                                                                    <p><i>{myData?.title}</i></p>
                                                                                </div>
                                                                            </div>

                                                                            <ul>
                                                                                <li><span>Rank</span>{myData?.rank}</li>
                                                                                <li><span>Total</span>{myData?.totalPoints}</li>
                                                                                <li> <span>Lessons</span>{myData?.lessons}</li>
                                                                                <li> <span>MCs</span>{myData?.mc}</li>
                                                                                <li> <span>KCs</span>{myData?.kc}</li>
                                                                                <li> <span>MEs</span>{myData?.me}</li>
                                                                                <li><span>Streak</span>{myData?.streak}</li>
                                                                                <li> <span>Next Goal</span>{myData?.nextGoal}</li>
                                                                                <li> <span>Achievements</span>{myData?.achievements}</li>

                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                </tr>


                                                            </table>
                                                        )}

                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        {
                                            myLeaderBoardData[0]?.leaderBoard?.map((myData, index) => (
                                                myLeaderBoardData[0]?.learnerId !== myData?.learnerId &&
                                                (
                                                    <tr onMouseMove={handleMouseMove}>
                                                        <td className="student_count"><strong>{myData?.rank}</strong></td>
                                                        <td className="stud_name">
                                                            <strong className="Avtar_user_name">  <img src={myData?.avatarLeftImageUrl} className="mr-2" alt="" /></strong>
                                                            {myData?.learnerName}
                                                        </td>
                                                        <td>{myData?.totalPoints}</td>
                                                        <td>{myData?.lessons}</td>
                                                        <td>{myData?.mc}</td>
                                                        <td>{myData?.kc}</td>
                                                        <td>{myData?.me}</td>
                                                        <td>{myData?.streak}</td>
                                                        <td>{myData?.nextGoal}</td>
                                                        <td>({myData?.achievements})</td>
                                                        <td><div className="messgae_avtar"> "So close! Just 75 pts to Rank 1!"</div></td>
                                                        <td>
                                                            {showTable && (
                                                                <table className="leade_bord_detailcard">

                                                                    <tr>
                                                                        <td>
                                                                            <div className="clbcarddtl">
                                                                                <div className="lbuserdtl flexone">
                                                                                    <div className="lbuserimage">
                                                                                        <img src={myData?.avatarLeftImageUrl} />
                                                                                    </div>
                                                                                    <div className="lbuserName">
                                                                                        <h4>{myData?.learnerName}</h4>
                                                                                        <p><i>{myData?.title}</i></p>
                                                                                    </div>
                                                                                </div>
                                                                                <ul>
                                                                                    <li><span>Rank</span>{myData?.rank}</li>
                                                                                    <li><span>Total</span>{myData?.totalPoints}</li>
                                                                                    <li> <span>Lessons</span>{myData?.lessons}</li>
                                                                                    <li> <span>MCs</span>{myData?.mc}</li>
                                                                                    <li> <span>KCs</span>{myData?.kc}</li>
                                                                                    <li> <span>MEs</span>{myData?.me}</li>
                                                                                    <li><span>Streak</span>{myData?.streak}</li>
                                                                                    <li> <span>Next Goal</span>{myData?.nextGoal}</li>
                                                                                    <li> <span>Achievements</span>{myData?.achievements}</li>

                                                                                </ul>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )

                                            ))
                                        }
                                    </>
                                }
                                {/* <tr className="my_position_list">
                                 <td className="student_count"><strong>1</strong></td>
                                 <td className="stud_name">
                                     <strong className="Avtar_user_name">  <img src="https://bloomster.s3.us-east-1.amazonaws.com/avatar/3_right.png" className="mr-2" alt="" /></strong>
                                     L1 Avatar
                                 </td>
                                 <td>520</td>
                                 <td>120</td>
                                 <td>90</td>
                                 <td>150</td>
                                 <td>160</td>
                                 <td>(5-day)</td>
                                 <td>+75 pts to Rank 1</td>
                                 <td>(Finished 1 Full Course)</td>
                                 <td><div className="messgae_avtar"> "So close! Just 75 pts to Rank 1!"</div></td>
                                 <td>
                                     <table className="leade_bord_detailcard">
                                         <tr>
                                             <td>
                                                 <div className="clbcarddtl">
                                                     <div className="lbuserdtl flexone">
                                                         <div className="lbuserimage">
                                                             <img src={image.Avtar10} />
                                                         </div>
                                                         <div className="lbuserName">
                                                             <h4>Avatar L1</h4>
                                                             <p><i>"You're leading the way! 🚀"</i></p>
                                                         </div>
                                                     </div>
                                                     <ul>
                                                         <li><span>Rank</span>1</li>
                                                         <li><span>Total</span>520</li>
                                                         <li> <span>Lessons</span>120</li>
                                                         <li> <span>MCs</span>90</li>
                                                         <li> <span>KCs</span>150</li>
                                                         <li> <span>MEs</span>160</li>
                                                         <li><span>Streak</span>5-day</li>
                                                         <li> <span>Next Goal</span>+75 pts to Rank 1</li>
                                                         <li> <span>img Achievements</span> 1 Full Course</li>
     
                                                     </ul>
                                                 </div>
                                             </td>
                                         </tr>
                                     </table>
                                 </td>
                             </tr> */}
                            </table>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='NhPageWrap'>
                    <div className='NHProgreschartList'>
                        <ShimmerSimpleGallery
                            imageType='circular'
                            imageHeight={200}
                            caption
                        />
                    </div>
                </div>
            )}
        </>

    )
}
export default LeaderBoard;