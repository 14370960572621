import * as image from "../../resources/images";
import React, { useEffect, useMemo, useState } from "react";
import { convertedNumber, getCapitalized, getdateWithCurretnTime, getSequnceSort, getUsStatnderdTime, textTrim } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { ShimmerCategoryList } from "react-shimmer-effects";
import { registerables } from "chart.js";
import { PLAN_NAME_REGGEX } from "../../utils/Regex";
import { MSG } from "../../utils/Messages";
import DatePicker from "react-date-picker";
import { useForm } from "react-hook-form";
import { addPlanCourseData } from "../../redux/actions";
const ChallengeCreatePlanModule = ({ setPopupData, handleCalculateStartDateEndDate, getAllHcfCourses, hcfPlanData }) => {
    const { loggedInUser,
        defaultChildData,
        getPlanCoursesRes,
    } = useSelector((state) => state.collections);

    const {
        register,
        getValues,
        setValue,
        reset,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({ mode: "onTouched" });

    const dispatch = useDispatch();

    const [userDate, setUserDate] = useState(new Date());
    const [selectedSitting, setSelectedSitting] = useState(2);
    const [selectedMinutes, setSelectedMinutes] = useState(30);
    const [selctedDays, setSelectedDays] = useState([]);
    const [isFlexible, setIsFlexible] = useState(false);
    const [getFlexibLeDays, setFlexibLeDays] = useState([]);


    let sittings = ["1", "2", "3", "4", "5", "6", "7"];
    let minutes = ["15", "30", "45", "60"];


    useEffect(() => {
        if (hcfPlanData) {
            setValue("planName", hcfPlanData?.planName);
        }
    }), [hcfPlanData]


    const getLearnerName = () => {
        return getCapitalized(defaultChildData?.firstName);
    };
    const isShowGrowth = () => {
        let showText = "Growth";
        return showText;
    }

    const handleSitting = (value) => {
        setSelectedSitting(value)
        setSelectedDays([]);
        setIsFlexible();
        setFlexibLeDays([]);
    }
    const handleMinutes = (value) => {
        setSelectedMinutes(value)
    }

    let weekDays = [
        { value: "Monday", key: "Mon" },
        { value: "Tuesday", key: "Tue" },
        { value: "Wednesday", key: "Wed" },
        { value: "Thursday", key: "Thu" },
        { value: "Friday", key: "Fri" },
        { value: "Saturday", key: "Sat" },
        { value: "Sunday", key: "Sun" }
    ]

    const handleWeekDays = (weekKey, index) => {
        if (selctedDays?.length >= selectedSitting && !selctedDays?.includes(weekKey)) {
            setPopupData({ title: "Select Days", message: `You have already selected ${selectedSitting} days for ${selectedSitting} sessions.` })
            return;
        }
        let daysOfArr = [...selctedDays];
        const key = daysOfArr?.findIndex((item) => item === weekKey);
        if (key !== -1) {
            daysOfArr?.splice(key, 1);
        } else {
            daysOfArr?.push(weekKey);
        }
        setSelectedDays(daysOfArr);
        setIsFlexible(false);
        setFlexibLeDays([]);
    }

    const handleFlexible = (value) => {
        setSelectedDays([]);
        setIsFlexible(!isFlexible);
        let weekArr = [];
        let mySitting = value ? value : selectedSitting;
        let currentDate = new Date(userDate);
        if (!isFlexible || value) {
            for (let i = 0; i < mySitting; i++) {
                let weekDay = new Date(currentDate).toLocaleString("en-us", { weekday: 'short' });
                weekArr?.push(weekDay);
                currentDate.setDate(currentDate.getDate() + 1);
            }
            setFlexibLeDays(weekArr);
        } else {
            setFlexibLeDays([]);
        }
    }

    useEffect(() => {
        if (getAllHcfCourses?.length > 0) {
            handleCalculateStartDateEndDate(
                getAllHcfCourses,
                selectedSitting,
                selectedMinutes,
                userDate,
                selctedDays,
                getFlexibLeDays
            );
        }
    }, [selectedSitting, selectedMinutes, userDate])

    useEffect(() => {
        if (selctedDays?.length > 0 || getFlexibLeDays?.length > 0) {
            dispatch(addPlanCourseData({ ...getPlanCoursesRes, getFlexibLeDays: getFlexibLeDays, selctedDays: selctedDays }))
        }
    }, [getFlexibLeDays, selctedDays])
    return (
        <div className="learner_plan setup_one back_next_pos">
            <div className="paceSteps mt-0">
                <div className="">
                    <h3 className="d-flex align-items-start paceseltitle">
                        <span>
                            Select a pace goal for {getLearnerName()} to  complete  course(s) in their {isShowGrowth()} Plan.
                        </span>
                    </h3>
                    <p className="pt-3">
                        The pace selected is used to build the timeline of the {isShowGrowth()} Plan. Pace can be changed at any time and courses are completed at your own pace. After selecting your pace, click 'Next' to choose how to build your {isShowGrowth()} Plan.
                    </p>
                </div>
                <div className="ScenecerelateddQuiz p-0 d-flex align-items-start justify-content-between">
                    <div className="mt-3 pt-3 w-40">
                        <div className="input-group">
                            <h3 className="text-nowrap mb-3">Plan Name: <span class="mandatoryField">*</span> </h3>
                            <div className="form-group">
                                <input
                                    type="text"
                                    // placeholder="Last Name"
                                    className={`form-control ${errors.planName ? "is-invalid" : ""
                                        }`}
                                    disabled={getValues("planName")}
                                    {...register("planName", {
                                        // required: {
                                        // 	value: true,
                                        // 	message: "Plan name is required",
                                        // },
                                        pattern: {
                                            value: PLAN_NAME_REGGEX,
                                            message: MSG.PLAN_TEXT,
                                        },
                                    })}
                                />
                                <div className="invalid-feedback">
                                    {errors?.planName?.message}
                                </div>
                            </div>
                        </div>
                        <div className="plan_startdate mt-3 ">
                            <div className={`input-group calender`}>
                                <div className={`form-group  `}>
                                    <h3 className="mr-3 text-nowrap mb-3"> Start Date: <span class="mandatoryField">*</span>
                                        {/* <span onClick={() => handlePopUpOpen("startdate")}>
                                <img src={image.chat_icon} className="ml-2 ichat_icon pointer" />
                                    </span> */}
                                    </h3>
                                    <label className={`flexone position-relative  m-0`}>
                                        <span className="clenderIcon">
                                            <img src={image.Calendericon} />
                                        </span>
                                        <DatePicker className="form-control signupChildClass p-0 w-100"
                                            clearIcon={null}
                                            oneTap
                                            onChange={(value) => {
                                                setUserDate(getdateWithCurretnTime(value))
                                            }}
                                            value={userDate}
                                            dayPlaceholder={"dd"}
                                            monthPlaceholder={"mm"}
                                            yearPlaceholder={"yyyy"}
                                            format="MM/dd/yyyy"
                                            placement={"topEnd"}
                                            minDate={new Date()}
                                        />
                                    </label>

                                </div>
                                <p>This is the start date for the start of the {isShowGrowth()} Plan or for the first course which you have not started yet.</p>
                            </div>
                        </div>
                        {
                            loggedInUser?.timeZone === null && false(
                                <div className="plan_Time_Zonedrp mt-3">
                                    <div className="plan_startdate mt-3">
                                        <div className="input-group calender learner_pLan_dropdown ">
                                            <div className="form-group   text-nowrap">
                                                <h3 className="mr-3 mb-3"> Time Zone<span class="mandatoryField">*</span> </h3>
                                                <label className="flexone position-relative w-100 timezonelabel m-0">
                                                    <span aria-haspopup="true"
                                                        className="pointer dropdownDiv flex w-100"
                                                        aria-expanded="false"
                                                        data-toggle="dropdown">
                                                        <span className="pe-2 dropdownLabel">{timezoneObj[selectedTimeZone]?.key}</span>
                                                        <i className="fa fa-angle-down fa-lg" aria-hidden="true"></i>
                                                    </span>
                                                    <ul className="dropdown-menu dropdown-menu-end lPDropdwnList" aria-labelledby="">
                                                        {
                                                            timezoneObj?.map((value, key) => (
                                                                <li onClick={() => setSelectedTimeZone(key)}>
                                                                    {value?.key}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </label>
                                                {/* <span >
                                            <img src={image.chat_icon} className="ml-2 ichat_icon pointer" />
                                        </span> */}
                                            </div>
                                            <p>Please provide your time zone. All dates and times will be shown according to this time zone.</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div class="signupType m-0 pt-3 mt-3">
                        <div class="PaceModeSel mb-3">
                            <h3 className=""> Sessions Per Week: {" "}
                                <span className="mandatoryField">*</span>
                                <div className="selectecPaceWeek">
                                    {
                                        sittings?.map((value, index) => (
                                            <label class="Selcheckbox m-0 ActiveQQst" key={index}>
                                                <input type="radio" name="skill0"
                                                    onClick={() => handleSitting(value)}
                                                    checked={value == selectedSitting ? true : false}
                                                //disabled={["5", "6", "7"]?.includes(value) && selectedMinutes == "60" ? true : false}
                                                />
                                                {value} <span>Session{value === "1" ? "" : "s"}</span>
                                                <span class="checkmark"> </span>
                                                {value === "2" && (<p className="recummended">(Recommended)</p>)}
                                            </label>

                                        ))
                                    }

                                </div>
                            </h3>
                            {/* <p>We recommend at least {convertedNumber(2)} (2) sessions per week for optimal learning experience.</p> */}
                        </div>
                    </div>
                    <div class="signupType m-0 pt-3 mt-3 seltimedaysit">
                        <div class="PaceModeSel mb-3">
                            <h3 className=""> Duration Per Session: {" "}
                                <span className="mandatoryField">*</span>
                                <div className="selectecPaceWeek">
                                    {
                                        minutes?.map((value, index) => (
                                            <label class="Selcheckbox m-0 ActiveQQst" key={index}>
                                                <input
                                                    type="radio" name="minutes"
                                                    checked={value == selectedMinutes ? true : false}
                                                    onClick={() => handleMinutes(value)}
                                                />
                                                {value} <span>minutes</span>
                                                <span class="checkmark"> </span>
                                                {value === "30" && (<p className="recummended">(Recommended)</p>)}
                                            </label>
                                        ))
                                    }
                                </div>
                            </h3>
                            {/* <p>
                            We recommend at least 30 mins per session for optimal learning experience.
                        </p> */}
                        </div>
                    </div>
                </div>
                <div className="ScenecerelateddQuiz selec_Days_Custom  pl-0">
                    <div class="signupType m-0  w-100">
                        <div class="PaceModeSel mb-3 w-100">
                            <h3 className=""> Select {convertedNumber(selectedSitting).toLowerCase()} ({selectedSitting}) Days you want to work on Bloomster.{" "}
                                <span className="mandatoryField">*</span>
                                <div className="selectecPaceWeek w-100 mb-0">
                                    <label class="Selcheckbox m-0 ActiveQQst iamflexiblelabel border-0 pb-1">
                                        <input type="checkbox" name="skill0" onClick={() => handleFlexible()}
                                            checked={isFlexible && selctedDays?.length === 0}
                                        />
                                        <span>I'm flexible: </span>
                                        <span class="checkmark"> </span>
                                    </label>
                                </div>
                                <p className="onlinertxt pt-1 pb-1">
                                    Consecutive days based on your selection for Sessions Per Week.
                                    {/* {selectedSitting} days starting my plan start date. */}
                                </p>
                                <p className="Mitual_days_sel">
                                    <strong>Or, </strong>
                                    select specific days you would like {getLearnerName()} to take the course(s) in this {isShowGrowth()} Plan.
                                </p>
                                <div className="selectecPaceWeek w-100">
                                    {
                                        weekDays?.map((day, key) => (
                                            <label class="Selcheckbox m-0 ActiveQQst border-0" key={key}>
                                                <input type="checkbox" name="skill0" onClick={() => handleWeekDays(day?.key, key)}
                                                    checked={selctedDays?.includes(day?.key)}
                                                />
                                                <span>{day?.value}</span>
                                                <span class="checkmark"> </span>
                                            </label>
                                        ))
                                    }

                                </div>
                            </h3>
                            {/* <p>We recommend at least {convertedNumber(2)} (2) sessions per week for optimal learning experience.</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ChallengeCreatePlanModule;