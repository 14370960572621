
import Home from "../Home";
import * as image from "../../resources/images";
import { ShimmerCategoryList, useForm } from "../../utils/Packages";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ChallengePlanModuleRightPanel from "./ChallengePlanModuleRightPanel";
import { updateLearnerPlan } from "../../redux/actions/APIs";
import { PATHS } from "../../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getCourseEndDate } from "../../utils/helper";
import { bloomsterPolyText } from "../../utils/polyTextHelper";
import Vicky from "../controls/Vicky";
const HelpMeRightPanel = ({ selecteOption,
    setPopupData,
    showChallengesPlan,
    isMyChoice,
    handleButton,
    selecteChalnge
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        loggedInUser,
        defaultChildData,
        getPlanCoursesRes,
        response,
    } = useSelector((state) => state.collections);

    const [loader, setLoader] = useState(false);

    const createRecomndedChallengePlan = () => {
        if (getPlanCoursesRes?.getScheduleCourse?.length > 0) {

            let daysOfWeekArr = getPlanCoursesRes?.getFlexibLeDays?.length > 0 ? getPlanCoursesRes?.getFlexibLeDays : getPlanCoursesRes?.selctedDays;
            if (daysOfWeekArr?.length === 0) {
                setPopupData({ title: "Select Days", message: `Please select one of the options for the days your child will do coursework. You can select ‘I’m Flexible’ if you are not sure.` })
                return;
            } else if (daysOfWeekArr?.length != getPlanCoursesRes?.sitting) {
                setPopupData({ title: "Select Days", message: `Please select ${getPlanCoursesRes?.sitting - getPlanCoursesRes?.selctedDays?.length} more day${(getPlanCoursesRes?.sitting - getPlanCoursesRes?.selctedDays?.length) > 1 ? 's' : ''}.` })
                return;
            }
            let array = {
                name: getPlanCoursesRes?.hcfPlanData?.planName,
                sittings: getPlanCoursesRes?.sitting,
                duration: getPlanCoursesRes?.duration,
                planCount: 1,
                timeZone: getPlanCoursesRes?.getUsTimeZone,
                planType: getPlanCoursesRes?.hcfPlanData?.planType,
                planId: getPlanCoursesRes?.hcfPlanData?.planId,
                daysOfWeek: daysOfWeekArr,
                courses: getPlanCoursesRes?.getScheduleCourse,
            };
            if (array?.courses?.length > 0 && array?.sittings % 2 == 1) {
                let course = array?.courses[array?.courses?.length - 1];
                course.activities[3].endDate = getCourseEndDate(array?.courses, course, daysOfWeekArr);
                course.endDate = course?.activities[3]?.endDate;
            }
            // console.log("createRecomndedChallengePlan : ",array);
            setLoader(true);
            dispatch(updateLearnerPlan(defaultChildData?.id, loggedInUser?.id, array))
        }
    }

    useEffect(() => {
        if (response?.success && selecteOption === "Create_Plan") {
            setLoader(false);
            history.push(PATHS.STR_MYPLAN + defaultChildData?.id);
        }
    }, [response])

    return (
        <div className="RightbarPannel p-0 rightpannelSticky newcoursecardpanel setuptwo_rPnl">
            <div className="heading p-0 border-0">
                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading">
                    <h2
                        data-toggle="collapse"
                        class="m-0 pt-0 pb-1 w-100 flex justify-content-between "
                    >
                        {["help_me", "life_one_plan"]?.includes(selecteOption) ? (
                            <span>
                                {" "}
                                <img src={image.howitwork} className="mr-2" alt="" />
                                How It Works
                            </span>
                        ) : (
                            <span>
                                {" "}
                                <img src={image.leanerPlan} className="mr-2" alt="" />
                                Recommended Growth Plan
                            </span>
                        )}

                    </h2>
                </div>
            </div>
            {selecteOption === "help_me" && (
                <div className="CourseCardWrapper fullHeight100">
                    <div class="LessionDtlOverview">
                        <div class="d-flex align-items-start flex-wrap">
                            <div class="flex w-100 mb-3 align-items-start flex-wrap text-justify textcontentSidebar">
                                {bloomsterPolyText("polytext_1", false)}
                                <Vicky text={bloomsterPolyText("polytext_1", true)} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {selecteOption === "life_one_plan" && (
                <div className="CourseCardWrapper fullHeight100">
                    <div class="LessionDtlOverview">
                        <div class="d-flex align-items-start flex-wrap">
                            <div class="flex w-100 mb-3 align-items-start flex-wrap text-justify textcontentSidebar">
                                {bloomsterPolyText("polytext_3", false)}
                                <Vicky text={bloomsterPolyText("polytext_3", true)} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {["Challenges_Plan", "Create_Plan"]?.includes(selecteOption) && (
                <ChallengePlanModuleRightPanel getPlanCoursesRes={getPlanCoursesRes}
                    defaultChildData={defaultChildData}
                />
            )}
            <div class="LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip ">
                <div class="form-group BDsubmitbutton d-flex justify-content-right align-items-center m-0 ">
                    <div class="w-100">
                        {(selecteOption === "help_me" || selecteOption === "life_one_plan") && (
                            <button
                                class="btn-blue btn-login d-block float-right w-auto"
                                onClick={() => showChallengesPlan()}
                                disabled={isMyChoice?.length < 2 && !selecteChalnge}
                            >
                                <span>
                                    <i class="fa-solid fa-paper-plane mr-2"></i>
                                </span>
                                Recommend a Growth Plan
                            </button>
                        )}
                        {selecteOption === "Challenges_Plan" && (
                            <button
                                class="btn-blue btn-login d-block float-right w-auto"
                                disabled={selecteOption !== "Challenges_Plan"}
                                onClick={() => handleButton(true)}
                            >
                                Next
                                <span>
                                    {" "}
                                    <i class="fa-solid fa-arrow-right m-0 ml-2"></i>
                                </span>
                            </button>
                        )}
                        {selecteOption === "Create_Plan" && (
                            <>
                                {loader ? (
                                    <div className="buttonDistribotion">
                                        <div className="justify-content-end">
                                            <button
                                                className="btn-blue btn-login d-block ml-auto mr-2  w-auto"
                                                disabled
                                            >
                                                <span className="RounAnimation mr-1"></span>{" "}
                                                Please Wait...
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn-blue btn-login d-block mb-5"
                                        onClick={() => createRecomndedChallengePlan()}
                                    >
                                        <span>
                                            <i class="fa-solid fa-paper-plane mr-2"></i>
                                        </span>
                                        Select Growth Plan
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HelpMeRightPanel;