import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as image from "../../resources/images";
import { getSequnceSort } from "../../utils/helper";
import Vicky from "../controls/Vicky";
import { selectOptionData } from "../../redux/actions";
const ShowNuggetPopup = ({ closeNugget, modalData }) => {
	const dispatch = useDispatch();

	// console.log("ShowNuggetPopup @@@@", modalData);
	return (
		<div className="NuggetPopup downgradepopup">
			<div className="NuggetPopupTitle flex modal-header">
				<div className="NuggetVickyImage">
					<h4 className="flexone">
						<img src={image.Powericon} alt="" className="mr-2" />
						{modalData?.title}
					</h4>
				</div>
				<button className="btn btn-primary" data-dismiss="modal" onClick={() => closeNugget()}>
					<i className="fa-regular fa-xmark m-0"></i>
				</button>
			</div>
			<div className="NuggetPopupDesc d-flex align-items-start justify-content-between pt-1">
				<div className="NugeetsSolution">
					{
						modalData?.optNuggetData?.map((option, index) => (
							<div className="otherOptionssol" key={index}>
								<p>
									<div className="nuggetsolitems">
										<h6>
											<strong>
												{" "}
												Option {`${option?.sequence}`}:
											</strong>{" "}
											<span
											// dangerouslySetInnerHTMLL={{
											// 	__html: option?.question,
											// }}
											/>{option?.question}{" "}
										</h6>
										<p
											dangerouslySetInnerHTML={{
												__html: option?.help,
											}}
										/>{" "}
									</div>
									<Vicky
										text={
											"option " +
											option?.sequence +
											" ..." +
											option?.question +
											"  ..." +
											option?.help
										}
										id={option?.sequence}
									// data={data}
									/>
								</p>
								<br />
							</div>
						))
					}
					{!modalData?.questionData?.options?.every((item) => item?.isUserSelected) && modalData?.questionData?.attempted && (
						<React.Fragment>
							<h5>
								Nice work! But here are the other choices
								you haven’t seen, to get some better
								insight...
							</h5>
							<br />
						</React.Fragment>
					)}
					{modalData?.questionData?.attempted && (
						getSequnceSort(modalData?.questionData?.options)?.map(
							(option, optionIndex) =>
								!option?.isUserSelected && (
									<React.Fragment key={optionIndex}>
										<div className="otherOptionssol">
											<p>
												<div className="nuggetsolitems">
													<h6>
														<strong>
															{" "}
															Option {`${option?.sequence}`}:
														</strong>{" "}
														<span
															dangerouslySetInnerHTML={{
																__html: option?.question,
															}}
														/>{" "}
													</h6>
													<p
														dangerouslySetInnerHTML={{
															__html: option?.help,
														}}
													/>{" "}
												</div>
												<Vicky
													text={
														"option " +
														option?.sequence +
														" ..." +
														option?.question +
														"  ..." +
														option?.help
													}
													id={option?.sequence}
												// data={data}
												/>
											</p>
											<br />
										</div>
									</React.Fragment>
								)
						)
					)}
				</div>
			</div>
			<div className="NuggetPopupFooter d-flex">
				<div className="input-group full-Width-group basic_details_form">
					<div className="form-group BDsubmitbutton d-flex m-0 ">
						<div className="flexone pl-2 haveAttempts">
							{
								!modalData?.questionData?.attempted && (
									<p className="flexone">
										<img src={image.exclamationTrangle} className="mr-2" />
										You have {3 - modalData?.questionData?.invalidAttempt} more attempt{(3 - modalData?.questionData?.invalidAttempt) > 1 ? 's' : ''} left.
									</p>
								)
							}
						</div>
						<button
							type="button"
							className="btn-blue btn-login d-block mb-5 ml-auto mr-2 cancelbutton"
							onClick={() => closeNugget()}
						>
							<span
							// key={
							// 	data?.kc?.length > 0 &&
							// 	data?.kc[selectedQuestionIndex].id
							// }
							>
								<i className="fa-solid fa-xmark mr-2"></i>
							</span>
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
export default ShowNuggetPopup;