import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as image from "../../resources/images";
import { getSequnceSort } from "../../utils/helper";
const MyAvtar = ({
	showSelectAvtar,
	avtarData,
	myAvtar,
	loggedInUser,
	handleBuy,
	loader,
}) => {
	return (
		<div className="Pick_yourAvtar">
			<h4 className="text-center flex mt-0 mb-3 mr-3">
				Pick Your Avatar{" "}
				{
					avtarData?.remainingPoints > 0 && (
						<span>
							{avtarData?.remainingPoints}
							<img src={image.money_bag} />
						</span>
					)
				}
			</h4>
			<div className="flex align-items-start mt-3">
				<div className="Your_Avtar flexone justify-content-center">
					<div>
						<div className="Your_avatr_img">
							<img src={myAvtar?.rightImageUrl} alt="" />

						</div>
						<p className="mb-3 mt-3">{/* Avatar Name */}</p>
						<p className="mt-3 mb-3">
							<span className="but_nowbtn">
								{" "}
								{
									loader ? (
										<div className="flexone justify-content-center">
											<button
												className="btn-blue btn-login d-block ml-auto mr-2 "
												disabled
											>
												<span className="RounAnimation mr-1"></span> Please
												Wait...
											</button>
										</div>
									) : (
										<button
											type="button"
											className="btn-blue btn-login d-block mb-3"
											onClick={() => handleBuy()}
										>
											<>
												{myAvtar?.isUserPurchased ? (
													<strong>
														<i class="fa-solid fa-paper-plane mr-2 m-0"></i>
														Select
													</strong>
												) : (
													<span>
														<i class="fa-solid fa-cart-shopping mr-2"></i>{" "}
														Buy
													</span>
												)}
											</>
										</button>
									)
								}
							</span>
						</p>
						<div className="Avatar_desc"></div>
					</div>
				</div>
				<ul
					class=" my_lockerlist"
					aria-labelledby="barlistdropdown"
					x-placement="top-start"
				>
					{getSequnceSort(avtarData?.avatarRec)?.map((avtr, index) => (
						<li class="pointer" key={index} onClick={() => showSelectAvtar(avtr)}>
							<span>
								<img src={avtr?.leftImageUrl} />
							</span>
							<span className="Avtar_points">
								{avtr?.avtarpoint}<img src={image.money_bag} />
							</span>
							{
								avtr?.isUserPurchased && (<span className="buyed_icon"><img src={image.buyed_icon} alt="" /></span>)
							}
							{
								!avtr?.isUserPurchased && (<span className="Locked_Avtar"><i class="fa-sharp fa-solid fa-lock"></i></span>)
							}
							{
								myAvtar?.avtarId === avtr?.avtarId && (<span className="selected_avatar_"><img src={image.newcheckicon} alt="" /></span>)
							}
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}
export default MyAvtar;