import React, { useState } from "react";
import * as image from "../../resources/images";
const QuizInformation = ({ closePopup, quizType }) => {
    console.log("QuizInformation ",quizType);
    return (
        <div className="newinfo_popup newinfo_popupdimension pacechangepopuo">
						<div
							className="modal d-flex downgradepopup sectionscorecard"
							id="schoolactivity182"
							tabIndex="-1"
							role="dialog"
							aria-labelledby="exampleModalLabel"
							aria-hidden="true"
						>
							<div className="modal-dialog modal-lg checkboxQuestionsScoring" role="document">
								<div className="modal-content">
									<div className="modal-header">
										<div className="heading border-0 p-0 w-100">
											<h2 className="flex">
												<span>
													<img src={image.Scorecardicon} alt="" className="mr-2" />
													Scoring
												</span>
												<button className="btn btn-primary" data-dismiss="modal" onClick={() => closePopup()}>
													<i className="fa-regular fa-xmark m-0"></i>
												</button>
											</h2>
										</div>
									</div>
									<div className="modal-body m-0 pt-0 paceSteps">
										<div class="ScenecerelateddQuiz border-0 mb-0">
											<div class="PaceModeSel pl-2 pr-2">
												<p className="pb-3 pt-2">
													{quizType === "CHECKBOXQ" && (
														<>
															You have 3 attempts to find the correct combination of answers. Each submission that contains a unique combination will count as an attempt.
														</>
													)}
													{quizType === "RADIOQ" && (
														<>
															You have 3 attempts to find the correct answer. Each submission that contains a unique answer will count as an attempt.
														</>
													)}
												</p>

												<p className="pb-3 pt-2">
													{quizType === "CHECKBOXQ" && (
														<>
															Points for the correct answer combination will be awarded as follows:
														</>
													)}
													{quizType === "RADIOQ" && (
														<>
															Points for the correct answer will be awarded as follows:
														</>
													)}
												</p>

												<div className="welcomscreenContent lockContentPOpups p-0 w-100">
													<ul>
														<li>
															{" "}
															<i class="fa fa-circle mr-2 mt-1 fa-4x"></i>
															<h4>
																1st attempt – 10 points
															</h4>
														</li>
														<li>
															<i class="fa fa-circle mr-2 mt-1"></i>
															<h4>
																2nd attempt – 5 points
															</h4>
														</li>
														<li>
															<i class="fa fa-circle mr-2 mt-1"></i>
															<h4>
																3rd attempt – 1 point
															</h4>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									<div className="modal-footer">
										<div className="form-group BDsubmitbutton d-flex m-0">
											<div className="buttonDistribotion">
												<div className="buttonDistribotion justify-content-end">
													<button
														type="button"
														className="btn-blue btn-login d-block mb-5 cancelbutton"
														onClick={() => closePopup()}
													>
														<i className="fa-solid fa-xmark"></i> Close
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
    );
}
export default QuizInformation;