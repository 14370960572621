import React, { useState } from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { PATHS } from "../../utils";
import * as image from "../../resources/images";
import { getUrlSegment } from "../../utils/helper";


const SecHeader = () => {
	const [mobNavigation, semobNavigation] = useState(false)
	const mobile_navigationfun = () => {
		if (mobNavigation === true) {
			semobNavigation(false)
		}
		else {
			semobNavigation(true)
		}
	}
	return (
		<div className={`header-dnd-area2 en-header header ${mobNavigation === true ? "Mobile_Navigation" : ""}`}>
			<div className='container-fluid header__container content-wrapper'>
				<div className='flex'>
					<div className='bloomLogo'>
						<a href="https://bloomster.com/"> <img src={image.vicky_logo_LP} /></a>
					</div>
					<div className='Hub_Navigation'>
						<ul>
							{
								window.location.origin === "https://platform.bloomster.com" ? (
									<>
										<li>
											<a href="javascript:void(0)">Courses<i class="fa-regular fa-angle-down"></i></a>
											<ul className="menu__submenu">
												<li className="menu__item active_pltform">
													<a href="https://bloomster.com/course-library">Course Library</a>
												</li>
												<li className="menu__item ">
													<a href="javascript:void(0)" className="flex">Course Bundle <i class="fa-regular fa-angle-down"></i></a>
													<ul className="menu__submenu deep_sub_menu">
														<li className="menu__item active_pltform">
															<a href="https://bloomster.com/bundle/new-year-new-you">New Year – New You!</a>
														</li>
														<li className="menu__item ">
															<a href="https://bloomster.com/bundle/managing-stress-and-emotions-bundle">Managing Stress & Emotions</a>
														</li>

													</ul>
												</li>

											</ul>
										</li>
										<li>
											<a href='javascript:void(0)'>How Bloomster Works<i class="fa-regular fa-angle-down"></i></a>
											<ul className="menu__submenu">
												<li className="menu__item active_pltform">
													<a href="https://bloomster.com/online-platform">Home Edition</a>
												</li>
												<li className="menu__item ">
													<a href="https://bloomster.com/classroom">School Edition</a>
												</li>

											</ul>
										</li>
										<li>
											<a href="https://bloomster.com/pricing">Pricing</a>
										</li>
										{/* <li>
											<a href="https://bloomster.com/how-bloomster-works">About</a>
										</li> */}
										<li>
											<a href="#">Resources<i class="fa-regular fa-angle-down"></i></a>
											<ul className="menu__submenu">
												<li className="menu__item ">
													<a href="https://bloomster.com/blog">Blog</a>
												</li>
												{/* <li className="menu__item ">
												   <a href="javascript:void(0)" className="flex">Course Bundle <i class="fa-light fa-angle-down"></i></a>
										              <ul className="menu__submenu deep_sub_menu">
												        <li className="menu__item ">
												          <a href="https://bloomster.com/bundle/new-school-year-new-you">New School Year, New You!</a>
												        </li>
										        	  </ul>
												</li> */}
												<li className="menu__item ">
													<a href="https://bloomster.com/ebook-library">eBook Library</a>
												</li>
												<li className="menu__item ">
													<a href="https://bloomster.com/app">Mobile App</a>
												</li>
											</ul>
										</li>
									</>
								) : (
									<>
										<li>
											<a href="javascript:void(0)">Courses<i class="fa-regular fa-angle-down"></i></a>
											<ul className="menu__submenu">
												<li className="menu__item active_pltform">
													<a href="https://bloomster.com/course-library">Course Library</a>
												</li>
												<li className="menu__item ">
													<a href="javascript:void(0)" className="flex">Course Bundle <i class="fa-light fa-angle-down"></i></a>
													<ul className="menu__submenu deep_sub_menu">
														<li className="menu__item active_pltform">
															<a href="https://bloomster.com/bundle/new-year-new-you">New Year - New You!</a>
														</li>
														<li className="menu__item ">
															<a href="https://bloomster.com/bundle/managing-stress-and-emotions-bundle">Managing Stress & Emotions</a>
														</li>

													</ul>
												</li>

											</ul>
										</li>
										<li>
											<a href='javascript:void(0)'>How Bloomster Works<i class="fa-regular fa-angle-down"></i></a>
											<ul className="menu__submenu">
												<li className="menu__item active_pltform">
													<a href="https://bloomster.com/online-platform">Home Edition</a>
												</li>
												<li className="menu__item ">
													<a href="https://bloomster.com/classroom">School Edition</a>
												</li>

											</ul>
										</li>
										<li>
											<a href="https://bloomster.com/pricing">Pricing</a>
										</li>
										{/* <li>
											<a href="https://bloomster.com/how-bloomster-works">About</a>
										</li> */}
										<li>
											<a href="#">Resources<i class="fa-regular fa-angle-down"></i></a>
											<ul className="menu__submenu">
												<li className="menu__item ">
													<a href="https://bloomster.com/blog">Blog</a>
												</li>
												{/* <li className="menu__item ">
												   <a href="javascript:void(0)" className="flex">Course Bundle <i class="fa-light fa-angle-down"></i></a>
										              <ul className="menu__submenu deep_sub_menu">
												        <li className="menu__item ">
												          <a href="https://bloomster.com/bundle/new-school-year-new-you">New School Year, New You!</a>
												        </li>
										        	  </ul>
												</li> */}
												<li className="menu__item ">
													<a href="https://bloomster.com/ebook-library">eBook Library</a>
												</li>
												<li className="menu__item ">
													<a href="https://bloomster.com/app">Mobile App</a>
												</li>
											</ul>
										</li>
									</>
								)
							}
							<li className="hdr_signin_btn">
								<NavLink
									to={PATHS.USERSIGNIN}
									className={
										getUrlSegment()[0] === "signin" ? "activepage" : ""
									}>
									Sign In
								</NavLink>
							</li>
							<li className='Singnupbtn'>
								<NavLink
									to={PATHS.USERSIGNUP}
									className={
										getUrlSegment()[0] === "signup" ? "activepage" : ""
									}>
									Sign Up
								</NavLink>
							</li>
						</ul>

					</div>
					<div className="mobileNavigation" onClick={mobile_navigationfun}>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SecHeader;
