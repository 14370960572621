import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as image from "../../resources/images";
import { getSequnceSort } from "../../utils/helper";
import ShowNuggetPopup from "./ShowNuggetPopup";
import { selectOptionData, showModal } from "../../redux/actions";
const UserQuiz = ({ getCompleteModuleData,
    moduleData,
    questionIndex,
    setQuestionIndex,
    setSurveyIndex,
    surveyIndex }) => {

    const dispatch = useDispatch();
    const { defaultChildData, selectOptData } = useSelector((state) => state.collections);

    const getAllQuestions = getSequnceSort(moduleData?.questions);


    useEffect(() => {
        if (moduleData) {
            let qstnIndx = getAllQuestions?.findIndex((item) => !item?.attempted)
            if (qstnIndx !== -1) {
                setQuestionIndex(qstnIndx);
            } else {
                setQuestionIndex(0);
            }


        }
    }, [moduleData])

    useEffect(() => {
        if (moduleData) {
            let tempOpt = getAllQuestions[questionIndex]?.options?.filter((item) => item?.attempted);
            tempOpt = tempOpt ? tempOpt : [];
            let title = tempOpt?.isCorrect ? "Well Done!" : "Close! Keep Going!";
            dispatch(selectOptionData({ type: "", title: title, questionData: getAllQuestions[questionIndex], optNuggetData: tempOpt }));
        }
    }, [questionIndex])


    // console.log("moduleData : ", moduleData, questionIndex, getCompleteModuleData, "-----");

    const [optNuggetData, setOptNuggetData] = useState();

    const handleOptionRadio = (selectedOpt) => {
        let getAllOption = getAllQuestions[questionIndex]?.options;
        getAllOption?.map((data, key) => {

            if (getAllQuestions[questionIndex]?.attempted) {
                data.isUserSelected = true;
            }
            else {
                if (selectedOpt?.id === data?.id) {
                    selectedOpt.attempted = true;
                } else {
                    data.attempted = false;
                }

            }
        })
        let title = selectedOpt?.isCorrect ? "Well Done!" : "Close! Keep Going!";

        // console.log("selectOptData?.type user : ", selectedOpt);
        if (getAllQuestions[questionIndex]?.attempted) {
            dispatch(selectOptionData({
                type: "ShowNuggetPopup",
                title: title,
                questionData: getAllQuestions[questionIndex],
                optNuggetData: [selectedOpt]
            }));
        } else {
            dispatch(selectOptionData({
                type: "",
                title: title,
                questionData: getAllQuestions[questionIndex],
                optNuggetData: [selectedOpt]
            }));
        }
        setOptNuggetData(selectedOpt);
    }

    const handleOptionCheckBox = (chooseOption) => {
        if (!getAllQuestions[questionIndex]?.attempted) {
            chooseOption.attempted = !chooseOption?.attempted;
        }
        let optArr = [];
        let getAllOption = getAllQuestions[questionIndex]?.options;
        getAllOption?.map((item, key) => {
            if (getAllQuestions[questionIndex]?.attempted) {
                item.isUserSelected = true;
            }
            if (item?.attempted) {
                optArr?.push(item);
            }
        })

        let title = chooseOption?.isCorrect ? "Well Done!" : "Close! Keep Going!";

        if (getAllQuestions[questionIndex]?.attempted) {
            dispatch(selectOptionData({
                type: "ShowNuggetPopup",
                title: title,
                questionData: getAllQuestions[questionIndex],
                optNuggetData: [chooseOption]
            }));
        } else {
            dispatch(selectOptionData({
                type: "",
                title: title,
                questionData: getAllQuestions[questionIndex],
                optNuggetData: optArr
            }));
        }
        setOptNuggetData(chooseOption);
    }

    console.log("?.type user 111: ", selectOptData);


    const handleCloseNugget = () => {
        setOptNuggetData();
    }

    const handleQuizeNext = (type) => {
        if (type) {
            setQuestionIndex(questionIndex + 1);
            if (getAllQuestions?.length === questionIndex + 1) {
                setSurveyIndex(surveyIndex + 1);
            }
        } else {
            setQuestionIndex(questionIndex - 1);
            if (questionIndex === 0) {
                setSurveyIndex(surveyIndex - 1);
            }
        }
    }
    return (
        <>
            <div className="CourseCardWrapper fullHeight100">
                <div class="AllNuggQuestionList QuestionListSlide">
                    <ul>
                        <li className={`pointer ${surveyIndex === 0 && questionIndex === 0 ? "DissableArrow" : ""}`}
                            onClick={() => handleQuizeNext(false)}
                        >
                            <span>
                                <i class="fa-regular fa-angle-left"></i>Back
                            </span>
                        </li>
                        {getAllQuestions?.map((questions, index) => (
                            <li class={`${questions?.attempted ? "bg-success text-light" : questionIndex == index ? "QAttemptAct" : ""}`}
                                style={{ cursor: "pointer" }}
                                onClick={() => setQuestionIndex(index)}
                            >{index + 1}</li>
                        ))}
                        {/* <li class="QAttemptAct">2</li>
            <li>3</li> */}
                        <li className={`pointer ${getCompleteModuleData?.survey?.length - 1 === surveyIndex
                            && questionIndex === getAllQuestions?.length - 1 ? "DissableArrow" : ""}`}
                            onClick={() => handleQuizeNext(true)}
                        >
                            <span>
                                Next
                                <i class="fa-regular fa-angle-right"></i>
                            </span>
                        </li>
                    </ul>
                </div>

                <div class="lSidebarcontentwrap CourseCardWrapper fullHeight100">
                    <div class="intructionsBox ScenecerelateddQuiz Analysistxt  seneriotext">
                        <p><strong>{moduleData?.name}</strong></p>
                        <p class="d-inline">
                            <span>
                                {" "}
                                {/* <i class="fa-solid fa-quote-left"></i> */}
                            </span>
                            <em>
                                <span class="m-0"
                                    dangerouslySetInnerHTML={{
                                        __html: moduleData?.learning
                                    }}
                                >
                                </span>
                            </em>
                            <span key={moduleData?.id}>
                                {/* <i class="fa-solid fa-quote-right"></i> */}
                            </span>
                        </p>
                    </div>
                    <div class="ScenecerelateddQuiz p-0">
                        <div class="how_challenges_work p-2">
                            <h4 className="mb-2">Instructions:</h4>
                            <ul>
                                {moduleData?.instructions?.map((instText, key) => (
                                    <li className="p-0" key={key}>
                                        <img src={image.Capturevdfhfveh} alt="" />
                                        {instText}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div class="signupType rangetypeQuestion textAreatypeQuestion mb-2 p-3">
                            <h4 class="mb-2 d-flex">
                                <span>
                                    <img
                                        src="/static/media/SceneQuestionicon.e6a54a848cd9c004ed57.png"
                                        alt=""
                                    />
                                </span>
                                <div>
                                    <span>{getAllQuestions[questionIndex]?.question}</span>
                                    <span class="pointer" onClick={() => dispatch(showModal({ type: "QuizInformation", quizType: getAllQuestions[questionIndex]?.type }))}>
                                        <img src={image.chat_icon} className="chat_icon" alt="" />
                                    </span>
                                </div>
                            </h4>

                            {getSequnceSort(getAllQuestions[questionIndex]?.options)?.map((options, key) => (
                                <>
                                    {getAllQuestions[questionIndex]?.type === "RADIOQ" && (
                                        <label class="Selcheckbox align-items-start d-block" key={key}>
                                            <span class="fw-bold">{key + 1}{". "}</span>
                                            {options?.question} {" "}
                                            <input type={"radio"} id={"option1" + questionIndex} name={"option1" + questionIndex}
                                                checked={options?.attempted}
                                                onClick={() => handleOptionRadio(options)}
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                    )}
                                    {getAllQuestions[questionIndex]?.type === "CHECKBOXQ" && (
                                        <div className="signupType checktypequestion m-0">
                                            <label class="Selcheckbox align-items-start d-block" key={key}>
                                                <span class="fw-bold">{key + 1}{". "}</span>
                                                {options?.question}{" "}
                                                <input type={"checkbox"} id="Public" name="checkboxOption"
                                                    checked={options?.attempted}
                                                    onClick={() => handleOptionCheckBox(options)}
                                                />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    )}
                                </>

                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default UserQuiz;
