import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as image from "../../resources/images";
import { getSequnceSort, avaterUserPositoin, dateSortingByStartDate, textTrim, getUsStatnderdTimeWithMonthName, getNumberOfDays } from "../../utils/helper";
import { PATHS } from "../../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { defaultChild, lockPopup, parentToolsModal, setSubscribeModal, showModal } from "../../redux/actions";
import CongratulationCard from "../widget/CongratulationCard";
import LessonCongratulationsPopup from "../dimensions/course/vickycourses/LessonCongratulationsPopup";
import { getCourseSummary, getRewardData } from "../../redux/actions/APIs";
import { Circle } from "rc-progress";
import ReactTooltip from "react-tooltip";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
const DashBoardUserProress = ({ showPlanCourseData,
	handleMyPlan,
	gotoSelectedPlan,
	getSelectePlanObj,
	handleOption,
	chooseOption,
	handleGotoCourse,
	moduleObj, setModuleObj, courseIndex, setSelectedCourseIndex
}) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const {
		defaultChildData,
		getMyPlanData,
		activityDataResp,
		alluserdetails,
		loggedInUser,
		response,
		modalData,
	} = useSelector((data) => data.collections);

	const [showCongratulationModel, setShowCongratulationModel] = useState(false);
	const [congratulationPopup, setCongratulationPopup] = useState(false);
	const [courseActivityId, setCourseActivityId] = useState([]);
	const [openReward, setOpenReward] = useState();
	const [isShowAvtr, setIsShowAvtr] = useState(false);
	const [showCorsPopup, setShowCorsPopup] = useState(false);
	const [getUsTimeZone, setUsTimeZone] = useState("US/Eastern");
	const [actvChallengeData, setActvChallengeData] = useState();
	const myAvtar = defaultChildData?.avatar;

	useEffect(() => {
		if (activityDataResp?.records?.length > 0) {
			setActvChallengeData(activityDataResp?.records[0]);
		}
	}, [activityDataResp])


	const showScorCardpopUp = (result, data, completion, course) => {
		if (result && completion > 0) {
			if (course?.isAcademic) {
				setCongratulationPopup(result);
			} else {
				setShowCongratulationModel(result);
			}
			setCourseActivityId(data);
		} else {
			setCongratulationPopup(false);
			setShowCongratulationModel(false);
			setCourseActivityId([]);
		}
	};

	// console.log("showPlanCourseData @@@ : ", showPlanCourseData);
	const showLockPopUp = (courseObj, module) => {
		//	console.log("showLockPopUp : ", courseObj, module);
		dispatch(lockPopup({ isLocked: true, type: courseObj }));
	};

	const _redirectLesson = (activity, keyModule, course) => {
		if (!activity?.isLocked) {
			let LessonDetail;
			if (activity?.isLesson) {
				LessonDetail = PATHS.LESSIONDETAIL_STR;
			} else {
				LessonDetail = PATHS.SOCIALCOURSE_STR;
			}
			let childId;
			if (defaultChildData?.id) {
				childId = defaultChildData?.id;
			}
			history.push({
				pathname:
					LessonDetail +
					childId +
					"/" +
					course?.id +
					"/" +
					course?.skillId +
					"/" +
					activity?.id,
				state: {
					//	tab: type,
					tab: "Series",
					dimension: course?.dimension,
					isEnrolled: course?.isEnrolled,
					course: course,
				},
			});
		}

	};

	const handleCourseSummary = (course) => {
		dispatch(getCourseSummary(defaultChildData?.id, course?.id));
		dispatch(parentToolsModal({ isCourseReview: true, data: course }));
	};
	const [courseLevelArr, setCourseLevelArr] = useState([]);
	// console.log('showPlanCourseData : ', showPlanCourseData);
	useEffect(() => {
		if (showPlanCourseData?.length > 0) {
			let userProgressArr = [];
			userProgressArr = showPlanCourseData?.length === 4 ? [...showPlanCourseData] :
				[...showPlanCourseData, ...Array(4 - showPlanCourseData?.length).fill({ activities: Array(4).fill({ isLocked: true }) })];
			// console.log("userProgressArr ::: ",showPlanCourseData, userProgressArr, userProgressArr[3]);
			setCourseLevelArr(userProgressArr);
			let isCourse = false;
			let isModule = false;
			showPlanCourseData?.map((course, index) => {
				if (!isCourse && (course?.isProgress || (course?.isCompleted && showPlanCourseData[index + 1]?.completion === 0)
					|| (showPlanCourseData?.length === index + 1 && course?.isCompleted))) {
					isCourse = true;
					setSelectedCourseIndex(index);
					getSequnceSort(course?.activities)?.map((module, key) => {
						if (!isModule && (module?.isProgress || (module?.isCompleted && course?.activities[key + 1]?.completion === 0) || (key + 1 === 4 && module?.isCompleted))) {
							isModule = true;
							setModuleObj(module);
						}
					})
				}
			})
			if (!isModule) {
				handleModule(getSequnceSort(showPlanCourseData[0]?.activities)[0]);
			}
		}
	}, [showPlanCourseData])

	// console.log("userProgressArr courseLevelArr::: ",showPlanCourseData, courseLevelArr, courseLevelArr?.length);

	const handleAvtar = (courseIndex, courseobj) => {
		return (avaterUserPositoin(courseIndex + 1, myAvtar));
		// const progressCorsObj = showPlanCourseData?.find((course) => course?.isProgress && !course?.isCompleted)
		// if (progressCorsObj?.id === courseobj?.id) {
		// 	return (avaterUserPositoin(courseIndex + 1));
		// }
	}

	const handleAvtarOnCourse = (courseIndex) => {

		return (avaterUserPositoin(courseIndex, myAvtar));
	}

	const handleModule = (data) => {
		setModuleObj(data);
	}

	const showModuleObjective = (data, index, course, moduleIndx) => {
		setModuleObj(data);
		setSelectedCourseIndex(index);
		if (!defaultChildData?.isSubscriber && moduleIndx > 0) {
			dispatch(setSubscribeModal(course));

		} else if (data?.isLocked) {
			dispatch(lockPopup({ isLocked: true, type: course }));
		}
		//	console.log('$$ course', course);
	}

	useEffect(() => {
		if (response?.success && response?.records[0]?.avtarId && alluserdetails) {
			let childObj = alluserdetails?.records[0]?.children?.filter(
				(val) => val?.id === defaultChildData?.id
			);
			dispatch(defaultChild(childObj[0]));
		}
	}, [alluserdetails, response])


	const formatDate = (dateString) => {
		const date = new Date(dateString);
		return date.toLocaleDateString("en-US", {
			month: "short",
			day: "numeric",
			year: "numeric",
		});
	};



	useEffect(() => {
		if (loggedInUser?.timeZone) {
			setUsTimeZone(loggedInUser?.timeZone)
		}
	}, [loggedInUser])

	const handleClenderVaiew = (myPlan) => {

		let dayOfWeek = getSelectePlanObj?.dayOfWeek ? getSelectePlanObj?.dayOfWeek?.split(",") : [];
		let holidaysArr = [];
		dispatch(showModal({ type: "CalenderVaiew", data: showPlanCourseData, getUserTimeZone: getUsTimeZone, dayOfWeek: dayOfWeek, holidays: holidaysArr }));
	}

	useEffect(() => {
		if (modalData?.type === "CalenderVaiew") {
			dispatch(setSubscribeModal());
		}
	}, [modalData])



	return (

		<>
			<div className="CourseCardWrapper fullHeight100">
				{
					(showPlanCourseData?.length > 0 && courseLevelArr?.length > 0) ? (
						<div className="Course_height">
							<div className="">
								<div className="planprogresdash flex align-items-start">
									<div className="planprogress_list w-50 p-0">
										{
											getMyPlanData?.records?.length > 0 && (
												<p className="chart_plan_name mt-2 ml-2"><i class="fa-light fa-rocket-launch mr-2"></i>{getSelectePlanObj?.planName}
													{/* <span className="circular_percentage_completed">
													<Circle
													percent={50}
													strokeWidth={15}
													strokeColor="#48cfae"
												/>
												<span class="PercentCompleted">0<span class="percenrSign">%</span></span>
												</span> */}
												</p>
											)
										}
										<div className="staire_map mt-1">
											{courseLevelArr?.map((course, index) => {
												course.overAllCoursePoint = index === 0 ? course.tpoints :
													course.tpoints ? courseLevelArr[index - 1]?.overAllCoursePoint + course.tpoints : "";
												return (
													<>
														<ul>
															{index === 0 ? (
																<li
																>
																	{" "}
																	<div className="course_indx start_staire_index">
																		Start
																		{
																			getMyPlanData?.records?.length > 1 && (
																				<>
																					<span aria-haspopup="true"
																						aria-expanded="false"
																						id="Parenttools"
																						className="pointer module_dropdwn_icon start_staire"
																						data-toggle="dropdown">
																						<i
																							class="fa fa-angle-down ps-1 fa-sm"
																							aria-hidden="true"
																						></i>
																					</span>
																					<ul
																						className="dropdown-menu dropdown-menu-end course_dropdwn"
																						aria-labelledby="Parenttools"
																					>
																						<li className="rewardDrpDwnd">
																							<p>
																								Select Plan
																							</p>

																						</li>
																						{dateSortingByStartDate(getMyPlanData?.records)?.reverse()?.map((plan, index) => (
																							<li className={`rewardDrpDwnd pointer ${getSelectePlanObj?.planId === plan?.planId ? "Active_plan_" : ""}`} onClick={() => gotoSelectedPlan(index)}>
																								<p className="">
																									{" "}
																									<img src={image.leanerPlan} alt="" />
																									{plan?.planName}
																								</p>
																							</li>
																						))}

																					</ul>
																				</>
																			)
																		}
																	</div>
																	{(course?.completion === 0 || getMyPlanData?.records?.length === 0) && (
																		<div className="character">
																			<img src={myAvtar?.rightImageUrl} />
																		</div>
																	)}
																</li>
															) : (
																<li >
																	{" "}
																	<div className="course_indx" onClick={() => { !courseLevelArr[index - 1]?.overAllCoursePoint && setShowCorsPopup(true) }}>
																		C{index}
																		{((courseLevelArr[index - 1]?.isCompleted && !course?.isCompleted && !course?.isProgress) ||
																			(showPlanCourseData?.every((courseObj) => courseObj?.isCompleted) &&
																				showPlanCourseData?.length === index)) && (
																				handleAvtarOnCourse(index - 1)
																			)}
																		{showPlanCourseData?.length === index && getMyPlanData?.records?.length > 0 && (
																			<>
																				{index === 2 ? (
																					<span className="plan_end_flag">
																						<img src={image.right_fliag_stand} />
																					</span>
																				) : (
																					<span className="Left_plan_end_flag">
																						<img src={image.intrest} />
																					</span>
																				)}
																			</>

																		)}
																		{courseLevelArr[index - 1]?.overAllCoursePoint && (
																			<>
																				<span aria-haspopup="true"
																					aria-expanded="false"
																					className="pointer module_dropdwn_icon"
																					id="ParenttoolsCourse"
																					data-toggle="dropdown">
																					<i
																						class="fa fa-angle-down ps-1 fa-sm"
																						aria-hidden="true"
																					></i>
																				</span>
																				<ul
																					className="dropdown-menu dropdown-menu-end course_dropdwn"
																					aria-labelledby="ParenttoolsCourse"
																				>
																					<li
																						className={`rewardDrpDwnd pointer flex justify-content-between`}
																						onClick={() =>
																							handleCourseSummary(courseLevelArr[index - 1])
																						}>
																						<p>
																							<img src={image.reviewicon} alt='' />
																							Course Summary
																						</p>
																						<p className="">
																							{courseLevelArr[index - 1]?.points}
																							<img src={image.money_bag} alt="" className="m-0 ml-1" />

																						</p>
																					</li>


																					<li className={`rewardDrpDwnd pointer`} onClick={() => handleMyPlan(courseLevelArr[index - 1]?.id)}>
																						<span>
																							<img src={image.mortarboard} />
																							Go to course
																						</span>

																					</li>
																				</ul>
																			</>
																		)}
																		{courseLevelArr[index - 1]?.overAllCoursePoint && (
																			<span className="poitbag_score">
																				<img src={image.money_bag} />
																				<span>{courseLevelArr[index - 1]?.overAllCoursePoint}</span>
																			</span>
																		)}

																	</div>
																</li>
															)}
															{getSequnceSort(course?.activities)?.map((module, key) => (
																<li className="pointer module_dropdwn_icon" key={key}
																	onClick={() => { !course?.overAllCoursePoint ? setShowCorsPopup(true) : showModuleObjective(module, index, course, key) }}
																>
																	<div className="module_num" key={module?.id}>
																		{module?.isLocked && (<span className="locked_module"><i class="fa-solid fa-lock"></i></span>)}
																		<span className="modu_numtxt">M{key + 1}</span>
																		{
																			module?.endDate && (
																				<span class="days_left" id="days_left">
																					{!module?.isCompleted && (getNumberOfDays(module?.endDate) + "d")}
																					<div id="date_dtl" place='bottom' className={"date_tooptip"}>
																						<p>{formatDate(module?.endDate)}</p>
																						{/* <p>Start date: {formatDate(module?.startDate)}
																					<br /> End Date: {formatDate(module?.endDate)} </p> */}
																					</div>
																				</span>
																			)
																		}

																		{((module?.completion > 0 && course?.activities[key + 1]?.completion === 0) || (key === 3 && module?.isProgress))
																			&& handleAvtar(index, course)}
																		{!module?.isLocked && (
																			<>
																				<span
																					aria-haspopup="true"
																					aria-expanded={false}
																					className="module_dropdwn_icon"
																					id="ParenttoolsModule"
																					data-toggle={"dropdown"}>
																					<i
																						class="fa fa-angle-down ps-1 fa-sm"
																						aria-hidden="true"
																					></i>
																				</span>
																				<ul
																					className="dropdown-menu dropdown-menu-end course_dropdwn"
																					aria-labelledby="ParenttoolsModule"
																				>
																					{!course?.isComic && (
																						<>
																							<li className={`rewardDrpDwnd flex justify-content-between`}>
																								<span>
																									<img src={image.journey_icon1} />
																									Module Objective
																								</span>
																							</li>
																							{module?.badgeName && (
																								<li className={`rewardDrpDwnd flex justify-content-between`}>
																									<span>
																										<img src={module?.badgeImageUrl ? module?.badgeImageUrl : image.score_badge} />
																										{module?.badgeName}
																									</span>
																								</li>
																							)}

																							<li className={`rewardDrpDwnd flex justify-content-between`}
																								onClick={() => handleClenderVaiew()}
																							>
																								<span>
																									<img src={image.calenderI_info} />
																									{getUsStatnderdTimeWithMonthName(
																										module?.startDate,
																										getUsTimeZone
																									)}
																								</span>
																							</li>
																						</>
																					)}
																					{module?.completion > 0 && (
																						<li className={`rewardDrpDwnd  `} onClick={() => showScorCardpopUp(
																							true,
																							[
																								defaultChildData?.id,
																								course?.id,
																								module?.id,
																							],
																							module?.completion,
																							course
																						)}
																						>
																							<p>
																								{" "}
																								<img src={image.Scorecardicon} alt="" />
																								Scorecard
																							</p>
																						</li>
																					)}
																					{/* {module.showReward && (
											<li className={`rewardDrpDwnd  `} onClick={() => viewReward(module, false, key)}>
											<p className="pointer">
												<img src={image.rewardimg} alt="" />
												Reward
											</p>
											</li>
										)} */}
																					<li className={`rewardDrpDwnd  flex justify-content-between`} onClick={() => _redirectLesson(module, key, course)}>
																						<span>
																							<img src={image.LessonIcon1} />
																							Go to Lesson
																						</span>
																						<p className="pointer">
																							<img src={image.money_bag} alt="" />
																							{module?.points}
																						</p>
																					</li>
																				</ul>
																			</>
																		)}

																	</div>
																	{module?.completion > 0 && (
																		<div className="green_bg" style={{ width: module?.completion + "%" }}></div>
																	)}
																</li>
															))}
														</ul>
														{index + 1 === 4 && (
															<ul className="end_course_">
																<li

																>
																	{" "}
																	<div className="course_indx" onClick={() => { !course?.overAllCoursePoint && setShowCorsPopup(true) }}>
																		C4
																		{courseLevelArr?.every((courseObj) => courseObj?.isCompleted) && (
																			handleAvtarOnCourse(index + 1)
																		)}
																		{showPlanCourseData?.length === index + 1 && (
																			<span className="plan_end_flag">
																				<img src={image.right_fliag_stand} />
																			</span>
																		)}

																		{course?.overAllCoursePoint && (
																			<>
																				<span
																					aria-haspopup="true"
																					aria-expanded="false"
																					className="pointer module_dropdwn_icon"
																					id="ParenttoolsCourse"
																					data-toggle="dropdown">
																					<i
																						class="fa fa-angle-down ps-1 fa-sm"
																						aria-hidden="true"
																					></i>
																				</span>
																				<ul
																					className="dropdown-menu dropdown-menu-end course_dropdwn"
																					aria-labelledby="ParenttoolsCourse"
																				>
																					{/* <li className={`rewardDrpDwnd  `}>
								<img src={image.gensettings} />
								Parent Tools
								</li>
								<li className={`rewardDrpDwnd  `}>
								<img src={image.Electiclistiocn} />
								Learner Center
								</li> */}
																					<li
																						className={`rewardDrpDwnd pointer flex justify-content-between`}
																						onClick={() =>
																							handleCourseSummary(course)
																						}>
																						<p>
																							<img src={image.reviewicon} alt='' />
																							Course Summary
																						</p>
																						<p className="">
																							{courseLevelArr[index - 1]?.points}
																							<img src={image.money_bag} alt="" className="m-0 ml-1" />

																						</p>
																					</li>

																					<li className={`rewardDrpDwnd  flex`} onClick={() => handleMyPlan(course?.id)}>
																						<span><img src={image.mortarboard} />
																							Go to course</span>
																					</li>
																				</ul>
																			</>
																		)}
																		{course?.overAllCoursePoint && (
																			<span className="poitbag_score">
																				<img src={image.money_bag} />
																				<span>{course?.overAllCoursePoint}</span>
																			</span>
																		)}

																	</div>
																</li>
															</ul>
														)}
													</>
												)
											})}

										</div>
									</div>
									{/* {console.log('moduleObj :', moduleObj)} */}
									{
										(getMyPlanData?.records?.length > 0 && showPlanCourseData && showPlanCourseData[courseIndex]?.hasVideoType) && (
											<div className="module_objective w-50 p-0">
												<div class="plans_contents_ ">
													<div className="px-3 pt-3">
														<h3 className="mb-3 pointer" onClick={() => handleMyPlan(showPlanCourseData[courseIndex]?.id)}><span className="_module_name">C{showPlanCourseData[courseIndex].sequence}</span> {showPlanCourseData[courseIndex]?.name}</h3>
														<h4 className="mb-3 pb-2 pointer" onClick={() => _redirectLesson(moduleObj, "", showPlanCourseData[courseIndex])}><span className="_module_name">M{moduleObj?.sequence}</span>{moduleObj?.name}</h4>
													</div>
													<div className="tabgrid mt-3">
														<ul className="objectibe_module_list flex" >
															{getSequnceSort(showPlanCourseData[courseIndex]?.activities)?.map((module, key) => (
																<li key={key} className={`${module?.id === moduleObj?.id ? "active-tabs" : ""} pointer tabs w-25 justify-content-center`}
																	onClick={() => handleModule(module)}>
																	M{key + 1}
																	{/* <span><img src={image.calenderI_info} alt="" /></span> */}
																</li>
															))}
														</ul>
													</div>
													<div className="px-3">
														<h4 className="mb-3 flex">
															What you’ll learn in this module:{" "}
															<span class="Score_points mr-2">
																<span class="earnnoCoin">{actvChallengeData ? actvChallengeData?.points : 0}</span>
																<img src={image.money_bag}></img>
															</span>
														</h4>
														<ul>
															{getSequnceSort(moduleObj?.objectives)?.map((modObj, index) => (
																<li className="d-flex" key={index}>
																	<div className="obj_prog_ress">
																		<span class="Progresslistitem m-0">
																			<span class="diemensionalProgress progressbar1" style={{ maxWidth: `${modObj?.progress}%`, background: "rgb(32, 201, 151)" }}>

																			</span><span class="percentage_marker" style={{ left: `${modObj?.progress}%` }}>{modObj?.progress}<sub>%</sub></span></span>

																	</div>
																	<span class="">
																		{modObj?.content}
																	</span>
																</li>
															))}
														</ul>
													</div>
												</div>
											</div>
										)
									}
								</div>
							</div>
						</div>
					) : (
						<div className='NhPageWrap'>
							<div className='NHProgreschartList'>
								<ShimmerSimpleGallery
									imageType='circular'
									imageHeight={200}
									caption
								/>
							</div>
						</div>
					)
				}
			</div >
			{showCorsPopup && (
				<div className="newinfo_popup newinfo_popupdimension newEnrollCoursPopup">
					<div
						className="modal d-flex downgradepopup sectionscorecard"
						id="schoolactivity181"
						tabIndex="-1"
						role="dialog"
						aria-labelledby="exampleModalLabel"
						aria-hidden="true"
					>
						<div className="modal-dialog createLearnerLoginPopUp" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<div className="heading border-0 p-0 w-100">
										<h2 className="flex">
											<span>
												<img src={image.exclamationTrangle} alt="" className="mr-2" />
												No Course Here Yet!
											</span>
											<button className="btn btn-primary" data-dismiss="modal" onClick={() => setShowCorsPopup(false)}>
												<i className="fa-regular fa-xmark m-0"></i>
											</button>
										</h2>
									</div>
								</div>
								<div className="modal-body m-0 pt-1">
									<div class="ScenecerelateddQuiz border-0 mb-0">
										<div class="PaceModeSel pl-3">
											<p className="pb-3 pt-2">
												You don’t have a course in this section of your progress map. Explore the catalog and enroll in a course that interests you! Once you enroll, it will be added to the current plan, and you’ll be able to track your progress right here.
											</p>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<div className="form-group BDsubmitbutton d-flex m-0">
										<div className="buttonDistribotion">
											<div className="buttonDistribotion justify-content-end">
												<button
													type="button"
													className="btn-blue btn-login d-block mb-5 cancelbutton"
													onClick={() => setShowCorsPopup(false)}
												>
													<i className="fa-solid fa-xmark"></i> Close
												</button>
												<button
													type="button"
													className="btn-blue btn-login d-block mb-5 w-auto rocket_icon"
													onClick={() => handleGotoCourse()}
												>
													<i class="fa-light fa-rocket-launch mr-2"></i>
													Go to Course Catalog
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{
				showCongratulationModel && (
					<CongratulationCard
						courseActivityId={courseActivityId}
						handleOpenpopup={showScorCardpopUp}
						isContinueButtonClick={false}
					/>
				)
			}

			{
				congratulationPopup && (
					<LessonCongratulationsPopup
						handleCongratulationPopup={showScorCardpopUp}
						courseActivityId={courseActivityId}
					/>
				)
			}

		</>
	);
}
export default DashBoardUserProress;