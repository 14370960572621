import ReactTooltip from "react-tooltip";
import * as image from "../resources/images";
import { MSG } from "./Messages";
import ReactDOMServer from "react-dom/server";

export const bloomsterPolyText = (type, isPoly) => {
  let polyText = "";
  if (type === "polytext_1") {
    polyText =
      <p>
        <div>
          <p>
            Finding the right support for your child’s development has never been easier. Simply select two to four key challenges—like managing emotions, making better decisions, or strengthening relationships. Bloomster then matches these to a tailored Growth Plan, created by experts to build the exact skills your child needs. Each plan blends proven strategies and specialized courses to help them grow and thrive.
          </p>
          <p>&nbsp;</p>
          <p>
            Imagine your child confidently overcoming challenges, excelling in school, and forming strong relationships. Growth Plans do more than address struggles—they foster resilience, creativity, and independence. With expert guidance, your child will gain emotional control, effective communication skills, and the confidence to embrace new opportunities. This is more than just a solution—it’s a path to lifelong success.
          </p>
        </div>
      </p>
  } else if (type === "polytext_2") {
    polyText =
      <p>
        <div>
          <p>
            Right now, your child’s progress map is waiting to be filled with achievements! Enroll in a Growth Plan today and take the first step toward building essential life skills that will help them thrive.
          </p>
          <p>&nbsp;</p>
        </div>
      </p>
  } else if (type === "polytext_3") {
    polyText =
      <p>
        <div>
          <p>
            Parenting through life’s challenges can feel overwhelming, but Bloomster is here to help. Our "Life Events" feature supports parents in guiding children through key moments like school changes, social pressures, or family transitions. Simply select a life event, and Bloomster will recommend tailored Growth Plans to build confidence, resilience, and life skills.
          </p>
          <p>&nbsp;</p>
          <p>
            With Bloomster’s Growth Plans, your child will gain the tools to navigate challenges, communicate effectively, and embrace growth. These expert-designed plans foster resilience and stability, ensuring they are prepared for life’s twists and turns. Select a plan today to empower your child’s future.
          </p>
          <p>&nbsp;</p>

        </div>
      </p>
  }
  polyText = isPoly ? ReactDOMServer.renderToString(polyText) : polyText;
  return polyText;
}