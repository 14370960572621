import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as image from "../../resources/images"; import { getModuleChallenge } from "../../redux/actions/APIs";
const UserCongratulations = () => {
	const dispatch = useDispatch();
	const { defaultChildData, activityDataResp } = useSelector((state) => state.collections);
	const [getModuleData, setModuleData] = useState();

	const [showCongratsCoins, setShowCongratsCoins] = useState(true);
	useEffect(() => {
		if (activityDataResp?.records?.length > 0) {
			let actvityObj = activityDataResp?.records[0];
			setModuleData(actvityObj);
			if (!actvityObj?.isComplete) {
				dispatch(getModuleChallenge(
					defaultChildData?.id,
					actvityObj?.activityId
				))
			}
		}
		setTimeout(() => {
			setShowCongratsCoins(false);
		}, 3000);

	}, [activityDataResp])

	console.log('getModuleData?.badge : ', getModuleData?.badge);

	return (
		<div className="CourseCardWrapper fullHeight100">
			<div className="congratulation_screen">
				{showCongratsCoins && (
					<img src={image.congratulation_confeti} className="confeti_img" />
				)}
				<h3 className="mt-3 flextwo">Congratulation
					<span className="point_congrate">
						{getModuleData?.points}
						<img src={image.money_bag} className="point_bag" />
					</span>
				</h3>
				<div className="badges_earned">
					{ }
					<img src={getModuleData?.badge?.imageUrl ? getModuleData?.badge?.imageUrl : image.animal_badgesdefault} alt="badge" />
					<p>You have earned badge!</p>
					<h4>{getModuleData?.badge?.badgesName}</h4>
				</div>
				<div className="congratemsg p-2 flexone">
					{/* <img src="https://bloomster.s3.us-east-1.amazonaws.com/avatar/3_right.png" className="avatr_image" /> */}
					<img src={defaultChildData?.avatar?.rightImageUrl} className="avatr_image" />
					<h4>
						Awesome work! Your efforts just earned you a shiny new badge!
					</h4>
				</div>
				<div className="varat_point_bg flex">
					{/* <div>
            <img src={image.score_success} className="shild_image" />
           
          </div> */}
				</div>

			</div>
		</div>
	);
};
export default UserCongratulations;
