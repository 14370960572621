import React, { useState } from "react";
import SmartMultiQuiz from "./SmartMultiQuiz";
import Home from "../Home";
import SmartQuizRightSidebar from "../dimensions/course/social/SmartQuizRightSidebar";

const ProfileSmartQuiz = () => {
  const [finalArray, setFinalArray] = useState([]);
  return (
    <div>
      <Home>
        <div className="d-flex align-items-flex-start w-100">
          <div className="LeftbarPannel p-0 leftpannel_Acquisition justify-content-between">
            <SmartMultiQuiz finalArray={finalArray} setFinalArray={setFinalArray} />{" "}
          </div>
          <div className="RightbarPannel p-0 IntelligenceWisetex rightpannelSticky">
            <SmartQuizRightSidebar finalArray={finalArray} />
          </div>
        </div>
      </Home>
    </div>
  );
};

export default ProfileSmartQuiz;
