import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as image from "../../resources/images";
import { getSequnceSort } from "../../utils/helper";
import { NavLink } from "react-bootstrap";
import { PATHS } from "../../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const MyBadges = ({ badgesData, gotoCourse }) => {
	// console.log('badgesData : ', badgesData);
	const myRef = useRef();

	const [getBadgeId, setBadgeId] = useState();


	const handleMouseMove = (myId) => {
		console.log("handleMouseMove : ", myId, getBadgeId);
		if (myId !== getBadgeId) {
			// console.log(`Cursor Position: X: Event: ${e}, ${e.clientX}, Y: ${e.clientY}`);
			setTimeout(() => {
				setBadgeId(myId);
			}, 3000)
		}

	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (myRef && myRef.current && myRef.current.contains(event.target)) {
				//   console.log("handleClik Insise");
			} else {
				//   console.log("handleClik outSide");
				setBadgeId();
			}
		};
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);
	return (
		<>
			{badgesData?.length > 0 ? (
				<div className="myBadgeslist flexone flex-wrap" >
					{badgesData?.map((badges, index) => (
						<div className="mybadges_items flexone flextwo position-relative" onMouseMove={() => handleMouseMove(badges?.badgesId)}>
							{badges?.imageUrl ? (
								<img src={badges?.imageUrl} alt="" />
							) : (
								<img src={image.animal_badgesdefault} alt="" />
							)}
							{badges?.isLock ? (
								<span className="locked_badges">
									<i class="fa-sharp fa-solid fa-lock"></i>
								</span>
							) : (
								<>
									<span className="earnedbegdes">
										<img src={image.earnedbadges} alt="" />
									</span>
								</>
							)}
							{getBadgeId === badges?.badgesId && (
								<table class="leade_bord_detailcard badgesdetails_card" ref={myRef}>
									<tr>
										<td>
											<div class="clbcarddtl">
												<div class="lbuserdtl flexone">
													<div class="lbuserimage">
														{badges?.imageUrl ? (
															<img src={badges?.imageUrl} alt="" />
														) : (
															<img src={image.animal_badgesdefault} alt="" />
														)}
													</div>
													<div class="lbuserName pb-2">
														<h4>{badges?.badgesName}</h4>
														<p className="p-0 mt-2">
															<strong>
																<img src={image.mortarboard} className="mr-2" />
															</strong>{" "}
															{badges?.courseName}
														</p>
														<p className="mt-1">
															<strong><img src={image.modules_icon} className="mr-2" /></strong>{badges?.activityName}
														</p>
														<p>
															<strong className="d-block">About Badge:</strong>

															{badges?.bragAboutIt}
														</p>
														<p>
															<strong className="d-block">Description:</strong>
															{badges?.description}
														</p>
													</div>
												</div>
											</div>
										</td>
									</tr>
								</table>
							)}
						</div>
					))}
				</div>
			) : (
				<div className="Pick_yourAvtar">
					<div className="default_badges w-50 m-auto p-5 text-center">
						<img src={image.default_badges} />
						<h3 className="mt-3">No Badges Yet</h3>
						<p className="mt-3 ">
							You haven’t started a plan or earned any badges yet.<NavLink onClick={() => gotoCourse()}>Choose a Plan</NavLink>to begin your journey and start unlocking achievements today!
						</p>
					</div>
				</div>
			)}
		</>
	);
};
export default MyBadges;
