import React, { useState } from "react";
import * as image from "../../resources/images";
import RoundProgress from "../controls/RoundProgress";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { useDispatch } from "react-redux";
import { showPanel } from "../../redux/actions";
import { ShimmerSimpleGallery } from "react-shimmer-effects";

const StudentGrowthSurvey = ({ intelligenceSurveyData, courseObj, reportType, showDimProgress }) => {

	const dispatch = useDispatch();
	const [openAccordionIndex, setOpenAccordionIndex] = useState(0);

	const toggleAccordion = (index) => {
		setOpenAccordionIndex(openAccordionIndex === index ? null : index);
	};

	const showReviewPreviewData = (course, isPost, isState, isView) => {
		let dataObj = {
			type: "showGrowthSurveyPanel",
			isPost: isPost,
			isView: isView,
			isState: isState,
			skillName: course?.skillName,
		}
		if (!isPost) {
			if (isState == "upState") {
				dataObj.previewData = course?.preGrowthSurveyBehavior;
				dataObj.reviewData = course?.preBehaviourCalc;
			} else {
				dataObj.previewData = [...course?.preGrowthSurveyScenario, ...course?.preGrowthSurveyKnowledge];
				dataObj.reviewData = course?.preKnowledgeAndScenarioCalc;
			}
		} else {
			if (isState == "upState") {
				dataObj.previewData = course?.postGrowthSurveyBehavior;
				dataObj.reviewData = course?.postBehaviourCalc;
			} else {
				dataObj.previewData = [...course?.postGrowthSurveyScenario, ...course?.postGrowthSurveyKnowledge];
				dataObj.reviewData = course?.postKnowledgeAndScenarioCalc;
			}
		}
		console.log("showReviewPreviewData : ", dataObj)
		dispatch(showPanel(dataObj))
	}
	return (
		<>
			{intelligenceSurveyData ? (
				<div className="StudGrowthservey p-3  TeacherDashboard">
					<h4>
						<img src={image.growthSurveyIcon} alt="" className="mr-2" />
						Growth Survey
					</h4>
					{intelligenceSurveyData?.records?.map((growth, index) =>
						growth?.courses
							?.sort((a, b) => a.coursesequence - b.coursesequence)
							?.map((course, key) => (
								(course?.courseId === courseObj?.id || showDimProgress || ["GrowthSurvey"]?.includes(reportType)) && (
									<div class="AccordianTabl  mt-3" key={Math.random()}>
										{showDimProgress && (
											<h5
											// className="GrowthSurvyCollapse"
											// data-toggle="collapse"
											// href={"#GrowthSurvytbl0"+key}
											// aria-expanded={key > 0 ? "false" : "true"}
											>
												<div class="PlanTblCourse_Name">
													<span>C{course?.coursesequence}</span>
													{course?.courseName}
												</div>
												{/* <span
                                   className="GrowthSurvyCollapse flexone pointer"
                                   data-toggle="collapse"
                                   href={"#GrowthSurvytbl0" + key}
                                   aria-expanded={key > 0 ? "false" : "true"}
                                 > */}
												<span
													className="GrowthSurvyCollapse flexone pointer"
													onClick={() => toggleAccordion(key)}
													href={"#GrowthSurvytbl0" + key}
													aria-expanded={openAccordionIndex === key}
												>
													<i class="fa-light fa-chevron-down"></i>
												</span>
											</h5>
										)}

										{/* <div className={`flexone justify-content-between  StugrowthSrvy_bg GrowthSurvytbl panel-collapse  collapse ${key > 0 ? "" : "show"} `}
														id={"GrowthSurvytbl0" + key}
													> */}
										<div
											className={`flexone justify-content-between  StugrowthSrvy_bg GrowthSurvytbl panel-collapse  collapse ${openAccordionIndex === key || (!showDimProgress && ["OverallProgress", "connectedSkillReport"]?.includes(reportType)) ? "show" : ""
												} `}
											id={"GrowthSurvytbl0" + key}
										>
											<div className="pre_growth_Servy">
												<h5>Pre Growth Survey</h5>
												<div
													className={`disabledDiv ${course?.preBehaviourCalc === 0 ? "disabledDiv" : ""
														}`}
												>
													<div
														class={
															course?.preBehaviourCalc === 0
																? "disabledDivChild anchor-disabled"
																: ""
														}
													></div>
													<div className="Studimensional_progress">
														<div className="round_progres_ mt-3">
															<div className="position-relative Coursecompprcent">
																<p className="">
																	<RoundProgress
																		data={course?.preBehaviourCalc}
																		strokeWidth={20}
																		className="m-1"
																		type={true}
																	/>
																</p>
															</div>
														</div>
														<hr />
														<div class="text-center flextwo align-items-center">
															<a
																href="javascript:void(0)"
																onClick={() =>
																	showReviewPreviewData(course, false, "upState", "Preview")
																}
															>
																Preview
															</a>
															<div class="verticalLine"></div>
															<a
																href="javascript:void(0)"
																onClick={() =>
																	showReviewPreviewData(course, false, "upState", "Review")
																}
															>
																Review
															</a>
														</div>
													</div>

													{/* <div class="indivisectionprog flex pb-1">
                                                      <div class="graphbatteryprogress">
                                                          <span class="secProgTxt">40<span class="secProgTxtSmallText">/80</span>
                                                          </span><span class="batterycells" style={{ height: "50%" }}></span>
                                                      </div>
                                                  </div> */}
													<div className="SemicircleProgress mt-5  text-center">
														<SemiCircleProgressBar
															percentage={course?.preKnowledgeAndScenarioCalc}
															strokeWidth={25}
															showPercentValue
														/>
													</div>
													<hr class="secondHrLine" />
													<div class="text-center d-flex align-items-center justify-content-center">
														<a
															href="javascript:void(0)"
															onClick={() =>
																showReviewPreviewData(course, false, "downState", "Preview")
															}
														>
															Preview
														</a>
														<div class="verticalLine"></div>
														<a
															href="javascript:void(0)"
															onClick={() =>
																showReviewPreviewData(course, false, "downState", "Review")
															}
														>
															Review
														</a>
													</div>
												</div>
											</div>
											<div class="d-flex flex-column middleContentMainDiv ">
												<div class="text-center moveTopContent1">
													<h3 class="pb-1">How I Changed</h3>
													<h5 class="pb-3">Results from reflection questions.</h5>
													<h4 class="pt-3">After taking the course.</h4>
													<span>
														<img src={image.growthSurveyArrowIcon} />
													</span>
												</div>
												<div class="text-center moveTopContent2">
													<h3 class="pb-1">What I Learned</h3>
													<h5 class="pb-3">Results from scenario questions.</h5>
													<h4 class="pt-3">After taking the course.</h4>
													<span>
														<img src={image.growthSurveyArrowIcon} />
													</span>
												</div>
											</div>

											<div className="pre_growth_Servy">
												<h5>Post Growth Survey</h5>
												<div
													class={`disabledDiv ${course?.postBehaviourCalc === 0 ? "disabledDiv" : ""
														}`}
												>
													<div
														class={
															course?.postBehaviourCalc === 0
																? "disabledDivChild anchor-disabled"
																: ""
														}
													></div>
													<div className="Studimensional_progress">
														<div className="round_progres_ mt-3">
															<div className="position-relative Coursecompprcent">
																<p className="">
																	<RoundProgress
																		data={course?.postBehaviourCalc}
																		strokeWidth={20}
																		className="m-1"
																		type={true}
																	/>
																</p>
															</div>
														</div>
														<hr />
														<div class="text-center flextwo align-items-center">
															<a
																href="javascript:void(0)"
																onClick={() =>
																	showReviewPreviewData(course, true, "upState", "Preview")
																}
															>
																Preview
															</a>
															<div class="verticalLine"></div>
															<a
																href="javascript:void(0)"
																onClick={() =>
																	showReviewPreviewData(course, true, "upState", "Review")
																}
															>
																Review
															</a>
														</div>
													</div>

													{/* <div class="indivisectionprog flex pb-1">
                                                      <div class="graphbatteryprogress">
                                                          <span class="secProgTxt">40<span class="secProgTxtSmallText">/80</span>
                                                          </span><span class="batterycells" style={{ height: "50%" }}></span>
                                                      </div>
                                                  </div> */}
													<div className="SemicircleProgress mt-5  text-center SemicircleProgressServey">
														<SemiCircleProgressBar
															percentage={course?.postKnowledgeAndScenarioCalc}
															strokeWidth={25}
															showPercentValue
														/>
													</div>
													<hr class="secondHrLine" />
													<div class="text-center d-flex align-items-center justify-content-center">
														<a
															href="javascript:void(0)"
															onClick={() =>
																showReviewPreviewData(course, true, "downState", "Preview")
															}
														>
															Preview
														</a>
														<div class="verticalLine"></div>
														<a
															href="javascript:void(0)"
															onClick={() =>
																showReviewPreviewData(course, true, "downState", "Review")
															}
														>
															Review
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								)

							))
					)}
				</div>
			) : (
				<ShimmerSimpleGallery imageType="circular" imageHeight={200} caption />
			)}

		</>
	);
};

export default StudentGrowthSurvey;