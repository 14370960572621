import * as image from "../../resources/images";
import { ShimmerCategoryList, useForm } from "../../utils/Packages";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getHcfChallenges, postCoursesHcfPlan, updateLearnerPlan } from "../../redux/actions/APIs";
import ChallengeModule from "./ChallengeModule";
import DeletePlanActivities from "../learnerplan/DeletePlanActivities";
import ChallengePlanModule from "./ChallengePlanModule";
import { getCalculatePlanDate, getDateByAddDays, getSequnceSort, handleSeatingsDate } from "../../utils/helper";
import ChallengesPopup from "../learnerplan/ChallengesPopup";
import { addPlanCourseData } from "../../redux/actions";
import ChallengeCreatePlanModule from "./ChallengeCreatePlanModule";
import ChallengeOnePlanModule from "./ChallengeOnePlanModule";
const CourseScreen = ({ selecteOption, handleOPtion, isMyChoice,
    setIsMyChoice, setPopupData, viewReward,
    viewCertificate, handlePopUpOpen,
    selecteChalnge, setSelecteChalnge
}) => {
    const dispatch = useDispatch();
    const { loggedInUser,
        defaultChildData,
        courseshcfData,
        getPlanCoursesRes,
    } = useSelector((state) => state.collections);

    const [getAllHcfCourses, setAllHcfCourses] = useState([]);
    const [hcfPlanData, setHcfPlanData] = useState();
    const [providerData, setProviderData] = useState([]);
    const [getHcfSkills, setHcfSkills] = useState([]);
    const [showChallenge, setShowChallenge] = useState();
    const [getUsTimeZone, setUsTimeZone] = useState("US/Eastern");

    useEffect(() => {
        if (loggedInUser?.timeZone) {
            setUsTimeZone(loggedInUser?.timeZone)
        }
    }, [loggedInUser])

    const handleClickOnMyChoice = (choiceName) => {
        if (isMyChoice?.length >= 4 && !isMyChoice?.includes(choiceName)) {
            setPopupData({
                title: "Max Challenges Limit",
                message: `You have already selected the maximum (4) number of challenges. You can delete a challenge to add new challenge.`,
            });
            return;
        }
        let myChoiceArr = [...isMyChoice];
        const key = myChoiceArr?.findIndex((chName) => chName === choiceName);
        if (key != -1) {
            myChoiceArr?.splice(key, 1)
        } else {
            myChoiceArr?.push(choiceName);
        }

        setIsMyChoice(myChoiceArr);
    }

    useEffect(() => {
        if (["help_me", "life_one_plan"]?.includes(selecteOption)) {
            clearState();
        }
        if (selecteOption === "help_me") {
            setSelecteChalnge();
        }
        if (selecteOption === "life_one_plan") {

            setIsMyChoice([]);
        }
        if (selecteOption === "my_courses") {
            setSelecteChalnge();
            setIsMyChoice([]);
        }
    }, [selecteOption])

    const clearState = () => {
        dispatch(postCoursesHcfPlan());
        dispatch(addPlanCourseData())
        setAllHcfCourses([]);
        setProviderData([]);
        setHcfSkills([]);
        //  setIsMyChoice([]);
        //  setSelecteChalnge();
    }


    // console.log("courseshcfData @@@ : ",courseshcfData, selecteOption, getAllHcfCourses, getPlanCoursesRes);
    useEffect(() => {
        if (courseshcfData?.records?.length > 0 && selecteOption === "Challenges_Plan") {
            let providerArr = [];
            setHcfPlanData(courseshcfData?.records[0]);
            let courses = [];
            let skillArr = [];
            // getMyPlanData?.records[0]?.dimensions?.map((dim, index) => {
            getSequnceSort(courseshcfData?.records[0]?.dimensions)?.map((dim, index) => {
                getSequnceSort(dim?.skills)?.map((skill, key) => {
                    skillArr?.push(skill);
                    getSequnceSort(skill?.courses)?.map((course, cKey) => {
                        course.skill = { ...skill, courses: [] };
                        course.dimension = { key: dim?.id, value: dim?.name }
                        courses.push(course);
                        const prvKey = providerArr?.findIndex((item) => item?.id === course?.provider?.id)
                        if (prvKey === -1) {
                            course.provider.courseName = [course?.name];
                            providerArr?.push({ ...course?.provider });
                        } else {
                            providerArr[prvKey]?.courseName?.push(course?.name);
                        }
                    })
                })
            })
            setAllHcfCourses(courses);
            setProviderData(providerArr);
            setHcfSkills(skillArr);
        }
    }, [courseshcfData])


    useEffect(() => {
        if (getAllHcfCourses?.length > 0 && selecteOption === "Challenges_Plan") {

            handleCalculateStartDateEndDate(
                getAllHcfCourses,
                2,
                30,
                new Date(),
                [],
                []

            );
        }
    }, [getAllHcfCourses, selecteOption]);

    const handleCalculateStartDateEndDate = (data, selectedSitting, selectedMinutes, userDate, selctedDays, getFlexibLeDays) => {
        // let getAllCourses = getCalculatePlanDate(data, duration, sitting, date, weekArr);
        let getScheduleCourse = [];
        let index = 0;
        data?.map((courses, i) => {
            if (true) {
                let courseArr = {
                    id: courses?.id,
                    duration: selectedMinutes,
                    sittings: selectedSitting,
                    sequence: index + 1,
                    startDate: "",
                    endDate: "",
                    activities: [],
                };
                getSequnceSort(courses?.activities)?.map((module, key) => {
                    let getDay = handleSeatingsDate(
                        parseInt(selectedSitting),
                        parseInt(selectedMinutes),
                        index,
                        key + 1
                    );
                    let currentDate = new Date();
                    if (index === 0 && key === 0) {
                        currentDate = new Date(userDate);
                    } else if (index !== 0 && key === 0) {
                        currentDate = getDateByAddDays(
                            getScheduleCourse[index - 1]?.activities[3]?.endDate,
                            1
                        );
                    } else {
                        currentDate = getDateByAddDays(
                            courses?.activities[key - 1]?.endDate,
                            1
                        );
                    }
                    module.startDate = new Date(currentDate).toUTCString().toLocaleString("en-us", {
                        month: "short",
                        year: "numeric",
                        day: "numeric",
                    });
                    module.endDate = new Date(
                        getDateByAddDays(module.startDate, getDay - 1)
                    ).toUTCString().toLocaleString("en-us", {
                        month: "short",
                        year: "numeric",
                        day: "numeric",
                    });
                    let obj = {
                        id: module?.id,
                        startDate: module?.startDate,
                        endDate: module?.endDate,
                        duration: selectedMinutes,
                        sittings: selectedSitting,
                    };
                    courseArr.activities.push(obj);
                });
                courseArr.startDate = courseArr.activities[0]?.startDate;
                courseArr.endDate = courseArr.activities[3]?.endDate;
                getScheduleCourse.push(courseArr);
                index++;
                courses.startDate = courseArr?.activities[0]?.startDate;
                courses.endDate = courseArr?.activities[3]?.endDate;
                courses.duration = selectedMinutes;
                courses.sittings = selectedSitting;
            }
        })
        dispatch(addPlanCourseData({
            hcfPlanData: hcfPlanData,
            getAllCourses: getAllHcfCourses,
            getScheduleCourse: getScheduleCourse,
            duration: selectedMinutes,
            sitting: selectedSitting,
            getUsTimeZone: getUsTimeZone,
            selctedDays: selctedDays,
            getFlexibLeDays: getFlexibLeDays,
        }))

    };

    const createRecomndedChallengePlan = () => {
        if (getPlanCoursesRes?.length > 0) {
            // let signInData = signinresponse?.records[0];
            let array = {
                name: "",
                sittings: 2,
                duration: 30,
                planCount: 1,
                timeZone: getUsTimeZone,
                planType: getAllHcfCourses[0]?.planType,
                planId: getAllHcfCourses[0]?.planId,
                daysOfWeek: getAllHcfCourses[0]?.weekArr,
                courses: getPlanCoursesRes
            };
            dispatch(updateLearnerPlan(defaultChildData?.id, loggedInUser?.id, array))
        }
    }

    return (
        <>
            <div>
                <div className="Course_height">
                    <div class="gridSection">
                        {["help_me", "my_courses", "life_one_plan"]?.includes(selecteOption) && (
                            <div className="setup_two ljpopups optional_course_catlog mb-3">
                                <div class="ScenecerelateddQuiz  flex position-relative flex-wrap px-3 pt-3 pb-0">
                                    <div class="signupType m-0 course_catalog_list w-100">
                                        {/* <div class="learnerPlan_modelQue">
                                            <div>
                                                <label class="Selcheckbox m-0 ActiveQQst learnerPlan_sellebel">
                                                    <div class="QQtitle">
                                                        <p class="palnquestiontitle m-0">
                                                            I Want to Select the Right Course for My
                                                            Child
                                                        </p>
                                                    </div>
                                                    <input
                                                        type="radio"
                                                        name="skill0"
                                                        value="recommended"
                                                        checked={selecteOption === "my_courses"}
                                                        onClick={() => handleOPtion("my_courses")}
                                                    />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div> */}
                                        <div class="learnerPlan_modelQue">
                                            <div>
                                                <label class="Selcheckbox m-0 ActiveQQst learnerPlan_sellebel">
                                                    <div class="QQtitle">
                                                        <p class="palnquestiontitle m-0">
                                                            I Want to Select the Right Course for My
                                                            Child
                                                        </p>
                                                    </div>
                                                    <input
                                                        type="radio"
                                                        name="skill0"
                                                        value="recommended"
                                                        checked={selecteOption === "my_courses"}
                                                        onClick={() => handleOPtion("my_courses")}
                                                    />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="learnerPlan_modelQue">
                                            <div>
                                                <label class="Selcheckbox m-0 ActiveQQst learnerPlan_sellebel">
                                                    <div class="QQtitle">
                                                        <p class="palnquestiontitle m-0">
                                                            I Want a Plan to Help My Child Through a Life Event
                                                        </p>
                                                    </div>
                                                    <input
                                                        type="radio"
                                                        name="skill0"
                                                        value="helpmefind"
                                                        checked={selecteOption === "life_one_plan"}
                                                        onClick={() => handleOPtion("life_one_plan")}
                                                    />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="learnerPlan_modelQue">
                                            <div>
                                                <label class="Selcheckbox m-0 ActiveQQst learnerPlan_sellebel">
                                                    <div class="QQtitle">
                                                        <p class="palnquestiontitle m-0">
                                                            Help Me Find the Right Growth Plan for My
                                                            Child
                                                        </p>
                                                    </div>
                                                    <input
                                                        type="radio"
                                                        name="skill0"
                                                        value="helpmefind"
                                                        checked={selecteOption === "help_me"}
                                                        onClick={() => handleOPtion("help_me")}
                                                    />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {selecteOption === "help_me" && (
                            <ChallengeModule
                                handleClickOnMyChoice={handleClickOnMyChoice}
                                isMyChoice={isMyChoice}
                                handlePopUpOpen={handlePopUpOpen}
                            />
                        )}
                        {selecteOption === "life_one_plan" && (
                            <ChallengeOnePlanModule
                                selecteChalnge={selecteChalnge}
                                setSelecteChalnge={setSelecteChalnge}
                                handlePopUpOpen={handlePopUpOpen}
                            />
                        )}
                        {selecteOption === "Challenges_Plan" && (
                            <ChallengePlanModule hcfPlanData={hcfPlanData}
                                setShowChallenge={setShowChallenge}
                                getAllHcfCourses={getAllHcfCourses}
                                providerData={providerData}
                                getUsTimeZone={getUsTimeZone}
                                viewReward={viewReward}
                                viewCertificate={viewCertificate}
                                isMyChoice={isMyChoice}
                            />
                        )}
                        {selecteOption === "Create_Plan" && (
                            <ChallengeCreatePlanModule
                                hcfPlanData={hcfPlanData}
                                setPopupData={setPopupData}
                                handleCalculateStartDateEndDate={handleCalculateStartDateEndDate}
                                getAllHcfCourses={getAllHcfCourses}
                            />
                        )}
                    </div>
                </div>
            </div>
            {showChallenge && (
                <div className="ratepopupp position-relative">
                    <ChallengesPopup showChallenge={showChallenge}
                        setShowChallenge={setShowChallenge}
                    />
                </div>

            )}
        </>

    );
}
export default CourseScreen;