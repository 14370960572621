/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as image from "../../../../resources/images";
import {
	billingPlan,
	dimStore,
	parentLoginAction,
	resetResponse,
	resetStripePlans,
	selectTab,
	setProviderModal,
	showModal,
	subscriptionPageNode,
} from "../../../../redux/actions";
import {
	getCurriculam,
	getDimensionData,
	getMetaData,
	learnerEnrolled,
	turnNotification,
	deleteDimension,
	logoutAction,
	courseJourney,
	getRewardData,
	getLesson,
	getJourny,
	subMitUserGrade,
	getSettingData,
} from "../../../../redux/actions/APIs";
import Home from "../../../Home";
import { AddVdoActivity } from "../../";
import CourseCard from "../intellectual/CourseCard";
import {
	ShimmerCategoryList,
	useHistory,
	useParams,
} from "../../../../utils/Packages";
import { getSequnceSort, redirectLeftOfScreen } from "../../../../utils/helper";
import ActivityView from "../../activity/ActivityView";
import CurriculumDetails from "../../../dimensions/activity/CurriculumDetails";
import CourseConfirmedPage from "../../courseConfirmedPage";
import { DimTab, ChooseSubject } from "../common";
import ExploreView from "../../activity/ExploreView";
import Subscription from "../../../subscription/Subscription";
import { PATHS } from "../../../../utils";
import { getHelpModal } from "../../../../redux/actions";
import Certificate from "../../../base/Certificate";
import RewardPopUp from "../../../base/RewardPopUp";
import IndexRightPanel from "../../IndexRightPanel";
import SkillGrowth from "../../../home/SkillGrowth";
import DimInfoPopup from "../../DimInfoPopup";
import GradePopup from "./GradePopup";

const Intellectual = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams();
	const [chooseSubjectObj, setChooseSubject] = useState([]);
	const [noti, setNoti] = useState(true);
	const [selectedTab, setSelectedTab] = useState();
	const [teacherData, showTeacherData] = useState(undefined);
	const [showCourseDetail, setShowCourseDetail] = useState("");
	const [resourceInnerData, setResourceInnerData] = useState({});
	const [courseDetailId, setCourseDetailId] = useState("");
	const [viewActivity, setViewActivity] = useState({
		view: false,
		data: "",
		activityObj: "",
	});
	let [showCurriculum, setShowCurriculum] = useState({
		flag: false,
		data: "",
		type: "",
		courses: "",
		courseSubject: "",
		name: "",
		skills: "",
	});
	const [showChooseSubject, setShowChooseSubject] = useState(false);
	const [showActivityForm, setActivityForm] = useState({});
	const [showmodal, setShowModal] = useState(false);
	const [courseIds, setCourseIds] = useState([]);
	const [curriculamData, setCurriculamData] = useState({
		type: false,
		name: "",
	});

	const [showSubs, setShowSubs] = useState(false);
	const [subscribeData, setSubscribeData] = useState();
	const [isChooseSubject, setIsChooseSubject] = useState(false);
	const [activeTab, setActiveTab] = useState(1);
	const [showGradePopup, setShowGradePopup] = useState(false);
	const [curricullumSkill, setCurricullumSkill] = useState(false);

	const [allCourse, setAllCourse] = useState([]);
	const [brochure, setBrochure] = useState(true);
	const [isAllHide, setIsAllHide] = useState(false);
	const [disableSkillSelection, setDisableSkillSelection] = useState(true);
	const [isAllCourseShow, setIsAllCourseShow] = useState(true);

	const {
		dimension,
		getSelectedUser,
		loggedInUser,
		courseList,
		response,
		activyDetails,
		notification,
		getdimension,
		getLessonResources,
		selectedDim,
		defaultChildData,
		signinresponse,
		signupresponse,
		nfRedirect,
		journeyData,
		addVideoResponse,
		getCurriculamData,
	} = useSelector((state) => state.collections);
	const { skillProg, dashboardData } = useSelector((state) => state.home);

	const [disable, setDisable] = useState(false);
	const [myDimData, setMyDimData] = useState();

	useEffect(() => {
		if (getdimension?.records?.length > 0) {
			setMyDimData(getdimension?.records);
			let dimObj = getdimension?.records.find(
				(data) => data?.id === params?.dimId
			);
			let dimData = {
				key: dimObj.id,
				value: dimObj.name,
			};
			dispatch(getDimensionData(defaultChildData?.id, dimData, dimension)).then(() => {
				setCourseObject(dimData);
			});
		}
	}, [loggedInUser, getdimension, dashboardData])

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (response?.success) {
			let dimData = {
				key: selectedTab?.key?.key,
				value: selectedTab?.key?.value,
				istDimensionApi: true
			};
			dispatch(getDimensionData(params?.id, dimData, dimension))
		}
		dispatch(resetResponse());

	}, [response]);

	/*
	useEffect(() => {
	  if (signinresponse?.success && getdimension && !nfRedirect) {
	  } else if (signupresponse?.success && getdimension) {
	  dispatch(
		showModal({
		type: "journeySkills",
		childId: signupresponse.records[0].childId,
		})
	  );
	  }
	}, [getdimension]);
	*/
	let dimensionId = history?.location?.state?.dimId;
	let dimensionName = history?.location?.state?.dimName;

	useEffect(() => {
		let dimensionDetail = getdimension?.records;
		let currentDimension = [];
		for (let index = 0; index < dimensionDetail?.length; index++) {
			if (dimensionDetail[index]?.id === params?.dimId) {
				currentDimension = dimensionDetail[index];
				break;
			}
		}

		if (params) {
			redirectLeftOfScreen(params?.id);
		}

		// let previousState = localStorage.getItem("previousState");

		// if (previousState == null || previousState == "null") {
		// 	if (loggedInUser) {
		// 		let dataObj = {
		// 			parentid: loggedInUser?.id
		// 				? loggedInUser?.id
		// 				: signupresponse?.records[0]?.parentId,
		// 			uistate: [
		// 				{
		// 					userid:
		// 						defaultChildData && defaultChildData?.id
		// 							? defaultChildData?.id
		// 							: signupresponse && signupresponse?.records[0]?.childId,
		// 					detail: {
		// 						dimension: currentDimension?.name,
		// 						skill: "",
		// 						course: "",
		// 						scene: "",
		// 						kc: "",
		// 						dimId: params?.dimId,
		// 					},
		// 					page: "",
		// 				},
		// 			],
		// 		};
		// 		localStorage.setItem("previousState", JSON.stringify(dataObj));
		// 	} else {
		// 		if (!localStorage.getItem("access_token")) {
		// 			dispatch(logoutAction());
		// 		}
		// 	}
		// } else {
		// 	previousState = JSON.parse(previousState);
		// 	let uistate = previousState?.uistate;
		// 	let isLearnerExists = false;
		// 	for (let index = 0; index < uistate?.length; index++) {
		// 		if (uistate[index]?.userid === params?.id) {
		// 			isLearnerExists = true;
		// 			uistate[index].detail = {
		// 				dimension: currentDimension?.name,
		// 				skill: "",
		// 				course: "",
		// 				scene: "",
		// 				kc: "",
		// 				dimId: params?.dimId,
		// 			};
		// 			uistate[index].page = "";
		// 			localStorage.setItem("previousState", JSON.stringify(previousState));
		// 			break;
		// 		}
		// 	}
		// 	if (!isLearnerExists) {
		// 		let obj = {
		// 			userid: params?.id,
		// 			detail: {
		// 				dimension: currentDimension?.name,
		// 				skill: "",
		// 				course: "",
		// 				scene: "",
		// 				kc: "",
		// 				dimId: params?.dimId,
		// 			},
		// 			page: "",
		// 		};
		// 		uistate.push(obj);
		// 		localStorage.setItem("previousState", JSON.stringify(previousState));
		// 	}
		// }
	}, [params]);

	useEffect(() => {
		if (dimensionId) {
			dispatch(
				selectTab({
					key: dimensionId,
					value: dimensionName,
				})
			);
		}
	}, [dimensionId]);

	/*
	useEffect(() => {
		dispatch(resetResponse());
	}, [response]);
	*/

	let redirectState = history?.location?.state?.redirect;

	useEffect(() => {
		if (getdimension && !selectedDim?.key && !redirectState) {
			if (getdimension?.records[3]?.id == params?.dimId) {
				dispatch(
					selectTab({
						key: getdimension?.records[3]?.id,
						value: getdimension?.records[3]?.name,
					})
				);
			}
		} else if (redirectState) {
			dispatch(
				selectTab({
					key: redirectState?.id,
					value: redirectState?.name,
				})
			);
		} else if (selectedDim?.key) {
			let selectedDimTab = getdimension?.records.filter(
				(data) => data.id === params.dimId
			);
			if (selectedDimTab) {
				dispatch(
					selectTab({
						key: selectedDimTab[0]?.id,
						value: selectedDimTab[0]?.name,
					})
				);
			}
		}
	}, [getdimension]);

	const setCourseObject = (tab) => {
		let tag = [];
		if (tab) {
			let data = dimension[tab?.value];
			setSelectedTab({
				key: selectedDim,
				data: data && data,
				tag: dimension[tab?.value]?.skills,
			});
		}
	};

	useEffect(() => {
		if (dimension[selectedDim?.value]) {
			handleDimensionApi();
		}
	}, [dimension, selectedDim])

	const handleDimensionApi = () => {
		let dimData = {
			key: selectedDim?.key,
			value: selectedDim?.value,
			istDimensionApi: true
		};
		dispatch(getDimensionData(defaultChildData?.id, dimData, dimension));
	}

	const toggleTab = (index, data) => {
		history.push({
			pathname: PATHS.DIMENSION_STR + data?.id + "/" + getSelectedUser?.id,
		});
		dispatch(
			selectTab({
				key: data.id,
				value: data?.name,
			})
		);
		if (getSelectedUser && ["LEARNER", "STUDENT"]?.includes(getSelectedUser?.role?.name)) {
			let selectedTab = {
				key: data.id,
				value: data.name,
			};

			if (!dimension[selectedTab.value]) {
				dispatch(getDimensionData(params?.id, selectedTab, dimension)).then(
					() => {
						setCourseObject(data?.name);
					}
				);
			}
		}
	};

	const [target, setTarget] = useState("");
	const [cortarget, setcorTarget] = useState("");
	const [abandonCourses, setAbandomCourse] = useState();
	useEffect(() => {
		if (history?.location?.state?.courseId && allCourse.length > 0) {
			const scrollToTarget = document.getElementById(
				"myCourse" + history?.location?.state?.courseId
			);

			setcorTarget(scrollToTarget);
			if (scrollToTarget) {
				scrollToTarget.scrollIntoView({ behavior: "smooth", block: "center" });
			}
		}
	}, [
		params?.id,
		selectedTab?.tag?.value,
		selectedTab?.data,
		cortarget,
		allCourse,
	]);

	useEffect(() => {
		if (history?.location?.state?.skillId) {
			const scrollToTarget = document.getElementById(
				"mytab" + history?.location?.state?.skillId
			);

			setTarget(scrollToTarget);

			if (scrollToTarget) {
				scrollToTarget.scrollIntoView({
					behavior: "smooth",
					block: "center",
				});
			}
		}
	}, [history?.location?.state?.skillId]);
	useEffect(() => {
		if (history?.location?.state?.skillId) {
			const scrollToTarget = document.getElementById(
				"mytab" + history?.location?.state?.skillId
			);

			setTarget(scrollToTarget);

			if (scrollToTarget) {
				scrollToTarget.scrollIntoView({ behavior: "smooth", block: "center" });
			}
		}
	}, [params?.id, selectedTab?.tag?.value, selectedTab?.data, target]);

	useEffect(() => {
		let dimObj = getdimension?.records.find(
			(data) => data?.id === params?.dimId
		);

		if (dimObj) {
			let ob = {
				key: dimObj.id,
				value: dimObj.name,
			};

			dispatch(selectTab(ob));
			if (getSelectedUser?.id) {
				dispatch(deleteDimension(params?.id, ob, dimension, "all"));
			}
		}
	}, [getSelectedUser?.id, dimension]);


	useEffect(() => {
		setCourseObject(selectedDim);
	}, [courseList, selectedDim]);

	useEffect(() => {
		if (!selectedDim) {
			let dimObj = getdimension?.records.find(
				(data) => data?.id === params?.dimId
			);

			if (dimObj) {
				let ob = {
					key: dimObj.id,
					value: dimObj.name,
				};

				dispatch(selectTab(ob));
			}
		}
	}, [selectedDim]);

	const showAddActivity = (type, data, obj) => {
		setActivityForm({ type, data, obj });
	};

	const closeModal = () => {
		setActivityForm(undefined);
		showTeacherData(undefined);
		setShowCurriculum(undefined);
		setShowCourseDetail(undefined);
		setShowModal(false);
		dispatch(resetResponse());
	};

	const closeModalSub = () => {
		setActivityForm(undefined);
		dispatch(resetResponse());
		setViewActivity({
			view: false,
			data: undefined,
			activityObj: undefined,
			selectedCourse: undefined,
		});
	};
	const closeModalEdit = () => {
		setViewActivity({
			view: false,
			data: undefined,
			activityObj: undefined,
			selectedCourse: undefined,
		});
	};
	useEffect(() => {
		if (addVideoResponse) {
			setActivityForm({});
			dispatch(resetResponse());
			dispatch(selectTab(selectedDim));
		}
	}, [addVideoResponse, selectedTab]);

	const handleCloseGradePopup = (result, data, skillData) => {
		if (["Social Studies", "Science"]?.includes(data?.name)) {
			setSubject(data);
		} else {
			setShowGradePopup(result);
			setCurricullumSkill(data);
		}
	};

	const [showCurriResourcePopup, setShowCurriResource] = useState(false);
	const [isResourceClick, setIsResourceClick] = useState(false);
	const handleCurriResourcePopup = (result, isResource) => {
		setShowCurriResource(result);
		setIsResourceClick(isResource);
	};

	const showActivity = (
		data,
		type,
		courseName,
		courseSubject,
		skills,
		isResource
	) => {
		skills.isResource = isResource;
		setCurricullumSkill(skills);
		if (getSelectedUser?.grade === null) {
			setShowGradePopup(true);
			return true;
		}
		dispatch(getCurriculam());
		dispatch(getCurriculam(params?.id, courseSubject?.id));
		setShowCurriculum({
			flag: true,
			data: data,
			type: type,
			courses: "courses[0]",
			name: courseName,
			userId: getSelectedUser?.id,
			courseId: courseSubject?.id,
			courseSubject: courseSubject,
			skills: skills,
		});

		if (isResource) {
			setActiveTab(2);
		} else {
			setActiveTab(1);
		}
	};

	let selectLessonName = [];
	let complextObj = [];
	selectLessonName = getCurriculamData?.records?.map((item, key) => {
		if (item?.isComplex) {
			selectLessonName = getSequnceSort(item?.lesssons)?.map(
				(vl, ky) =>
					vl?.isComplex &&
					vl?.countOfResources &&
					complextObj.push({
						modLessId: vl.moduleLessonId,
						lesson: vl.name,
						module: item.name,
						sequence: item.sequence,
					})
			);
			return complextObj;
		}
	});

	let selectLesson = [];
	selectLessonName &&
		getSequnceSort(complextObj)?.map(
			(vl, ky) => (
				selectLesson.push(vl), (selectLesson = getSequnceSort(selectLesson))
			)
		);

	selectLesson = getSequnceSort(selectLesson);

	useEffect(() => {
		if (getCurriculamData && selectLesson) {
			dispatch(getLesson(getSelectedUser?.id, selectLesson[0]?.modLessId)).then(
				() => { }
			);
		}
		dispatch(subMitUserGrade());
	}, [getCurriculamData]);

	useEffect(() => {
		if (getLessonResources) {
			dispatch(subMitUserGrade());
		}
	}, [getLessonResources]);

	const _viewCourseDetail = (data, id) => {
		setShowCourseDetail(data);
		setCourseDetailId(data.id);
	};

	const openCurriculam = (data) => {
		setCurriculamData({
			type: !curriculamData?.type,
			name: data?.name,
			modules: data?.courseModules,
			enroll: 0,
		});
	};

	const providerPopup = (data, courseData) => {
		if (courseData?.id !== journeyData?.recordId) {
			dispatch(showModal({ type: "JourneyShimmer" }));
			dispatch(courseJourney(courseData?.id));
		}

		setShowCourseDetail(courseData);
		setCourseDetailId(courseData.id);
		if (showCurriculum) {
			data.resourceType = "resourceProvider";
			data.courseData = courseData;
		}

		dispatch(setProviderModal(data));
		showTeacherData(data);
		setIsChooseSubject(true);
	};

	const close = () => {
		showTeacherData(undefined);
		setShowChooseSubject(false);
		setChooseSubject([]);
		setIsChooseSubject(false);
	};

	const onSubmit = () => {
		if (noti === false) {
			dispatch(turnNotification(false));
		}
		if (courseIds.length > 0) {
			let formData = {
				courseIds: courseIds.join(","),
			};

			if (formData.courseIds === "") {
				closeModal();
			} else {
				dispatch(learnerEnrolled(getSelectedUser.id, formData));
				closeModal();
			}
		}
	};

	const setSubject = (data, type) => {
		let obj = data?.courses;
		obj.area = { id: data?.id, name: data?.name };
		setChooseSubject(obj);
		setShowChooseSubject(true);
	};

	const showViewActivity = (data, courseData, dimName, type) => {
		dispatch(getMetaData(data?.id));
		setViewActivity({
			view: true,
			data: data,
			activityObj: data?.activityDetails,
			selectedCourse: courseData,
			dimName: selectedDim?.value,
			type: type,
		});
	};

	const handlePopUp = (data, skillId, dimName, location) => {
		let obj = {
			courseObj: data,
			skillId: skillId,
		};

		setSubscribeData(obj);

		setShowSubs(false);
		if (location) {
			setIsChooseSubject(true);
		}
	};

	const closeSub = () => {
		dispatch(deleteDimension(getSelectedUser?.id, selectedTab?.key, dimension));
		dispatch(billingPlan());
		dispatch(resetStripePlans());
		setShowSubs(false);
		dispatch(subscriptionPageNode());
	};

	const showResourceInnerDetail = (data) => {
		setResourceInnerData(data);
	};

	useEffect(() => {
		window.addEventListener("scroll", isSticky);
		return () => {
			window.removeEventListener("scroll", isSticky);
		};
	});

	const isSticky = (e) => {
		const header = document.querySelector(".SpecialLeftpanel");
		const scrollTop = window.scrollY;
		scrollTop >= 1
			? header.classList.add("LPwidth")
			: header.classList.remove("LPwidth");
	};

	const openHelp = (data) => {
		dispatch(getHelpModal(data));
	};

	const dimPopupData = JSON.parse(localStorage.getItem("dimPopup"));

	useEffect(() => {
		if (courseList) {
			const dimPopupData = JSON.parse(localStorage.getItem("dimPopup")) || {};
			if (selectedTab?.key?.value && !dimPopupData[selectedTab.key.value]) {
				if (dimPopupData[selectedTab.key.value] === undefined) {
					dimPopupData[selectedTab.key.value] = true;
					localStorage.setItem("dimPopup", JSON.stringify(dimPopupData));
					setAbandomCourse(dimPopupData);
				}
			}

			if (chooseSubjectObj?.area?.id) {
				let selectedSub = chooseSubjectObj?.area?.id;
				let skillObj = [];
				if (courseList && courseList?.areas?.length > 0) {
					skillObj = courseList?.areas;
				} else if (courseList?.skills.length > 0) {
					skillObj = courseList?.skills;
				}
				skillObj.map((vl, ky) => {
					if (vl?.id === selectedSub) {
						let obj = vl?.courses;
						obj.area = chooseSubjectObj?.area;

						setChooseSubject(obj);
					}
				});
			}
		}
	}, [courseList]);

	useEffect(() => {
		if (courseList && courseList?.skills) {
			courseList?.skills?.map((vl, ky) => {
				vl?.courses?.map((cour) => {
					if (cour.id === courseDetailId) {
						setShowCourseDetail(cour);
						showTeacherData(cour?.provider);
					}
				});
			});
		}
		if (courseList && courseList?.areas) {
			courseList?.areas?.map((vl, ky) => {
				vl?.courses?.map((cour) => {
					if (cour.id === courseDetailId) {
						setShowCourseDetail(cour);
						showTeacherData(cour?.provider);
					}
				});
			});
		}
	}, [showCourseDetail, courseList, teacherData, courseDetailId]);

	const [certificate, setCertificate] = useState();
	const [certificateData, setCertificateData] = useState();
	const [certificateskilldata, setcertificateskilldata] = useState();
	const [openReward, setOpenReward] = useState();

	const viewCertificate = (data, courseData, skills) => {
		setCertificate(data);
		setCertificateData(courseData);
		setcertificateskilldata(skills);
	};

	const viewReward = (data, isCourse, reward) => {
		setOpenReward(data);
		dispatch(getRewardData(params?.id, reward?.id, isCourse));
	};

	const coursePageRedirect = (skillId) => {
		dispatch(getJourny(false));
		let filter = [
			{
				name: skillId?.name,
				skillIds: [],
			},
		];

		skillId?.skills
			.filter((val) => val?.courseCount !== 0)
			.forEach((vl) => {
				filter[0].skillIds.push({ id: vl.id, value: vl.name });
			});

		if (skillId) {
			history.push({
				pathname: PATHS.COURSEPAGE_STR + params?.id,
				type: "dimCatlog",
				state: {
					data: { isFilter: true, filter: filter },
				},
				filterFrom: 'dim'
			});
		}
	};

	const [allDimCourses, setAllDimCourses] = useState([]);

	useEffect(() => {
		if (selectedTab?.data) {
			let myArray = [];
			let courseArr = [];
			setBrochure(true);
			setIsAllCourseShow(true);
			setIsAllHide(false);
			let count = 0;
			let hideCourseCount = 0;
			getSequnceSort(selectedTab?.data?.skills)?.map((sk, index) => {
				sk.isCourseDisplay = false;
				sk.showPencil = false;
				sk.showIcon = false;
				sk.showInput = true;
				sk.showSkill = true;
				if (sk?.courseCount === 0) {
					hideCourseCount++;
				}
				if (sk?.courses?.length > 0) {
					if (!["Social Studies", "Science"]?.includes(sk?.name)) {
						sk.isCourseDisplay = true;
						count++;
					}
					setBrochure(false);
				}
				if (["Math", "English"]?.includes(sk?.name)) {
					if (getSelectedUser?.grade === null) {
						sk.showPencil = true;
					} else {
						sk.showIcon = true;
					}
					if (sk?.courses?.length === 1) {
						sk.showInput = false;
						sk.showSkill = false;
						count--;
						sk.isCourseDisplay = false;
					}
				}
				if (["Social Studies", "Science"]?.includes(sk?.name)) {
					sk.showInput = false;
					if (sk?.isEnrolled) {
						sk.showIcon = true;
					} else {
						sk.showPencil = true;
					}
				}
				myArray.push(sk);

				if (sk?.isCourseDisplay && !["Social Studies", "Science"]?.includes(
					sk?.name
				)) {
					getSequnceSort(sk?.courses)?.map((courses, key) => {
						courses.skill = sk;
						courseArr?.push(courses);
					})
				}


			});
			if (selectedTab?.data?.skills?.length === hideCourseCount) {
				setIsAllHide(true);
			}
			setAllDimCourses(courseArr);
			setAllCourse(myArray);
			let isBool = false;
			myArray?.map((sk, key) => {
				if (
					(sk?.courses?.length === 0 && sk?.courseCount > 0) ||
					!sk.showSkill
				) {
					isBool = true;
				}
			});
			if (!isBool) {
				setIsAllCourseShow(false);
			}
			if (count > 1) {
				setDisableSkillSelection(false);
			} else {
				setDisableSkillSelection(true);
			}
		}
	}, [selectedTab?.data, getSelectedUser?.grade]);

	const [showInfoPopup, setShowInfoPopup] = useState(false);
	const [infoPopupData, setinfoPopupData] = useState({});
	const [selectedDimension, setSelectedDimension] = useState([]);

	const handleShowInfoPopup = (result, data) => {
		setSelectedDimension(selectedTab?.key?.value);
		setShowInfoPopup(result);
		setinfoPopupData({
			name: data?.name,
			description: data?.description,
			alert: data?.alert,
			courses: data.courses,
		});
	};

	const handleSetting = () => {
		let userId = defaultChildData?.id;
		let levelId = defaultChildData?.level?.id;
		dispatch(parentLoginAction("verifyUser"));
		dispatch(getSettingData(userId, levelId));
		dispatch(dimStore({ dimName: selectedDim?.value }));
	};

	const setShowSkillPopup = (result) => {
		setShowInfoPopup(result);
	};

	const closeDipInfoPopup = (courName) => {
		const dimPopupData = JSON.parse(localStorage.getItem("dimPopup")) || {};
		if (dimPopupData[courName]) {
			dimPopupData[courName] = false;
			localStorage.setItem("dimPopup", JSON.stringify(dimPopupData));
			setAbandomCourse(dimPopupData);
		}
		abandonSelectedValue = false;
	};

	let abandonSelectedValue;
	if (selectedTab && dimPopupData && selectedTab?.key?.value in dimPopupData) {
		if (selectedTab?.key?.value in dimPopupData) {
			abandonSelectedValue = dimPopupData[selectedTab.key.value];
		}
	}

	const handleSkillCourse = (index) => {
		let array = [...allCourse];
		array[index].isCourseDisplay = !array[index].isCourseDisplay;
		let count = 0;
		array?.map((value, index) => {
			if (value?.isCourseDisplay) {
				count++;
			}
		});
		if (count > 1) {
			setDisableSkillSelection(false);
		} else {
			setDisableSkillSelection(true);
		}
		setAllCourse(array);
	};

	const handleClickHere = (skill) => {
		setSelectedDimension(selectedTab?.key?.value);
		let filter = {
			name: selectedTab?.key?.value,
			skillIds: [
				{
					id: skill.id,
					value: skill.name,
					description: skill.description,
					alert: skill.alert,
				},
			],
		};
		history.push({
			pathname: PATHS.COURSEPAGE_STR + defaultChildData?.id,
			type: "dimCatlog",
			state: {
				data: { filter: [filter] },
			},
			filterFrom: "dim",
		});
	};

	return (
		<>
			<Home>
				<div
					className="d-flex flex-wrap SpecialLeftpanel w-100"
					key={allCourse}
				>
					<div className="d-flex w-100 align-items-start overflow-visible">
						<div className="LeftbarPannel p-0">
							<div className="heading  gridSection d-flex w-100 p-0 border-0">
								{getdimension && (
									<DimTab
										toggleTab={toggleTab}
										data={myDimData}
										toggleState={selectedDim}
									/>
								)}
							</div>
							<div className="CourseCardWrapper">

								<div className="gridSection mt-2">
									{selectedTab?.data?.skills?.length > 0 ? (
										<>
											{brochure ? (
												<div className="notenrollednanycourses flex">
													<div className="notenrolldimtxt  w-50">
														{isAllHide ? (
															<h3>
																You have chosen to hide all courses.{" "}
																<a
																	href="javascript:void(0)"
																	onClick={() => handleSetting()}
																>
																	{" "}
																	Click here{" "}
																</a>{" "}
																to update your settings to bring the courses
																back into view!
															</h3>
														) : (
															<h3>
																<a
																	href="javascript:void(0)"
																	onClick={() =>
																		coursePageRedirect(selectedTab?.data)
																	}
																>
																	{" "}
																	Click here{" "}
																</a>
																to view available course in the{" "}
																{selectedTab?.key?.value} dimension and enroll.
															</h3>
														)}
													</div>
													<div className="notenrolldimimg w-50">
														<img src={image.nocourseenrollimage} />
													</div>
												</div>
											) : (
												<div className="gridSection mx-2 mb-2 p-1">
													<div className="skillSeltaglist coursepagelist">
														<div class="intrestlisting dimpagetags">
															<div class="Course_filter mr-3">
																<span>
																	<img src={image.CourseTitleIcon} alt="" />
																</span>
															</div>
															{allCourse?.map(
																(skill, index) =>
																	skill?.courses?.length > 0 && (
																		<>
																			<div
																				class="instlistitem"
																				key={skill?.name}
																			>
																				<div class="carditem">
																					<label class="Selcheckbox ActiveQQst m-0 flex withcheckedicon">
																						<span class="QQtitle flexone pointer">
																							<h4 class="flex w-100">
																								{skill?.name}
																							</h4>
																						</span>
																						{skill?.showInput && (
																							<>
																								<input
																									type="checkbox"
																									id="Public"
																									name="skill0"
																									disabled={
																										disableSkillSelection &&
																											skill?.isCourseDisplay
																											? true
																											: false
																									}
																									value="PUBLIC"
																									onClick={() =>
																										handleSkillCourse(
																											index,
																											skill?.isCourseDisplay
																										)
																									}
																									defaultChecked={
																										skill?.isCourseDisplay
																											? true
																											: false
																									}
																								/>
																								<span class="checkmark"></span>
																							</>
																						)}
																						{skill?.showPencil && (
																							<span
																								className="ml-2 edit_pencil"
																								onClick={() =>
																									handleCloseGradePopup(
																										true,
																										skill
																									)
																								}
																							>
																								<i class="fa-light fa-pencil active"></i>
																							</span>
																						)}
																						{skill?.showIcon && (
																							<>
																								<span
																									className="ml-2 edit_pencil"
																									aria-haspopup="true"
																									aria-expanded="false"
																									id="barlistdropdown"
																									data-toggle="dropdown"
																								>
																									<i class="fa-solid fa-bars"></i>
																								</span>
																								<ul
																									className="dropdown-menu dropdown-menu-end"
																									aria-labelledby="barlistdropdown"
																								>
																									<li
																										onClick={() =>
																											showActivity(
																												undefined,
																												"Vicky",
																												skill?.name,
																												skill?.courses.find(
																													(course) =>
																														course?.type ===
																														"VICKY"
																												),
																												skill
																											)
																										}
																									>
																										<span>
																											<i class="fa-regular fa-folders mr-2"></i>
																										</span>{" "}
																										<strong>Curriculum</strong>
																									</li>
																									<li
																										onClick={() =>
																											showActivity(
																												undefined,
																												"Vicky",
																												skill?.name,
																												skill?.courses.find(
																													(course) =>
																														course?.type ===
																														"VICKY"
																												),
																												skill,
																												true
																											)
																										}
																									>
																										<span>
																											<i class="fa-regular fa-person-chalkboard mr-2"></i>
																										</span>{" "}
																										<strong>Resources</strong>
																									</li>
																								</ul>
																							</>
																						)}
																					</label>
																				</div>
																			</div>
																		</>
																	)
															)}
														</div>
													</div>

													{/* {allCourse?.map(
														(skill, index) =>
															skill?.isCourseDisplay &&
															!["Social Studies", "Science"]?.includes(
																skill?.name
															) &&
															getSequnceSort(skill?.courses)?.map(
																(course, key) => {
																	course.dimension = {
																		key: selectedDim?.key,
																		value: selectedDim?.value,
																	};
																	return (
																		![
																			"Math Resources",
																			"English Resources",
																		]?.includes(course?.name) && (
																			<CourseCard
																				data={course}
																				viewReward={viewReward}
																				viewCertificate={viewCertificate}
																				skills={skill}
																			/>
																		)
																	);
																}
															)
													)} */}
													{allDimCourses?.map(
														(course, key) => {
															course.dimension = {
																key: selectedDim?.key,
																value: selectedDim?.value,
															};
															return (
																![
																	"Math Resources",
																	"English Resources",
																]?.includes(course?.name) && course?.skill?.isCourseDisplay && (
																	<CourseCard
																		data={course}
																		viewReward={viewReward}
																		viewCertificate={viewCertificate}
																		skills={course?.skill}
																	/>
																)
															);
														}
													)
													}

													{isAllCourseShow &&
														["PARENT", "LEARNER"]?.includes(loggedInUser?.role?.name) && (
															<div className="notenrolledskill mt-5">
																<h4 className="mb-3">
																	We offer more courses in the skills below you
																	have not explored yet. Click the skills to view
																	available courses and enroll.
																</h4>
																<div className="flexone align-items-baseline">
																	<span className="skill_imageicon_ mr-3">
																		<img src={image.CourseTitleIcon} />
																	</span>
																	<ul className="flexone flex-wrap w-100">
																		{allCourse?.map(
																			(skill, index) =>
																				((skill?.courses?.length === 0 &&
																					skill?.courseCount > 0) ||
																					!skill.showSkill) && (
																					<li
																						onClick={() => handleClickHere(skill)}
																					>
																						{skill?.name}
																					</li>
																				)
																		)}
																	</ul>
																</div>
															</div>
														)}
												</div>
											)}
										</>
									) : (
										<div className="GridCardList">
											<ShimmerCategoryList
												items={3}
												categoryStyle="STYLE_SIX"
											/>
										</div>
									)}
								</div>
							</div>
							<div class="LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip ">
								<div class="form-group BDsubmitbutton d-flex justify-content-between align-items-center m-0 ">
									<div>
										{/* <button class="btn-blue btn-login d-block float-right w-auto">
									<i class="fa-solid fa-paper-plane mr-2"></i>Begin Survey
									</button> */}
									</div>
								</div>
							</div>

						</div>

						<IndexRightPanel
							progressObj={selectedTab?.data}
							selectedTab={selectedTab?.key}
							data={selectedTab}
							showActivity={showActivity}
							viewReward={viewReward}
						/>

						{showmodal && notification === true && (
							<CourseConfirmedPage
								closeModal={closeModal}
								courseIds={courseIds}
								setCourseIds={setCourseIds}
								onSubmit={onSubmit}
								noti={noti}
								setNoti={setNoti}
							/>
						)}
					</div>
				</div>
			</Home>
			{showActivityForm?.obj && (
				<AddVdoActivity
					obj={showActivityForm?.obj}
					type={showActivityForm?.data}
					closeModal={closeModal}
					showActivityForm={showActivityForm}
				/>
			)}
			{viewActivity.view && (
				<ActivityView
					records={activyDetails}
					data={viewActivity?.data}
					object={viewActivity?.activityObj}
					closeModalSub={closeModalSub}
					closeModalEdit={closeModalEdit}
					dimName={viewActivity?.dimName}
					type={viewActivity?.type}
					selectedCourse={viewActivity?.selectedCourse}
					showActivity={showActivity}
					showViewActivity={showViewActivity}
					showAddActivity={showAddActivity}
				/>
			)}
			{showCurriculum?.flag === true && (
				<CurriculumDetails
					data={showCurriculum?.data}
					closeModal={closeModal}
					type={showCurriculum?.type}
					courses={showCurriculum?.courses}
					courseName={showCurriculum?.courseSubject}
					showViewActivity={showViewActivity}
					showActivity={showActivity}
					dimensionName={showCurriculum?.courses?.key}
					selectedTab={selectedTab}
					showResourceInnerDetail={showResourceInnerDetail}
					handlePopUp={handlePopUp}
					providerPopup={providerPopup}
					setShowChooseSubject={setShowChooseSubject}
					tabIndex={activeTab}
					selectedLesson={selectLesson && selectLesson[0]?.modLessId}
				/>
			)}
			{curriculamData?.type && (
				<CurriculumDetails
					closeModal={openCurriculam}
					getLessonResources={getLessonResources}
					courseName={curriculamData}
					data={curriculamData?.modules}
					enroll={curriculamData?.enroll}
					selectedTab={selectedTab}
					showActivity={showActivity}
					handlePopUp={handlePopUp}
					providerPopup={providerPopup}
					tabIndex={activeTab}
				/>
			)}
			{showCurriculum?.courseSubject && (
				<ExploreView
					resourceInnerData={resourceInnerData}
					selectedCourse={showCurriculum?.courseSubject}
					handlePopUp={handlePopUp}
					getLessonResources={getLessonResources}
					areaId={showCurriculum?.skills?.id}
					providerPopup={providerPopup}
				/>
			)}
			<div key={chooseSubjectObj.length}>
				{showChooseSubject && (
					<ChooseSubject
						data={chooseSubjectObj}
						close={close}
						selectedTab={selectedTab}
						handlePopUp={handlePopUp}
						_viewCourseDetail={_viewCourseDetail}
						setDisable={setDisable}
					/>
				)}
			</div>
			{showSubs && (
				<Subscription close={closeSub} subscribeData={subscribeData} />
			)}
			{certificate && (
				<Certificate
					_redirectLesson={viewCertificate}
					certificateData={certificateData}
					certificateskilldata={certificateskilldata}
				/>
			)}
			{openReward && (
				<RewardPopUp
					closeReward={viewReward}
					rewardDataWithoutAPI={openReward}
				/>
			)}
			{abandonSelectedValue &&
				selectedTab?.data?.abandonedCourses.length > 0 && (
					<DimInfoPopup
						closeDipInfoPopup={closeDipInfoPopup}
						coursesData={selectedTab?.data?.abandonedCourses}
					/>
				)}
			{showInfoPopup && (
				<SkillGrowth
					data={infoPopupData}
					dimName={selectedDimension}
					setShowSkillPopup={setShowSkillPopup}
				/>
			)}
			{showGradePopup && (
				<GradePopup
					handleCloseGradePopup={handleCloseGradePopup}
					data={curricullumSkill}
					showActivity={showActivity}
				/>
			)}

			{showCurriResourcePopup && (
				<div className="newinfo_popupdimension Whatadmcndo resoandcurriculum">
					<div
						className="modal fade show d-block"
						id="schoolactivity11"
						role="dialog"
					>
						<div className="modal-dialog modal-lg  gradepopup">
							<div className="modal-content courseInformation">
								<div className="modal-header">
									<div className="heading border-0 p-0 w-100">
										<h2 className="flex">
											<span>
												{isResourceClick ? (
													<i className="fa-regular fa-person-chalkboard mr-2"></i>
												) : (
													<i className="fa-regular fa-folders mr-2"></i>
												)}
												{isResourceClick ? "Resources" : "Curriculum"}
											</span>
											<button
												className="btn btn-primary"
												onClick={() => handleCurriResourcePopup(false, false)}
											>
												<i className="fa-regular fa-xmark m-0"></i>
											</button>
										</h2>
									</div>
								</div>

								<div className="modal-body">
									<div className="infopopup_wrap  align-items-start">
										<div className="infopopupright align-items-start pb-2">
											<div className="WelcomScreen">
												<div className="welcomscreenContent welcomscreentwo ">
													<div className="w-100  pb-0 coursedescstyl curriresopopup">
														<p className="d-flex align-items-start">
															{isResourceClick
																? "Explore our Resources link to discover a curated collection of top-quality internet resources, quizzes, and practice tests that cover all modules and lessons relevant to your selected subject and grade level."
																: "Select the Curriculum link to access a comprehensive list of modules and lessons included within the Common Core Plus curriculum for your chosen grade level."}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer dontshowagain">
									<div className="form-group BDsubmitbutton m-0 d-flex">
										<div className="buttonDistribotion">
											<button
												type="button"
												className="btn-blue btn-login d-block mb-5 cancelbutton"
												onClick={() => handleCurriResourcePopup(false, false)}
											>
												<i className="fa-solid fa-xmark"></i> Close
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Intellectual;
