import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as image from "../../resources/images";
import { addAvtar, getAllUsersDetail, getAvtar, getBadges } from "../../redux/actions/APIs";
import { ShimmerCategoryList } from "react-shimmer-effects";
import DeletePlanActivities from "../learnerplan/DeletePlanActivities";
import { getSequnceSort } from "../../utils/helper";
import MyBadges from "./MyBadges";
import MyAvtar from "./MyAvtar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PATHS } from "../../utils";
const MyLockerScreen = ({ showLockerScreen, childData }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { defaultChildData, getAllAvtar, addAvtarData, response, loggedInUser, getAllBadges } = useSelector(
		(state) => state.collections
	);

	const [myAvtar, setMyAvtar] = useState("");
	const [loader, setLoader] = useState(false);
	const [myTab, setMyTab] = useState("my_avtar");

	useEffect(() => {
		if (childData?.id) {
			dispatch(getAvtar(childData?.id));
			dispatch(getBadges(childData?.id));
		}
	}, [childData]);

	useEffect(() => {
		if (getAllAvtar?.records?.length > 0) {
			let avtObj = getAllAvtar?.records[0]?.avatarRec?.find((item) => item?.isUserSelected)
			setMyAvtar(avtObj);
		}
	}, [getAllAvtar])

	const showSelectAvtar = (avtrObj) => {
		setMyAvtar(avtrObj);
	}

	const handleBuy = () => {
		if (getAllAvtar?.records[0]?.remainingPoints < myAvtar?.avtarpoint && !myAvtar?.isUserPurchased) {

			setShowAlertPopUp({
				title: "Buy Avatar",
				message: "You don't have sufficient points."
			})
			return;
		}
		let body = {
			avtarId: myAvtar?.avtarId,
			avtarpoint: myAvtar?.avtarpoint,
			leftImageUrl: myAvtar?.leftImageUrl,
			rightImageUrl: myAvtar?.rightImageUrl,
			isUserSelected: myAvtar?.isUserSelected,
			isUserPurchased: myAvtar?.isUserPurchased,
			usersPoints: myAvtar?.usersPoints,
			sequence: myAvtar?.sequence,
			spendPoint: getAllAvtar?.records[0]?.remainingPoints,
		}
		setLoader(true);
		//	dispatch(addAvtar(childData, body))
		dispatch(addAvtar(childData?.id, body))
	}

	useEffect(() => {
		if (response) {
			setLoader(false);
		}
		if (response?.success && response?.records[0]?.avtarId === myAvtar?.id) {
			dispatch(getAllUsersDetail());
			dispatch(getAvtar(childData?.id));
		}
	}, [response])

	const [showAlertPopUp, setShowAlertPopUp] = useState();

	const handleClose = () => {
		setShowAlertPopUp();
	}

	const handleTab = (type) => {
		setMyTab(type);
	}

	// console.log("getAllAvtar : ", getAllAvtar, myAvtar, response, getAllBadges);

	const gotoCourse = () => {
		showLockerScreen();
		history.push({
			pathname: PATHS.COURSEPAGE_STR + childData?.id,
			state: {
				isCreateHmfPlan: true,
			},
		});
	}

	return (
		<>
			<div className="halfPagePOpup SchoolActivityPopup ">
				<div className="modal d-flex" id="schoolactivity186" role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content course_plan__Information">
							<div className="modal-header">
								<div className="heading border-0 p-0">
									<h2 className="flex">
										<span>
											<img src={image.mylocker} className="mr-2" />
											{childData?.firstName}'s Locker
										</span>
										<button
											className="btn btn-primary"
											data-dismiss="modal"
											onClick={() => showLockerScreen("")}
										>
											<i className="fa-regular fa-xmark m-0"></i>
										</button>
									</h2>
								</div>
							</div>
							<div className="modal-body paymentinfo p-0">
								<div class="tabgrid w-100 m-0  ">
									<ul>
										<li class={`tabs  ${myTab === "my_avtar" ? "active-tabs" : ""} w-25`}
											onClick={() => handleTab("my_avtar")}>
											<img src={image.myAvtar_icon} />
											My Avatar
										</li>
										<li class={`tabs  ${myTab === "my_bedges" ? "active-tabs" : ""} w-25`}
											onClick={() => handleTab("my_bedges")}>
											<img src={image.score_badge} alt="" />
											My Badges
										</li>
									</ul>
								</div>
								{getAllAvtar?.records?.length > 0 && myTab === "my_avtar" ? (
									<MyAvtar showSelectAvtar={showSelectAvtar}
										avtarData={getAllAvtar?.records[0]}
										myAvtar={myAvtar}
										loggedInUser={loggedInUser}
										handleBuy={handleBuy}
										loader={loader}

									/>
								) : myTab === "my_bedges" ? (
									<MyBadges badgesData={getSequnceSort(getAllBadges?.records)} gotoCourse={gotoCourse} />
								) : (
									<ShimmerCategoryList items={3} categoryStyle="STYLE_SIX" />
								)}
							</div>

							<div className="modal-footer">
								<div className="form-group BDsubmitbutton d-flex m-0">
									<div className="buttonDistribotion">
										<div className="buttonDistribotion">
											<button
												type="button"
												className="btn-blue btn-login d-block mb-5 cancelbutton "
												onClick={() => showLockerScreen("")}
											>
												<i className="fa-solid fa-xmark"></i> Close
											</button>
											{
												myTab === "my_avtar" && (
													<>
														{
															loader ? (
																<div className="justify-content-end">
																	<button
																		className="btn-blue btn-login d-block ml-auto mr-2  w-auto"
																		disabled
																	>
																		<span className="RounAnimation mr-1"></span> Please
																		Wait...
																	</button>
																</div>
															) : (
																<button
																	type="button"
																	className="btn-blue btn-login d-block w-auto"
																	onClick={() => handleBuy()}
																>
																	<>
																		{myAvtar?.isUserPurchased ? (
																			<strong>
																				<i class="fa-solid fa-paper-plane mr-2 m-0"></i>{" "}
																				Select
																			</strong>
																		) : (
																			<span>
																				<i class="fa-solid fa-cart-shopping mr-2"></i>{" "}
																				Buy
																			</span>
																		)}
																	</>
																</button>
															)
														}
													</>
												)
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showAlertPopUp && (
				<DeletePlanActivities
					closePlan={handleClose}
					deletePlan={showAlertPopUp}
				/>
			)}
		</>

	);
};
export default MyLockerScreen;
