import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as image from "../../resources/images";
import { getSequnceSort, avaterUserPositoin, dateSortingByStartDate, textTrim } from "../../utils/helper";
import { PATHS } from "../../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Vicky from "../controls/Vicky";
import { bloomsterPolyText } from "../../utils/polyTextHelper";
import UserActivityProgress from "./UserActivityProgress";
import UserQuiz from "./UserQuiz";
import { getModuleChallenge, getSocialActivityDetail, Knowladgecheck, postModuleChallenge } from "../../redux/actions/APIs";
import { selectOptionData } from "../../redux/actions";
import UserCongratulations from "./UserCongratulations";
import { ShimmerCategoryList } from "react-shimmer-effects";
import { ViewVideo } from "../dimensions/activity";
const UserProgressRightPanel = ({ handleWhyAPlanPopup, handleMoreOptions, handleGotoCourse, userPlanData, showPlanCourseData,
	courseIndex, moduleObj, setShowAlertPopUp, setShowVideoPopUp, handleVideoData
}) => {
	const dispatch = useDispatch();
	const { defaultChildData, loggedInUser, socialActivityData, selectOptData, activityDataResp, kcSubmitResponse } = useSelector((state) => state.collections);
	const [indexOfRightPnl, setIndexOfRightPnl] = useState(0);
	const [surveyIndex, setSurveyIndex] = useState(0);
	const [questionIndex, setQuestionIndex] = useState(0);

	useEffect(() => {
		dispatch(selectOptionData({
			type: "",
			title: "",
			questionData: {},
			optNuggetData: []
		}));
	}, [])

	const [isNext, setIsNext] = useState(true);

	useEffect(() => {
		// console.log("useEffect selectOptData : ", selectOptData, kcSubmitResponse, activityDataResp);
		if (selectOptData) {
			let tempActivity = activityDataResp?.records[0];
			let isCongratulation = true;
			activityDataResp?.records[0]?.survey?.map((data, index) => {
				data?.questions?.map((qustObj, key) => {
					if (!qustObj?.attempted && isCongratulation) {
						isCongratulation = false;
					}
				})
			})
			if (!isCongratulation && surveyIndex === tempActivity?.survey?.length - 1 && questionIndex === tempActivity?.survey[surveyIndex]?.questions?.length - 1) {
				setIsNext(false);
			} else {
				setIsNext(true);
			}

			if (selectOptData?.type === "" && kcSubmitResponse?.success && !activityDataResp?.records[0]?.isComplete && indexOfRightPnl === 2) {
				if (isCongratulation) {
					setIndexOfRightPnl(indexOfRightPnl + 1);
					setSurveyIndex(0);
					dispatch(postModuleChallenge());
				}
			}
		}

	}, [selectOptData])

	useEffect(() => {
		if (activityDataResp?.records?.length > 0) {
			if (!activityDataResp?.records[0]?.isComplete) {
				if (activityDataResp?.records?.length > 0) {
					setIndexOfRightPnl(1);
				} else {
					setIndexOfRightPnl(0);
				}
			} else if (activityDataResp?.records[0]?.isComplete) {
				setIndexOfRightPnl(3);
			}
		}
	}, [activityDataResp])

	useEffect(() => {
		if (showPlanCourseData) {
			dispatch(getModuleChallenge(
				defaultChildData?.id,
				moduleObj?.id
			))
		}
	}, [courseIndex, moduleObj])

	const [getOptionId, setOptionId] = useState();
	const [getOptionIdArr, setOptionIdArr] = useState();

	const handleSubmit = (qustionObj, optionObj) => {
		let kcpoints = 0;
		optionObj.isUserSelected = true;

		if (optionObj?.attempted && optionObj?.isCorrect) {
			qustionObj.attempted = true;

		}
		dispatch(selectOptionData({ ...selectOptData, type: "ShowNuggetPopup" }));
		// console.log("etOptionId !== optionObj : ",getOptionId, optionObj?.id, getOptionId !== optionObj?.id);
		if (getOptionId !== optionObj?.id) {
			qustionObj.invalidAttempt = qustionObj?.invalidAttempt + 1;
			if (qustionObj?.attempted) {
				kcpoints = qustionObj.invalidAttempt === 1 ? 10 : 5;
			}
			if (qustionObj.invalidAttempt === 3) {
				kcpoints = 1;
				qustionObj?.options?.map((optObj, key) => {
					if (optObj?.isCorrect) {
						optObj.attempted = true;
						qustionObj.attempted = true;
					} else {
						optObj.attempted = false;
					}
				})
			}
			const body = {
				questionId: qustionObj?.id,
				invalidAttempt: qustionObj.invalidAttempt,
				type: qustionObj?.type,
				answer: "",
				optionIds: [optionObj?.id],
				kcpoints: kcpoints
			};
			KnowladgecheckHandler(body, qustionObj);
			setOptionId(optionObj?.id);
		}

	}


	const KnowladgecheckHandler = async (attemptBodyData, qustionObj) => {
		let getActivityData = activityDataResp?.records[0];
		dispatch(
			postModuleChallenge(
				defaultChildData.id,
				getActivityData?.activityId,
				qustionObj?.surveyId,
				attemptBodyData,
			)
		);
		//	getActivityData.points += attemptBodyData?.kcpoints;
		if (qustionObj?.attempted) {
			showPlanCourseData[courseIndex].points += attemptBodyData?.kcpoints;
		}
	};

	const handleSubmitCheckBox = (qustionObj, optionObj) => {
		let kcpoints = 0;
		let selectOptId = [];
		let correctOptIds = [];
		let attemptOptIds = [];
		let isSubmit = false;
		qustionObj?.options?.map((item, key) => {
			if (item?.attempted) {
				item.isUserSelected = true;
				selectOptId?.push(item?.id);
			}
			if (item?.isCorrect) {
				correctOptIds?.push(item?.id);
			}
			if (item?.attempted && item?.isCorrect) {
				attemptOptIds?.push(item?.id);
			}
		})

		if (correctOptIds?.length === attemptOptIds?.length && correctOptIds?.length === selectOptId?.length) {
			qustionObj.attempted = true;
		}
		let title = qustionObj?.attempted ? "Well Done!" : "Close! Keep Going!";
		dispatch(selectOptionData({ ...selectOptData, title: title, type: "ShowNuggetPopup" }));
		qustionObj?.options?.map((opt, key) => {
			const idKey = getOptionIdArr?.findIndex((myId) => myId === opt?.id)
			// console.log("opt?.attempted && idKey === -1 && !isSubmit : ",opt?.attempted , idKey, isSubmit);
			if (opt?.attempted && idKey === -1 && !isSubmit) {
				isSubmit = true;
			}
			if (!opt?.attempted && idKey !== -1 && !isSubmit) {
				isSubmit = true;
			}
		})

		if (isSubmit) {
			qustionObj.invalidAttempt = qustionObj?.invalidAttempt + 1;
			if (qustionObj?.attempted) {
				kcpoints = qustionObj.invalidAttempt === 1 ? 10 : 5;
			}
			if (qustionObj.invalidAttempt === 3) {
				kcpoints = 1;
				qustionObj?.options?.map((optObj, key) => {
					if (optObj?.isCorrect) {
						optObj.attempted = true;
						qustionObj.attempted = true;
					} else {
						optObj.attempted = false;
					}
				})
			}
			const body = {
				kcpoints: kcpoints,
				questionId: qustionObj?.id,
				optionIds: selectOptId,
				invalidAttempt: qustionObj.invalidAttempt,
				type: qustionObj?.type,
				answer: "",
				activityTime: "",
				isKc: true,
			};
			KnowladgecheckHandler(body, qustionObj);
			// console.log("KnowladgecheckHandler : ", body, qustionObj);
			setOptionIdArr(selectOptId);
		}

	}

	const handleShowRightPnl = () => {
		let tempActivity = activityDataResp?.records[0];
		if (!moduleObj?.isCompleted) {
			setShowAlertPopUp({
				title: 'Module Challenge',
				message: `Please complete all Lessons of Module ${moduleObj?.sequence} before proceeding to the Module Challenge.`,
			});
			return;
		}
		if (selectOptData?.type) {
			selectOptData.type = "";
		}
		if ((indexOfRightPnl === 1 || (indexOfRightPnl === 2 && surveyIndex === tempActivity?.survey?.length - 1 && questionIndex === tempActivity?.survey[surveyIndex]?.questions?.length - 1))) {
			setIndexOfRightPnl(indexOfRightPnl + 1)
			setSurveyIndex(0);
			return;
		}
		handleKcIndex(tempActivity);

	}

	const handleKcIndex = (tempActivity) => {
		if (questionIndex === tempActivity?.survey[surveyIndex]?.questions?.length - 1) {
			setSurveyIndex(surveyIndex + 1);
			setQuestionIndex(0);
		} else {
			setQuestionIndex(questionIndex + 1)
		}

	}

	const handleBack = () => {
		if (activityDataResp?.records[0]?.survey?.length > 0 && indexOfRightPnl == 3) {
			setSurveyIndex(activityDataResp?.records[0]?.survey?.length - 1);

		}
		if (surveyIndex > 0 && indexOfRightPnl == 2) {
			setSurveyIndex(surveyIndex - 1);
			return;
		}
		setIndexOfRightPnl(indexOfRightPnl - 1)
	}



	// console.log("socialActivityData userOriogress : ",activityDataResp,selectOptData, moduleObj);
	return (
		<div className="heading p-0 border-0">
			<h2 className='flex'>
				<div class="form-title mt-0 mb-0 Enrollcoursetitle heading summery_plan">
					<h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
						{userPlanData?.length === 0 ? (
							<span className="flexone">
								<span className="mr-2"><img src={image.your_progress_} alt="" /></span>
								Start Your Journey
							</span>
						) : (
							<span>
								{" "}
								<img src={image.howitwork} className="mr-2" alt="" />
								Module Challenge
							</span>
						)}

						{loggedInUser?.role?.name === "PARENT" && (
							<div className="learner_pLan_dropdown">
								<span
									aria-haspopup="true"
									className="pointer"
									aria-expanded="false"
									id="leaneringplndwn"
									data-toggle="dropdown"
								>
									<i class="fa-sharp fa-solid fa-bars"></i>
								</span>
								<ul
									className="dropdown-menu dropdown-menu-end lPDropdwnList"
									aria-labelledby="leaneringplndwn"
								>
									<li onClick={() => handleWhyAPlanPopup()}>
										<p>
											{" "}
											<img
												src={image.SceneQuestionicon}
												className="mr-2"
											/>
											Why A Growth Plan?
										</p>
									</li>
									<li>
										<p
											onClick={() =>
												handleMoreOptions("createNewPlan", true)
											}
										>
											{" "}
											<img src={image.newleaner_plan} className="mr-2" />
											Create Growth Plan
										</p>
									</li>
									<li>
										<p
											onClick={() =>
												handleMoreOptions("courseCatlog", true)
											}
										>
											{" "}
											<img src={image.mortarboard} className="mr-2" />
											Course Catalog
										</p>
									</li>
								</ul>
							</div>
						)}
					</h2>
				</div>
			</h2>
			{userPlanData?.length === 0 ? (
				<div className="CourseCardWrapper fullHeight100">
					<div class="LessionDtlOverview">
						<div class="d-flex align-items-start flex-wrap">
							<div class="flex w-100 mb-3 align-items-start flex-wrap text-justify textcontentSidebar">
								{bloomsterPolyText("polytext_2", false)}
								<Vicky text={bloomsterPolyText("polytext_2", true)} />
							</div>
						</div>
					</div>
				</div>
			) : (
				<>
					{(!activityDataResp?.records || indexOfRightPnl === 0) ? (
						(
							<ShimmerCategoryList items={3} categoryStyle="STYLE_SIX" />
						)
					) : (
						<>
							{indexOfRightPnl === 1 && (
								<UserActivityProgress
									activityData={activityDataResp?.records[0]}
									setShowVideoPopUp={setShowVideoPopUp}
									handleVideoData={handleVideoData}
								/>
							)}
							{indexOfRightPnl === 2 && (
								<UserQuiz
									getCompleteModuleData={activityDataResp?.records[0]}
									moduleData={getSequnceSort(activityDataResp?.records[0]?.survey)[surveyIndex]}
									questionIndex={questionIndex}
									setQuestionIndex={setQuestionIndex}
									setSurveyIndex={setSurveyIndex}
									surveyIndex={surveyIndex}
								/>
							)}
							{indexOfRightPnl === 3 && (
								<UserCongratulations />
							)}
						</>
					)}

				</>

			)}
			<div class="LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip ">
				<div class="form-group BDsubmitbutton d-flex justify-content-right align-items-center m-0 ">
					<div class="">
						<button
							class="btn-blue btn-login d-block float-right w-auto"
							disabled={[0, 1]?.includes(indexOfRightPnl)}
							// onClick={() => { setIndexOfRightPnl(indexOfRightPnl - 1), setSurveyIndex(0) }}
							onClick={() => handleBack()}
						>
							<span>
								{" "}
								<i class="fa-solid fa-arrow-left m-0 mr-2"></i>
							</span>
							Back
						</button>
					</div>
					<div class="w-100">
						{([0, 1]?.includes(indexOfRightPnl) || selectOptData?.questionData?.attempted || indexOfRightPnl === 3) ? (
							<button class="btn-blue btn-login d-block float-right w-auto"
								disabled={indexOfRightPnl === 3 || indexOfRightPnl === 0 || (indexOfRightPnl === 2 && !isNext)}
								onClick={() => { handleShowRightPnl() }}
							>Next
								<span>
									{" "}
									<i className="fa-solid fa-arrow-right m-0 ml-2"></i>
								</span>
							</button>
						) : (
							<button
								type="submit"
								className="btn-blue btn-login d-block mb-5 ml-auto"
								disabled={!selectOptData?.optNuggetData[0]?.attempted}
								onClick={() => {
									selectOptData?.questionData?.type === "RADIOQ" ? handleSubmit(selectOptData?.questionData, selectOptData?.optNuggetData[0])
										: handleSubmitCheckBox(selectOptData?.questionData, selectOptData?.optNuggetData[0])
								}}
							>
								<span >
									<i className="fa-solid fa-paper-plane mr-2 m-0"></i>
								</span>
								Submit
							</button>
						)}
					</div>
					{/* <div class="w-100">
						<button
						type="button"
						className="btn-blue btn-login d-block mb-5 w-auto rocket_icon"
						onClick={() => handleGotoCourse()}
					>
						<i class="fa-light fa-rocket-launch mr-2"></i>
						Go to Course Catalog
					</button>
					</div> */}
				</div>
			</div>
		</div>
	);
}
export default UserProgressRightPanel;