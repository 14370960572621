import * as image from "../../resources/images";
import { ShimmerCategoryList, useForm } from "../../utils/Packages";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getHcfChallenges, getLifeChallenges } from "../../redux/actions/APIs";
const ChallengeOnePlanModule = ({ handlePopUpOpen, selecteChalnge, setSelecteChalnge }) => {
  const dispatch = useDispatch();
  const { defaultChildData,
    lifeChallengeData,
  } = useSelector((state) => state.collections);

  useEffect(() => {
    if (!lifeChallengeData) {
      dispatch(getLifeChallenges());
    }
    //  setSelecteChalnge();
  }, []);

  const handleOPtion = (value) => {
    setSelecteChalnge(value);
  }
  console.log("selecteChalnge : ", selecteChalnge);
  return (
    <div class="Challenges_list px-3 ">
      <h3>
        Please select one life event to help Bloomster recommend the best Growth Plan to help your child navigate it.
        {/* <span onClick={() => handlePopUpOpen("challengesInformation")}>
          <img
            src="/static/media/chat-icon.2a607af3ca378ac033c1.png"
            class="ml-2 ichat_icon pointer"
            alt=""
          />
        </span> */}
      </h3>
      <div class="challenges_itesms">
        <div class="ScenecerelateddQuiz p-0">
          <div class="signupType rangetypeQuestion textAreatypeQuestion mb-2 pt-3 mt-0  challenge_one_plan">
            {/* <h4 class="mb-2 d-flex">
              <span>
                <img
                  src="/static/media/learnerProfiles.b18150dc6085a2997846.png"
                  alt=""
                />
              </span>
              <div class="flex w-100">
                <span>My Child...</span>
              </div>
            </h4> */}
            {lifeChallengeData?.records?.length > 0 &&
              lifeChallengeData?.records?.map((chlngObj, index) => (
                <label class="Selcheckbox align-items-start d-block">
                  <span class="fw-bold"></span>
                  {chlngObj?.name}
                  <input
                    type="radio"
                    id={"option1"}
                    name={"option1"}
                    checked={selecteChalnge === chlngObj?.id}
                    onClick={() => handleOPtion(chlngObj?.id)}
                  />
                  <span class="checkmark"></span>
                </label>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ChallengeOnePlanModule