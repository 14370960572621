import React, { useEffect, useState } from "react";
import * as image from "../../resources/images";
import { useDispatch, useSelector } from "react-redux";
import { courseEnrollmentWithSession, getAllUsersDetail, getDimPlanCours, getSettingData, getUpdateLearnerPlan, updateLearnerPlan, varificationEmail } from "../../redux/actions/APIs";
import { toast } from "react-toastify";
import Success from "../notifications/Success";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PATHS } from "../../utils";
import { message } from "antd";
import { parentLoginAction } from "../../redux/actions";
import { getCalculatePlanDate, getCapitalized, getDateByAddDays, getSequnceSort, handleSeatingsDate } from "../../utils/helper";
import WebLayout from "../layout/WebLayout";
import { isMobile, isTablet, isDesktop } from 'react-device-detect';

const EmailVarification = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { defaultChildData,
		signupresponse,
		alluserdetails,
		loggedInUser,
		emailResponse,
		parentLogin,
		getNewPlanResponse,
		coursePlanResponse,
		settingData
	} = useSelector((state) => state.collections);

	const [loader, setLoader] = useState(false);


	const varifyEmail = (userName) => {
		dispatch(varificationEmail(userName));
	};

	useEffect(() => {
		dispatch(getAllUsersDetail());
	}, [])

	useEffect(() => {
		if (emailResponse && emailResponse?.success) {
			toast.info(<Success response={emailResponse} />, {});
			dispatch(varificationEmail());
		}
	}, [emailResponse]);

	const handleProceed = () => {
		setLoader(true);
		dispatch(getAllUsersDetail());
	}

	useEffect(() => {
		if (alluserdetails?.success && loader) {
			if (alluserdetails?.records[0]?.isActive) {
				history.push(PATHS.STR_LEARNER_PLAN + alluserdetails?.records[0]?.children[0]?.id)
			} else {
				let activateResponse = { message: "Your account has not been activated. Please check your email to activate your account." }
				toast.info(<Success response={activateResponse} />, {});
			}
			setLoader(false);
		}
	}, [alluserdetails])

	/*
	useEffect(() => {
		if (signupresponse?.records[0]?.planId && alluserdetails?.success && calcCourseDate?.length === 0) {
			dispatch(getDimPlanCours(alluserdetails?.records[0]?.children[0]?.id, signupresponse?.records[0]?.planId));
		}
	}, [signupresponse, alluserdetails])
	*/

	//	console.log("defaultChildData eamail : ", defaultChildData, alluserdetails, signupresponse);

	useEffect(() => {
		if (signupresponse?.records[0]?.planId && signupresponse?.records[0]?.childId) {
			dispatch(getDimPlanCours(signupresponse?.records[0]?.childId, signupresponse?.records[0]?.planId));
		} else if (signupresponse && signupresponse?.records[0]?.courseId) {
			let childData = alluserdetails?.records[0]?.children[0];
			dispatch(getSettingData(childData?.id, childData?.level?.id));
		}
	}, [])

	useEffect(() => {
		if (settingData?.records?.length > 0 && signupresponse?.records[0]?.courseId) {
			let userData = signupresponse?.records[0];
			let weekArr = [];
			let todayDate = new Date();
			for (let i = 0; i < 2; i++) {
				let weekDay = new Date(todayDate).toLocaleString("en-us", { weekday: 'short' });
				weekArr?.push(weekDay);
				todayDate.setDate(todayDate.getDate() + 1);
			}
			let myCourseArr = settingData?.records?.find((item) => item?.id === userData?.courseId)
			handleCalculateStartDateEndDate(
				[myCourseArr],
				2,
				30,
				weekArr,
				userData?.childId
			);
			dispatch(getSettingData());
		}
	}, [settingData])

	useEffect(() => {
		if (coursePlanResponse?.records?.length > 0) {
			let weekArr = [];
			let todayDate = new Date();
			for (let i = 0; i < 2; i++) {
				let weekDay = new Date(todayDate).toLocaleString("en-us", { weekday: 'short' });
				weekArr?.push(weekDay);
				todayDate.setDate(todayDate.getDate() + 1);
			}
			handleCalculateStartDateEndDate(
				coursePlanResponse?.records,
				2,
				30,
				weekArr
			);
		}
	}, [coursePlanResponse]);

	const handleCalculateStartDateEndDate = (data, sitting, duration, weekArr, childId) => {
		let getPlanDateArr = getCalculatePlanDate(data, duration, sitting, "", weekArr)
		if (getPlanDateArr?.length > 0) {
			let signUpData = signupresponse?.records[0];
			let array = {
				name: signUpData?.planName ? signUpData?.planName : data[0]?.name,
				sittings: 2,
				duration: 30,
				planCount: 1,
				timeZone: signUpData?.timeZone,
				planType: signUpData?.planType,
				planId: childId ? "" : data[0]?.planId,
				daysOfWeek: weekArr,
				courses: getPlanDateArr
			};
			if (childId) {
				array.iscourseupdate = true;
				array.userId = signupresponse?.records[0]?.childId;
				dispatch(courseEnrollmentWithSession(childId, data[0]?.skillId, data[0]?.id, array));
			} else {
				dispatch(updateLearnerPlan(alluserdetails?.records[0]?.children[0]?.id, alluserdetails?.records[0]?.id, array))
			}
		}
	};

	return (
		<div>
			<div id="emailVerificationParentDiv">

				<div class="email-verification">
					<div>
						<img src={image.newvickylogo} alt='' class="logo" />
					</div>
					<div class="main-div align-items-center">
						<div class="imgDiv">
							<img src={image.emailVarifyIcon} alt='' />
						</div>
						<div class="contentDiv">
							<h1>Welcome to Bloomster!</h1>
							<h3>Hi, {getCapitalized(alluserdetails?.records[0]?.firstName)}</h3>
							{/* <p>Thanks for signing up and starting your child's holistic learning journey on Bloomster. <br />Please verify your email before moving forward. An email with the 'Verify Account' button was sent to the address you provided. If you have not received the account verification email, please click the button below to resend it. */}
							<p>
								{signupresponse?.records[0]?.planId && (
									<>Your bundle has been created and saved to your account!<br /> <br /></>
								)}
								Please verify your e-mail before moving forward. An e-mail was sent to the address you provided with instructions.
								<br /> <br />
								If you do not see the e-mail please check your spam folder.
								<br /> <br />
								Still not seeing it? Click the button below to resend it.
							</p>

							{/* {isDesktop ? '(Desktop)' : isTablet ? '(Tablet)' : '(Mobile)'} */}
							{/* <a href="#" onClick={() =>
                varifyEmail(alluserdetails?.records[0]?.username)
              }
              >
                Resend Verification Email
              </a> */}
							{/* </p> */}
							{loader ? (

								<button type="button" disabled>
									<span className="RounAnimation mr-1"></span> Please
									Wait...
								</button>
							) : (
								// <button type="button"
								//   onClick={() => handleProceed()}
								// >
								//   Proceed
								// </button>
								<button type="button"
									onClick={() =>
										varifyEmail(alluserdetails?.records[0]?.username)
									}
								>
									Resend Verification Email
								</button>
							)}

						</div>
					</div>
				</div>
			</div>

			<div class="container-fluid footer"><div class="LargeScrenResponsive"><h4>© 2024 <a href="https://www.bloomster.com" target="_blank">Bloomster.com</a>. All Rights Reserved.<span class="pl-3 ml-3"> <a href="https://bloomster.com/termsandprivacypolicy" class="pblink mr-2" target="_blank">Terms of Use</a> | <a href="https://bloomster.com/termsandprivacypolicy" class="pblink ml-2" target="_blank">Privacy Policy</a> </span></h4></div></div>
		</div>
	);
};
export default EmailVarification;
