import React, { useEffect, useState } from "react";

import * as image from "../../../../resources/images";

import { beginMultiQuiz, setShowImpGrowthPopup } from "../../../../redux/actions";

import { postData } from "../../../../redux/actions/PersonalityAPIs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Tab = ({ socialActivityData, selectTabs, selectedTab }) => {
	const dispatch = useDispatch();
	const param = useParams();
	let tabCounter = 0;
	const {
		growthSurveyData,
	} = useSelector((state) => state.collections);

	//	const [getTabData, setTabData] = useState([]);

	const hasOptionalTabs =
		socialActivityData?.records[0]?.series?.reference ||
		socialActivityData?.records[0]?.series?.quiz?.id ||
		socialActivityData?.records[0]?.series?.practice?.id ||
		(socialActivityData?.records[0]?.series?.personalityType &&
			socialActivityData?.records[0]?.series?.personalityType !== "EQ") ||
		(socialActivityData?.records[0]?.series?.personalityType &&
			socialActivityData?.records[0]?.series?.personalityType === "EQ");

	/*
	useEffect(() => {
		let seriesObj = socialActivityData?.records[0]?.series;
		let tabsData = [];
		if (seriesObj) {
			if (socialActivityData?.records[0]?.isGrowthSurvey && seriesObj?.charImage) {
				tabsData?.push({ tabName: "Characters", image: image.wavehand });
			}
			if (seriesObj?.reference) {
				tabsData?.push({ tabName: "References", image: image.SceneRefrence });
			}
			if (seriesObj?.quiz?.id) {
				tabsData?.push({ tabName: "Quiz", image: image.ActiveAudio });
			}
			if (seriesObj?.practice?.id) {
				tabsData?.push({ tabName: "Practice", image: image.sceneArrow });
			}
			if (seriesObj?.personalityType && seriesObj?.personalityType === "EQ") {
				tabsData?.push({ tabName: "empthyQuiz", image: image.empathyicon });
			}
			setTabData(tabsData);
		}
		console.log("tabsData@@@@ : ", seriesObj, tabsData);

	}, [socialActivityData?.records[0]?.serie])
	*/

	const [tabCount, setTabCount] = useState(0);
	const [showMore, setShowMore] = useState(false);

	// useEffect(() => {
	// 	dispatch(clickWordData());
	// },[selectedTab]);

	useEffect(() => {
		if (socialActivityData?.records[0]?.series) {
			let counter = 1;
			//	console.log("tabsData 1 : ", socialActivityData?.records[0]);
			let data = socialActivityData?.records[0]?.series;
			if (socialActivityData?.records[0]?.isGrowthSurvey) {
				counter++;
			}
			if (data?.charImage) {
				counter++;
			}
			if (data?.reference || (data?.personalityType && data?.personalityType !== "EQ") || (data?.personalityType && data?.personalityType === "EQ")) {
				setShowMore(true);
			}
			setTabCount(counter);
			console.log("tabsData 2 : ", counter);
		}

	}, [socialActivityData?.records[0]])



	return (
		<>
			<ul>
				{socialActivityData?.records[0]?.series && (
					<>
						<li
							className={`tabs ${selectedTab === "Series" ? "active-tabs" : ""
								}`}
							onClick={() => selectTabs("Series")}
						>
							<img src={image.LessonIcon1} alt='' style={{ width: "24px" }} />{" "}
							Lessons
						</li>
						{
							socialActivityData?.records[0]?.isGrowthSurvey && (
								<li
									className={`tabs ${selectedTab === "growth" ? "active-tabs" : ""
										}`}
									onClick={() => selectTabs("growth")}>
									<img src={image.growthSurveyIcon} alt='' /> Growth Survey
								</li>
							)
						}
						{
							socialActivityData?.records[0]?.series?.charImage && (
								<li
									className={`tabs ${selectedTab === "Characters" ? "active-tabs" : ""
										}`}
									onClick={() => selectTabs("Characters")}>
									<img src={image.wavehand} alt='' /> Characters
								</li>
							)
						}
						<li
							className={`tabs ${selectedTab === "References" ? "active-tabs" : ""
								}`}
							onClick={() => selectTabs("References")}
						>
							<img src={image.SceneRefrence} alt='' style={{ width: "24px" }} />{" "}
							References
						</li>
						{/* {
							tabCount == 2 && socialActivityData?.records[0]?.series?.reference ? (
								<li
									className={`tabs ${selectedTab === "References"
										? "active-tabs"
										: ""
										}`}
									onClick={() => selectTabs("References")}>
									<img src={image.SceneRefrence} alt='' /> References
								</li>
							) : (
								showMore && (
									<li
										className='tabs learner_pLan_dropdown moreTabs' id="moreTabs">
										<span
											aria-haspopup='true'
											aria-expanded='false'
											id='MoreItemsTeb'
											data-toggle='dropdown' className="w-100 flexone">
											<img src={image.moreitems} alt='' style={{ width: "24px" }} />
											<span className='ms-1'>More</span>
											<i className='fa-regular fa-angle-down ms-2 moreTabsDownIcon'></i>
										</span>
										<ul
											className='dropdown-menu dropdown-menu-end lPDropdwnList tabingmenuDropdown'
											aria-labelledby='MoreItemsTeb'>
											{socialActivityData?.records[0]?.series?.reference && (
												<li
													className={`tabs ${selectedTab === "References"
														? "active-tabs w-100"
														: "w-100"
														}`}
													onClick={() => selectTabs("References")}>
													<img src={image.SceneRefrence} alt='' /> References
												</li>
											)}
											
											{socialActivityData?.records[0]?.series?.personalityType &&
												socialActivityData?.records[0]?.series?.personalityType !==
												"EQ" && (
													<>
														<li
															className={`tabs ${selectedTab === "Personalities"
																? "active-tabs w-100"
																: "w-100"
																}`}
															onClick={() => {
																dispatch(postData());
																selectTabs("Personalities");
															}}>
															<img src={image.peronalitytypeicon} alt='' />{" "}
															Personality Type
														</li>

														<li
															className={`tabs ${selectedTab === "multiQuiz"
																? "active-tabs w-100"
																: "w-100"
																}`}
															onClick={() => {
																dispatch(beginMultiQuiz(false));
																selectTabs("multiQuiz");
															}}>
															<img src={image.multipleintellQuiz} alt='' /> Multiple
															Intelligences
														</li>
													</>
												)
											}

											{socialActivityData?.records[0]?.series?.personalityType &&
												socialActivityData?.records[0]?.series?.personalityType ===
												"EQ" && (
													<li
														className={`tabs ${selectedTab === "empthyQuiz"
															? "active-tabs w-100"
															: "w-100"
															}`}
														onClick={() => selectTabs("empthyQuiz")}>
														<img src={image.empathyicon} alt='' /> Empathy Check
													</li>
												)
											}
										</ul>
									</li>
								)
							)
						} */}
					</>
				)}
			</ul>
		</>
	);
};

export default Tab;
