import * as image from "../../resources/images";
import { ShimmerCategoryList, useForm } from "../../utils/Packages";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getHcfChallenges } from "../../redux/actions/APIs";
const ChallengeModule = ({ handleClickOnMyChoice, isMyChoice, handlePopUpOpen }) => {
  const dispatch = useDispatch();
  const { defaultChildData,
    hcfData,
  } = useSelector((state) => state.collections);

  useEffect(() => {
    if (!hcfData) {
      dispatch(getHcfChallenges());
    }
  }, []);
  return (
    <div class="Challenges_list px-3 ">
      <h3>
        Please choose 2–4 challenges below to help Bloomster
        find the best Growth Plan for your child.
        <span onClick={() => handlePopUpOpen("challengesInformation")}>
          <img
            src="/static/media/chat-icon.2a607af3ca378ac033c1.png"
            class="ml-2 ichat_icon pointer"
            alt=""
          />
        </span>
      </h3>
      <div class="challenges_itesms">
        <div class="ScenecerelateddQuiz p-0">
          <div class="signupType rangetypeQuestion textAreatypeQuestion mb-2 pt-3 mt-0">
            <h4 class="mb-2 d-flex">
              <span>
                <img
                  src="/static/media/learnerProfiles.b18150dc6085a2997846.png"
                  alt=""
                />
              </span>
              <div class="flex w-100">
                <span>My Child...</span>
              </div>
            </h4>
            {hcfData?.records?.length > 0 &&
              hcfData?.records?.map((userChoice, index) => (
                <label class="Selcheckbox align-items-start d-block">
                  <span class="fw-bold mr-1"></span>
                  {userChoice?.uiLable}
                  <input
                    type="checkbox"
                    id="option0"
                    name="option0"
                    checked={isMyChoice?.includes(
                      userChoice?.uiLable
                    )}
                    onClick={() => handleClickOnMyChoice(userChoice?.uiLable)}
                  />
                  <span class="checkmark"></span>
                </label>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ChallengeModule