/** @format */

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { showModal } from "../../redux/actions";

import { getAllUsersDetail, getMyPlan, getVerifyUser } from "../../redux/actions/APIs";
import * as image from "../../resources/images";
import WebLayout from "../layout/WebLayout";
import { PATHS } from "../../utils";
import { Link, useHistory } from "react-router-dom";
import { getCapitalized, getSequnceSort } from "../../utils/helper";
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import { SignUp } from "../base";

const VerifyUser = () => {
	const history = useHistory();
	const { verifyUserData, responseError, verifyErr, modalData, alluserdetails, signupresponse, getMyPlanData } = useSelector(
		(state) => state.collections
	);

	const params = useParams();
	const dispatch = useDispatch();
	const [viewHtml, setViewHtml] = useState("");
	const [isScreen, setIsScreen] = useState(false);
	useEffect(() => {
		if (params?.token && !verifyErr) {
			dispatch(getVerifyUser(params?.token));
		}
		setTimeout(() => {
			setIsScreen(true);
		},3000)
	}, [!verifyErr]);
	useEffect(() => {
		if (responseError) {
			setViewHtml("error");
		}
	}, [responseError]);
	useEffect(() => {
		if (verifyUserData) {
			if (localStorage.getItem("access_token")) {
				dispatch(getAllUsersDetail());
			}
			setViewHtml("success");
		}
	}, [verifyUserData]);

	const showModalBox = (obj) => {
		if (obj.type === "SignIn") {
			history.push(PATHS.USERSIGNIN);
		} else {
			dispatch(showModal(obj));
		}
	};

	


	const [getCoursesArr, setCoursesArr] = useState([]);
	// console.log("alluserdetails@@@ : ",params,verifyErr, alluserdetails, signupresponse, verifyUserData, getCoursesArr);

	useEffect(() => {
		if (alluserdetails?.records?.length > 0) {
			dispatch(getMyPlan((alluserdetails?.records[0]?.children[0]?.id)))
			
		}
	}, [alluserdetails])

	useEffect(() => {
		if (getMyPlanData?.records?.length > 0) {
			let coursesArr = handleSelectedPlanCourses(getMyPlanData?.records[0]?.dimensions);
			setCoursesArr(coursesArr);
		}
	}, [getMyPlanData])

	useEffect(() => {
		if (getCoursesArr?.length > 0) {
			handleHome();
		} else {
			if (alluserdetails?.records?.length > 0 && !alluserdetails?.records[0]?.children[0]?.isUserPlan) {
				handleHome();
			}
		}
	}, [getCoursesArr, alluserdetails])

	const handleSelectedPlanCourses = (myPlanData) => {
		let myPlanCourses = [...myPlanData];
		let coursesArr = [];
		let myPlanTree = [];
		myPlanCourses?.map((dim, index) => {
			let dimObj = { ...dim, skills: [] }
			dim?.skills?.map((skill, sKey) => {
				let skilObj = { ...skill, courses: [] }
				dimObj?.skills?.push(skilObj);
				skill?.courses?.map((course, cKey) => {
					dimObj?.skills[sKey]?.courses?.push(course);
					course.isSelected = true;
					coursesArr?.push({ ...course });
				})
			})
			myPlanTree?.push(dimObj);
		})
		return getSequnceSort(coursesArr);
	}

	// console.log("getCoursesArr : ", getCoursesArr?.length, getCoursesArr, alluserdetails, signupresponse)
	const handleHome = () => {
		let childObj = alluserdetails?.records[0]?.children[0];
		if (childObj?.isUserPlan) {
			history.push({
				pathname: PATHS.STR_MYPLAN + childObj?.id,
			});
			if (getCoursesArr?.length === 1) {
				dispatch(showModal({ type: "SignUpPlanPopup" }))
			}
		} else {
			// history.push({
			// 	pathname: PATHS.STR_LEARNER_PLAN + childObj?.id,
			// 	showPopup: "welcomepopup"
			// });
			history.push({
				pathname: PATHS.COURSEPAGE_STR + childObj?.id,
				isGuidePopup: true,
			});

		}
	}

	return (
		alluserdetails?.records?.length > 0 ? (
			<></>
		) : isScreen && (
			<div>
			<WebLayout className="outerPagesHeader">
				<div className="userverifymopup">
					<div class="email-verification">
						<div>
							<img src={image.newvickylogo} alt='' class="logo" />
						</div>

						{viewHtml === "error" && (
							<div className="main-div">
								<div className="imgDiv w-50 p-0">
									<div className="Successimsge m-auto">
										<div className="m-auto">
											<img src={image.verifyaccount} alt="..." />
										</div>
									</div>
								</div>
								<div className="contentDiv">
									<h2>Welcome to Bloomster!</h2>

									{
										isDesktop && (<div className=" text-center">
											<h4 className=" mb-3">
												You have already <span className="greencolo">verified</span>{" "}
												your account.
												<br /> Please click the button below to Sign In.
											</h4>
										</div>)
									}
									{
										(isMobile || isTablet) && (
											<>
												<div className="text-center">
													<h4 className=" mb-3">Your bundle is waiting for you in the Bloomster App. You and your child can fully engage with the platform in a mobile-friendly format.
													</h4>
													<div className="appLInked flex justify-content-around m-auto">
														<a href="https://play.google.com/store/apps/details?id=com.bluffy.bloomster" >
															<img src={image.AndroidAppicon} alt="Android App" />
														</a>
														<a href="https://apps.apple.com/us/app/bloomster/id6474612652">
															<img src={image.IosAppicon} alt="Ios App" />
														</a>
													</div>
												</div>
												<p className="text-center mt-3">Or to be continue in Browser.</p>
											</>
										)
									}
									{localStorage.getItem("access_token") ? (
										<div className="ErrorDescription w-100">
											<button
												type="button"
												to={PATHS.HOME}
												onClick={() => showModalBox({ type: "SignIn" })}
											>

												Sign In
											</button>
										</div>
									) : (
										<div className="ErrorDescription w-100">
											<button
												type="button"
												className="mt-0"
												href={"#"}
												onClick={() => showModalBox({ type: "SignIn" })}
											>

												Sign In
											</button>
										</div>
									)}
								</div>
							</div>
						)}
						{viewHtml === "success" && (
							<div className="main-div">
								<div className="imgDiv">
									<div className="Successimsge">
										<div className=" m-auto">
											<img src={image.verifyaccount} alt="..." />
										</div>
									</div>
								</div>
								<div className="contentDiv">
									<h2>Welcome to Bloomster!</h2>
									{
										isDesktop && (
											<div className="text-center">
												<h3>Hi,  {getCapitalized(verifyUserData?.records[0]?.firstName)}</h3>
												<h4 className=" mb-3">
													{verifyUserData?.message} <br /> Please
													click the button below to Sign In.
												</h4>
											</div>
										)
									}

									{
										(isMobile || isTablet) && (
											<>
												<div className="text-center">
													<h3>Hi,  {getCapitalized(verifyUserData?.records[0]?.firstName)}</h3>
													<h4 className=" mb-3">Your bundle is waiting for you in the Bloomster App. You and your child can fully engage with the platform in a mobile-friendly format.
													</h4>
													<div className="appLInked flex justify-content-around m-auto">
														<a href="https://play.google.com/store/apps/details?id=com.bluffy.bloomster" >
															<img src={image.AndroidAppicon} alt="Android App" />
														</a>
														<a href="https://apps.apple.com/us/app/bloomster/id6474612652">
															<img src={image.IosAppicon} alt="Ios App" />
														</a>
													</div>
												</div>
												<p className="text-center mt-3">Or to be continue in Browser.</p>
											</>
										)
									}

									{/* <div className="ErrorDescription w-100">
                <a href={"#"} onClick={() => showModalBox({ type: "SignIn" })}>
                  <i className=" icon icon-dapulse-home  h4 mb-0 mr-2"></i>Sign
                  In
                </a>
              </div> */}
									{localStorage.getItem("access_token") ? (
										<div className="ErrorDescription ErrorDescriptionbtn w-100">
											<button
												type="button"
												onClick={() => handleHome()}
											>

												Sign In
											</button>
										</div>
									) : (
										<div className="ErrorDescription w-100">
											<button
												type="button"

												onClick={() => showModalBox({ type: "SignIn" })}
											>

												Sign In
											</button>
										</div>
									)}
								</div>
							</div>

						)}

					</div>
				</div>
			</WebLayout>
		</div>	
		)
		

	);
};

export default VerifyUser;
